import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFbSingleDoc, loading, patchData } from "../utilities/Functions";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const [convo, setConvo] = useState();
  const [pending, setPending] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  const [completed, setCompleted] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const service = queryParameters.get("service");

  var collectionName;

  if (!id) {
    // error handling
  }

  if (service === "Question") {
    collectionName = "CompletedOneQuestionJob";
  } else {
    collectionName = "CompletedJob";
  }

  useEffect(() => {
    getDoc();
  }, []);

  useEffect(() => {
    if (convo) {
      completeDbChanges();
    }
  }, [convo]);

  useEffect(() => {
    if (completed) {
      var state = {
        userId: convo.userId,
        creatorId: convo.creatorId,
        service: { typeOfService: convo.category },
        convoId: convo.id,
        loggedBy: "user",
      };
      setTimeout(() => {
        navigate("/inquiry-detail-page", {
          state: state,
        });
      }, 3000);
    }
  }, [completed]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  async function getDoc() {
    setPending(true);
    await getFbSingleDoc(collectionName, id, setConvo, setPending, setError);
  }

  async function completeDbChanges() {
    var asyncPending;
    var asyncStatus;
    var asyncError;

    function setAsyncData(data) {}
    function setAsyncPending(bool) {
      asyncPending = bool;
    }
    function setAsyncStatus(status) {
      asyncStatus = status;
    }
    function setAsyncError(error) {
      asyncError = error;
    }

    let userInfo = await getFbSingleDoc(
      "User",
      convo.userId,
      setAsyncData,
      setAsyncPending,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!!
    }

    var userSubmission;
    if (service === "Question") {
      userSubmission = {
        oneQuestionSubmissions: [...userInfo.oneQuestionSubmissions],
      };
      userSubmission.oneQuestionSubmissions.push(convo.id);
    } else {
      userSubmission = {
        reviewSubmissions: [...userInfo.reviewSubmissions],
      };
      userSubmission.reviewSubmissions.push(convo.id);
    }

    await patchData(
      "User",
      convo.userId,
      userSubmission,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!!
    }

    let creatorInfo = await getFbSingleDoc(
      "Creator",
      convo.creatorId,
      setAsyncData,
      setAsyncPending,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!!
    }

    // wait convInfo -> append new conv id
    //    to creator receivedOneQuestionSubmissionIds
    var creatorSubmission;
    if (service === "Question") {
      creatorSubmission = {
        receivedOneQuestionSubmissionIds: [
          ...creatorInfo.receivedOneQuestionSubmissionIds,
        ],
      };
      creatorSubmission.receivedOneQuestionSubmissionIds.push(convo.id);
    } else {
      creatorSubmission = {
        receivedSubmissionIds: [...creatorInfo.receivedSubmissionIds],
      };
      creatorSubmission.receivedSubmissionIds.push(convo.id);
    }

    await patchData(
      "Creator",
      convo.creatorId,
      creatorSubmission,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!!
    }

    setCompleted(true);
  }

  function setPageLayout() {
    if (!completed) {
      return loading();
    } else {
      return <div>Payment successful, redirecting to your inquiry</div>;
    }
  }

  return setPageLayout();
};

export default PaymentSuccess;
