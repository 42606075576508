import React from 'react';

const PrivacyPolicy = () => {
    return (
        <main className="flex min-h-screen flex-col justify-center p-24 space-y-10 lg:w-full">
            <div className="flex flex-col justify-center items-start text-left lg:max-w-5xl space-y-10 mx-auto">
                <div className="font-futura-bold text-center text-4xl" style={{ marginBottom: '20px', fontSize: '2.50rem' }}>
                Privacy Policy
                </div>

                <div className="font-futura-medium" style={{ fontSize: '1.25rem' }}>
                Introduction: This privacy policy outlines the information collected and how it is used by Rated10. We are committed to protecting the privacy of our users.
                <br /><br />
                Information Collection: We collect personal information such as name, email address, and IP address when you use our services. We may also collect information about your location, device type, and browsing behavior.
                <br /><br />
                Use of Information: The information we collect is used to provide and improve our services, personalize your experience, and communicate with you. We may also use your information for research purposes, to comply with legal obligations, and to enforce our policies.
                <br /><br />
                Sharing of Information: We do not sell, rent, or share your personal information with third parties for their marketing purposes. We may share your information with third-party service providers who help us operate our services, but only as necessary to provide the services you have requested.
                <br /><br />
                Data Retention: We retain your information for as long as necessary to provide you with our services, comply with legal obligations, and resolve disputes.
                <br /><br />
                Data Security: We use reasonable technical, administrative, and physical safeguards to protect your information from unauthorized access, use, and disclosure.
                <br /><br />
                Changes to Policy: This privacy policy may be updated periodically to reflect changes in our practices. We will notify you of any changes by posting the updated policy on our website.
                <br /><br />
                Contact Information: If you have any questions or concerns about our privacy policy, please contact us at [Insert Contact Information].
                <br /><br />
                Consent: By using our services, you consent to the collection and use of your information as described in this privacy policy.
                </div>
            </div>
        </main>
    );
  };
  
export default PrivacyPolicy;


