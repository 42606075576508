import { Button, Form, Image, Stack } from "react-bootstrap";
import Avatar from "../assets/Avatar.png";
import { useEffect, useRef, useState } from "react";
import { loading, patchData, uploadFile } from "../utilities/Functions";
import { useNavigate } from "react-router-dom";
import { useGetDoc, useGetFile } from "../utilities/CustomHooks";

import "./PageStyles.css";
import Compressor from "compressorjs";

const UserProfile = () => {
  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const inputFile = useRef(null);

  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem("token"));
  var launchSettings = JSON.parse(sessionStorage.getItem("launchSettings"));

  const [uiUser, setUiUser] = useState(null);
  const { data: userInfo } = useGetDoc(
    "User",
    user.uid,
    [user.uid, updatePending],
    setPending,
    setError
  );

  // const [creatorDependant, setCreatorDependant] = useState([
  //   null,
  //   updatePending,
  // ]);
  // const [creatorId, setCreatorId] = useState();
  // const { data: creatorInfo } = useGetDoc(
  //   "Creator",
  //   creatorId,
  //   creatorDependant,
  //   setPending,
  //   setError
  // );

  const [photoDependant, setPhotoDependant] = useState([null]);
  let fileName = "user" + user.uid + "/" + user.uid + "ProfilePicture";
  const { file: photo, setFile: setPhoto } = useGetFile(
    fileName,
    photoDependant,
    setPending,
    setError
  );

  // set UI fields
  useEffect(() => {
    if (userInfo) {
      setUiUser(userInfo);
    }
  }, [userInfo]);

  // // set creator dependencies
  // useEffect(() => {
  //   if (userInfo && userInfo.asCreator) {
  //     setCreatorDependant([userInfo.asCreator, updatePending]);
  //     setCreatorId(userInfo.asCreator);
  //   }
  // }, [userInfo, updatePending]);

  // set photo dependencies
  useEffect(() => {
    if (user.uid && userInfo) {
      setPhotoDependant([updatePending]);
    }
  }, [updatePending]);

  // set dummy photo if photo is null
  useEffect(() => {
    if (!photo) {
      setPhoto(Avatar);
    }
  }, [photo]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  //  create / change photo
  function handleEditPhoto(event) {
    if (inputFile === null) return;

    inputFile.current.click();
  }

  function onFileSelected(event) {
    // setPending(true);
    setUpdatePending(true);
    setStatus("File uploading!");

    let fileName = "user" + user.uid + "/" + user.uid + "ProfilePicture";

    new Compressor(event.target.files[0], {
      quality: 1 - launchSettings.photoCompressionQuality,
      success: (compressedResult) => {
        uploadFile(
          fileName,
          compressedResult,
          handleUploadProgress,
          handleUploadError,
          handleUploadSuccess
        );
      },
    });

    // uploadFile(
    //   fileName,
    //   event.target.files[0],
    //   handleUploadProgress,
    //   handleUploadError,
    //   handleUploadSuccess
    // );
    // setPhoto(event.target.files[0]);
  }

  function handleUploadProgress(progress) {
    setStatus(`Uploading : ${progress}%`);
  }

  function handleUploadError(error) {
    setStatus(`Unsuccessful, try again later`);
    // setTimeout(() => {
    // setPending(false);
    setUpdatePending(false);
    // }, 1000);
  }

  function handleUploadSuccess(file) {
    setStatus(`Successful`);
    // setTimeout(() => {
    // setPending(false);
    setUpdatePending(false);
    // }, 1000);
    // setPhoto(file);
  }

  function handleUiUserChanges(event) {
    // name mean uiUser field name
    const { id, value } = event.target;
    setUiUser((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (uiUser !== userInfo) {
      setUpdatePending(true);
      patchData("User", user.uid, uiUser, setUpdatePending);
      navigate(-1);
    }
  }

  function setPageLayout() {
    if (pending || updatePending) {
      return loading();
    } else if (uiUser === null) {
      // show error or do something else
    } else {
      return (
        <Stack
          gap={3}
          // className="col-4"
          className="col col-12 col-md-4 mx-auto p-2 d-flex flex-column border border-secondary"
        >
          <Stack gap={1} direction="horizontal" className="mx-auto">
            <Image src={photo} roundedCircle className="profile-photo" />
            <Button variant="outline-secondary" onClick={handleEditPhoto}>
              <div className="d-flex fw-bold text-left">Edit</div>
            </Button>
            <input
              hidden
              type="file"
              accept="image/*"
              id="file"
              ref={inputFile}
              onChange={onFileSelected}
            />
          </Stack>
          <Form
            onSubmit={handleSubmit}
            onReset={() => navigate("/user-settings")}
            className="mx-auto"
          >
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                type="text"
                value={uiUser.name}
                onChange={handleUiUserChanges}
              />
            </Form.Group>
            <Button
              variant="r10_orange text-white"
              className="col-12 mb-3"
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="light text-r10_orange"
              className="col-12 mb-3"
              type="reset"
            >
              Cancel
            </Button>
          </Form>
        </Stack>
      );
    }
  }

  return setPageLayout();
};

export default UserProfile;
