import React from 'react';
import { Button } from 'react-bootstrap';
import {isMobile} from "../utilities/Functions";

class SponsorshipButton extends React.Component {
  render() {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: isMobile() ? '10px' : '20px',
        paddingTop: isMobile() ? '10px' : '20px',
        paddingLeft: '0px',
        paddingRight: '0px',
        alignItems: 'center',
        textAlign: 'left',
        width: '100%', // Ensure the container takes full width
        boxSizing: 'border-box', // Include padding and border in the element's total width and height
    };

    const buttonStyle = {
      top: '10px',
      right: '20px',
      zIndex: 999, // Ensures the button stays on top of other elements
      height: "7vh",
      width: "auto",
      padding: '10px 20px',
      fontSize: '1em',
      backgroundColor: '#E35335', // Orange color
      color: 'white',
      fontWeight: 'bold',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    };

    return (
        <section id="reviews" style={containerStyle}>
          <Button
            className="shake"
            style={buttonStyle}
            onClick={() => window.location.href = "/sponsorship-apply"} // Link to sponsorship application
          >
            Interested in Sponsorship? Apply here!
          </Button>
        </section>
      );
  }
}

export default SponsorshipButton;