import React from 'react';

const CreatorTermsConditions = () => {
    return (
        <main className="flex min-h-screen flex-col justify-center p-24 space-y-10 lg:w-full">
            <div className="flex flex-col justify-center items-start text-left lg:max-w-5xl space-y-10 mx-auto">
                <div className="font-futura-bold text-center text-4xl" style={{ marginBottom: '20px', fontSize: '2.50rem' }}>
                Creators Terms & Conditions
                </div>

                <div className="font-futura-medium" style={{ fontSize: '1.25rem' }}>
                Welcome to Rated10! We're excited to have you as a creator on our platform. Please take a moment to carefully read and understand the terms and conditions outlined below.
                <br /><br />
                By using the Rated10 platform, you agree to be bound by the following terms and conditions:
                <br /><br />
                Eligibility:
                You must be at least 18 years old to register as a creator on Rated10.
                You must have the legal capacity to enter into this agreement.
                <br /><br />
                Content Guidelines:
                You are solely responsible for the content you create and share on Rated10.
                Content must adhere to our community guidelines, which prohibit explicit, harmful, or illegal content.
                Rated10 reserves the right to remove any content that violates these guidelines.
                <br /><br />
                Pricing and Payment:
                Creators have the authority to set their own prices for interactions with their followers.
                Rated10 will retain 30% of the total earnings, and creators will receive 70%.
                Payments will be processed at the end of each month for the total amount earned in that period.
                <br /><br />
                Payment Platform:
                To receive payments, creators must connect their bank account to the payment platform Stripe.
                Rated10 will use Stripe to facilitate all financial transactions.
                <br /><br />
                Taxes:
                Creators are responsible for any taxes or fees associated with their earnings on the platform.
                <br /><br />
                Intellectual Property:
                Creators retain all rights to their original content.
                By using Rated10, you grant us a non-exclusive license to use, reproduce, and distribute your content for promotional purposes.
                <br /><br />
                Termination:
                Rated10 reserves the right to terminate your account at our discretion if you violate any of these terms and conditions.
                <br /><br />
                Liability:
                Rated10 is not liable for any damages or losses resulting from your use of the platform.
                <br /><br />
                Indemnification:
                You agree to indemnify and hold Rated10 harmless from any claims, damages, or losses arising from your use of the platform.
                <br /><br />
                Updates to Terms:
                Rated10 may update these terms and conditions from time to time. We will notify you of any significant changes.
                <br /><br />
                Governing Law:
                This agreement is governed by the laws of Delaware, United States, without regard to its conflict of law principles.
                <br /><br />
                Contact Information:
                If you have any questions or concerns, please contact us at support@rated10.app.
                <br /><br />
                By using Rated10, you acknowledge that you have read, understood, and agree to these terms and conditions. If you do not agree, please do not use the platform.
                Thank you for being a part of the Rated10 community! We look forward to seeing what you accomplish on the platform.
                </div>
            </div>
        </main>
    );
  };
  
export default CreatorTermsConditions;




