import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// import "firebaseui";
import { auth as fbUIComp } from "firebaseui";
import firebase from "firebase/compat/app";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBDVbEWRGGGtJs4ETUjXrjQ-wKufIQqNT0",
  authDomain: "rated-5d7cf.firebaseapp.com",
  projectId: "rated-5d7cf",
  storageBucket: "rated-5d7cf.appspot.com",
  messagingSenderId: "854851521474",
  appId: "1:854851521474:web:0d46fb90553e62304d245b",
  measurementId: "G-RQ7F0ZC58X",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const storage = getStorage(app);

export const fbUI = new fbUIComp.AuthUI(auth);
export const fbUIAuth = firebase.auth;

export const fbTimestamp = Timestamp;

export const fbFunctions = getFunctions(app);
