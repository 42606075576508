import React from 'react';

const AccountLinkReturn = () => {
    return (
        <main className="flex min-h-screen flex-col justify-center p-24 space-y-10 lg:w-full">
            <div className="flex flex-col justify-center items-start text-left lg:max-w-5xl space-y-10 mx-auto">
                <div className="font-futura-bold text-center text-4xl" style={{ marginBottom: '20px', fontSize: '2.50rem' }}>
                Account Link Failed
                </div>

                <div className="font-futura-medium" style={{ fontSize: '1.25rem' }}>
                Please return to the app by closing this window.
                </div>
            </div>

            <div className="grid text-center">
                © 2024 Rated10, LLC. All rights reserved.
            </div>
        </main>
    );
  };
  
export default AccountLinkReturn;



