import { useEffect, useRef, useState } from "react";

import Avatar from "../assets/Avatar.png";
import {
  createDoc,
  delDoc,
  linkAndBrand,
  loading,
  patchData,
  serviceAndText,
  statusAlert,
  uniqByKeepLast,
  uploadFile,
} from "../utilities/Functions";
import { Button, Col, Form, Image, Row, Stack } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetDoc, useGetDocs, useGetFile } from "../utilities/CustomHooks";
import { messageTexts } from "../utilities/Constants.js";

import "./PageStyles.css";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const CreatorPageEdit = (props) => {
  const location = useLocation();
  var id = location.state.id;
  const section = location.state.section;

  const navigate = useNavigate();

  let user = JSON.parse(sessionStorage.getItem("token"));
  let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  var launchSettings = JSON.parse(sessionStorage.getItem("launchSettings"));

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();
  const [alertClass, setAlertClass] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  // const [updatePending, setUpdatePending] = useState(null);

  const inputFile = useRef(null);

  const [uiCreator, setUiCreator] = useState({ name: "", bio: "", links: [] });
  const [pendingCreatorInfo, setPendingCreatorInfo] = useState();
  const { data: creatorInfo } = useGetDoc(
    "Creator",
    id,
    [id],
    setPendingCreatorInfo,
    setError
  );

  const [uiCreatorServices, setUiCreatorServices] = useState(null);
  const [pendingServices, setPendingServices] = useState();
  const { fbDocs: creatorServices } = useGetDocs(
    `Creator/${id}/Products`,
    [id],
    setPendingServices,
    setError
  );

  // const [fileName, setFileName] = useState();
  // const [dependant, setDependant] = useState([null, updatePending]);
  const [pendingFile, setPendingFile] = useState();
  const { file: photo, setFile: setPhoto } = useGetFile(
    "user" + user.uid + "/" + user.uid + "ProfilePicture",
    [],
    setPending,
    setError
  );

  const [pendingUserInfo, setPendingUserInfo] = useState();

  const linksArray = linkAndBrand();
  const servicesArray = serviceAndText();

  // set dummy photo if photo is null
  useEffect(() => {
    if (!photo && !pending) {
      setPhoto(Avatar);
    }
  }, [photo]);

  // set UI fields for creator info
  useEffect(() => {
    if (creatorInfo) {
      setGetUiLinkFields("set");
    }
  }, [creatorInfo]);

  // set UI fields for creator services
  useEffect(() => {
    if (creatorServices) {
      setGetUiServices("set");
    }
  }, [creatorServices]);

  // // update - show status - exit
  // useEffect(() => {
  //   if (!updatePending && status === "Updating services") {
  //     setStatus("Successful");
  //     setTimeout(() => {
  //       navigate(-1);
  //     }, 3000);
  //   }
  // }, [updatePending]);

  //  set pending depending all individual pendings
  useEffect(() => {
    setPending(
      pendingCreatorInfo || pendingServices || pendingFile || pendingUserInfo
    );
  }, [pendingCreatorInfo, pendingServices, pendingFile, pendingUserInfo]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function setGetUiLinkFields(method) {
    let ui;
    let linkStr;

    if (method === "set") {
      ui = { ...creatorInfo };
      ui.links.forEach((link, index) => {
        linkStr = linksArray.find((array) => array.brand === link.brand);

        if (linkStr) {
          ui.links[index].url = ui.links[index].url.replace(linkStr.url, "");

          // remove / if it is the first char
          if (ui.links[index].url[0] === "/") {
            ui.links[index].url = ui.links[index].url.slice(1);
          }

          // remove / if it is the last char
          if (ui.links[index].url[ui.links[index].url.length - 1] === "/") {
            ui.links[index].url = ui.links[index].url.slice(
              0,
              ui.links[index].url.length - 2
            );
          }
        }
      });
      setUiCreator({ ...ui });
    } else {
      ui = { ...uiCreator };
      let links = JSON.parse(JSON.stringify(uiCreator.links));
      ui.links = [];
      links.forEach((link, index) => {
        // remove / if url starts with it
        if (link.url[0] === "/" || link.url[0] === "@") {
          link.url = link.url.slice(1);
        }
        // get only proper values
        if (link.brand !== "-1" && link.url !== "") {
          linkStr = linksArray.find((array) => array.brand === link.brand);
          if (linkStr) {
            link.url = linkStr.url + link.url;
            ui.links.push(link);
          }
        }
      });
      // setUiCreator({ ...ui });
      return ui;
    }
  }

  function setGetUiServices(method) {
    let uiServices = [];
    let found;

    if (method === "set") {
      creatorServices.forEach((original) => {
        found = servicesArray.find(
          (array) => original.typeOfService === array.typeOfService
        );
        if (found !== undefined) {
          uiServices.push({ ...original });
        }
      });
      setUiCreatorServices([...uiServices]);
    } else {
      uiServices = [...uiCreatorServices];
      uiServices = uiServices.filter(
        (service) => service.typeOfService !== "-1"
      );
      uiServices = uiServices.filter((service) =>
        /^\d+$/.test(service.tokenPrice)
      );
      uiServices = uiServices.filter((service) => service.tokenPrice > 0);

      // setUiCreatorServices(uiServices);
      return uiServices;
    }
  }

  function onFileSelected(event) {
    // setPending(true);
    setPendingFile(true);
    setStatus("File uploading!");

    let fileName = "user" + user.uid + "/" + user.uid + "ProfilePicture";

    new Compressor(event.target.files[0], {
      quality: 1 - launchSettings.photoCompressionQuality,
      success: (compressedResult) => {
        uploadFile(
          fileName,
          compressedResult,
          handleUploadProgress,
          handleUploadError,
          handleUploadSuccess
        );
      },
    });
  }

  async function checkAndUpdateServices() {
    let uiServices = setGetUiServices("get");
    let found;

    setPendingServices(true);
    setStatus("Updating services");

    // create - update
    // uiServices.forEach((service) => {
    for (let index = 0; index < uiServices.length; index++) {
      let service = uiServices[index];

      found = creatorServices.find(
        (array) => array.firestoreId === service.firestoreId
      );
      if (found === undefined) {
        // create doc
        if (service.fbDocId) {
          delete service.fbDocId;
        }
        service.firestoreId = `prod_${uuidv4()}`;
        await createDoc(
          `Creator/${id}/Products`,
          service.firestoreId,
          service,
          setPendingServices,
          setStatus,
          setError
        );
      } else if (!_.isEqual(service, found)) {
        // update doc
        if (service.fbDocId) {
          delete service.fbDocId;
        }
        await patchData(
          `Creator/${id}/Products`,
          service.firestoreId,
          service,
          setPendingServices,
          setStatus,
          setError
        );
      }
    }

    // delete services
    // creatorServices.forEach((service) => {
    for (let index = 0; index < creatorServices.length; index++) {
      let service = creatorServices[index];

      found = uiServices.find(
        (array) => array.firestoreId === service.firestoreId
      );

      // if original data not in ui
      if (found === undefined) {
        found = servicesArray.find(
          (array) => array.typeOfService === service.typeOfService
        );

        // original data is in used services and it is marked as deleted
        if (found !== undefined) {
          await delDoc(
            `Creator/${id}/Products`,
            service.firestoreId,
            setPendingServices,
            setStatus,
            setError
          );
        }
      }
    }

    // setPendingServices(false);
  }

  async function createNewCreator() {
    setStatus("Building creator info");
    setPendingCreatorInfo(true);
    setPendingServices(true);
    setPendingFile(true);
    setPendingUserInfo(true);

    let ui = setGetUiLinkFields("get");

    // set new creator info
    ui.id = uuidv4();
    ui.userId = user.uid;
    ui.email = user.email;
    ui.name = userInfo.name;

    let error = await createDoc(
      `Creator`,
      ui.id,
      ui,
      setPendingCreatorInfo,
      setStatus,
      setError
    );
    if (error) {
      // error handling !!!
      return;
    }

    // update user fields
    setStatus("Updating user");
    // setUpdatePending(true);

    let userUpdate = { asCreator: ui.id };
    error = await patchData(
      "User",
      user.uid,
      userUpdate,
      setPendingUserInfo,
      setStatus,
      setError
    );
    if (error) {
      // error handling !!!
      return;
    }
    userInfo.asCreator = ui.id;
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

    // after creator, set new products
    id = ui.id;
    checkAndUpdateServices();
  }

  //  create / change photo
  function handleEditPhoto(event) {
    if (inputFile === null) {
      return;
    }

    inputFile.current.click();
  }

  function handleUploadProgress(progress) {
    setStatus(`Uploading : ${progress}%`);
  }

  function handleUploadError(error) {
    setStatus(`Unsuccessful, try again later`);
    // setTimeout(() => {
    // setPending(false);
    setPendingFile(false);
    // }, 1000);
  }

  function handleUploadSuccess(file) {
    setStatus(`Successful`);
    // setTimeout(() => {
    // setPending(false);
    setPendingFile(false);
    // }, 1000);
    setPhoto(URL.createObjectURL(file));
  }

  function handleUiBioChanges(event) {
    // name mean uiUser field name
    let { id, value } = event.target;
    if (id === "jobLimitNumber") {
      value = Number(value);
    }
    setUiCreator((prevState) => ({
      ...prevState,
      [id]: value ? value : null,
    }));
  }

  function handleUiLinkChanges(event, index) {
    let uiLinks = uiCreator.links;

    switch (event.target.name) {
      case "+":
        uiLinks.push({ brand: "", url: "" });
        setUiCreator({ ...uiCreator, links: uiLinks });
        break;
      case "-":
        uiLinks.splice(index, 1);
        setUiCreator({ ...uiCreator, links: uiLinks });
        break;
      default:
        uiLinks[index][event.target.name] = event.target.value;
        setUiCreator({ ...uiCreator, links: uiLinks });
        break;
    }
  }

  function handleUiServiceChanges(event, index) {
    let uiServices = [...uiCreatorServices];

    switch (event.target.name) {
      case "active":
        uiServices[index].active = event.target.value === "true" ? true : false;
        setUiCreatorServices([...uiServices]);
        break;
      case "+":
        uiServices.push({
          typeOfService: "",
          tokenPrice: "",
          price: "",
          active: true,
        });
        setUiCreatorServices([...uiServices]);
        break;
      case "-":
        uiServices.splice(index, 1);
        setUiCreatorServices([...uiServices]);
        break;
      default:
        uiServices[index][event.target.name] = event.target.value;
        if (event.target.name === "tokenPrice") {
          uiServices[index].price = (
            Number(uiServices[index].tokenPrice) * 100
          ).toString();
        }
        setUiCreatorServices([...uiServices]);
        break;
    }
  }

  function formValidation(section) {
    // link check - no duplicates
    if (
      (!section || section === "link") &&
      uiCreator.links &&
      uiCreator.links.length !==
        uniqByKeepLast(uiCreator.links, (link) => link.brand).length
    ) {
      setStatus(messageTexts.cpe_same_link);
      setStatusVariant("danger");
      setAlertClass("col-4 me-auto mt-auto text-center");
      setTimeout(() => {
        setStatus(null);
        setAlertClass(null);
      }, 3000);
      return false;
    }

    // service check - no duplicates
    if (
      (!section || section === "service") &&
      uiCreatorServices &&
      uiCreatorServices.length !==
        uniqByKeepLast(uiCreatorServices, (service) => service.brand).length
    ) {
      setStatus(messageTexts.cpe_same_service);
      setStatusVariant("danger");
      setAlertClass("col-4 me-auto mt-auto text-center");
      setTimeout(() => {
        setStatus(null);
        setAlertClass(null);
      }, 3000);
      return false;
    }

    return true;
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!formValidation(section)) {
      return;
    }

    // let ui = { ...uiCreator };
    let ui = JSON.parse(JSON.stringify(uiCreator));

    if (section === "") {
      // console.log("new creator");
      //  !!!
      // build creator info and send to firebase
      createNewCreator();
    } else if (section === "service") {
      checkAndUpdateServices();
    } else {
      // for link edit, set url's properly
      if (section === "link") {
        ui = setGetUiLinkFields("get");
      }

      if (ui !== creatorInfo) {
        setPendingCreatorInfo(true);
        //setStatus("Updating");
        patchData(
          "Creator",
          id,
          ui,
          setPendingCreatorInfo,
          setStatus,
          setError
        );
      }
    }

    setStatusVariant("success");
    setAlertClass("col-4 me-auto mt-auto text-center");
    setTimeout(() => {
      setStatus(null);
      setAlertClass(null);
    }, 3000);
    // navigate(-1);
  }

  function bioLayout() {
    return (
      <Stack gap={3} className="mb-5">
        <div className="mb-1 h1 fw-bold">Edit Creator Profile Information</div>
        <Stack gap={1} direction="horizontal">
          <Image src={photo} roundedCircle className="profile-photo" />
          <Button variant="outline-secondary" onClick={handleEditPhoto}>
            <div className="d-flex fw-bold text-left">Edit</div>
          </Button>
          <input
            hidden
            type="file"
            accept="image/*"
            id="file"
            ref={inputFile}
            onChange={onFileSelected}
          />
        </Stack>
        <Form.Group controlId="creatorName" className="col-8">
          <Form.Label className="fw-bold">Name</Form.Label>
          <Form.Control
            type="text"
            value={uiCreator.creatorName ? uiCreator.creatorName : ""}
            onChange={handleUiBioChanges}
          />
        </Form.Group>
        <Form.Group controlId="bio" className="col-8">
          <Form.Label className="fw-bold">Bio</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={uiCreator.bio ? uiCreator.bio : ""}
            onChange={handleUiBioChanges}
          />
        </Form.Group>
        <Form.Group controlId="jobLimitNumber" className="col-8">
          <Form.Label className="fw-bold">Inquiry Capacity</Form.Label>
          <Form.Control
            type="number"
            // value={uiCreator.jobLimitNumber ? uiCreator.jobLimitNumber : ""}
            value={uiCreator.jobLimitNumber}
            placeholder="Inquiry capacity, leave blank to no set a limit"
            onChange={handleUiBioChanges}
          />
        </Form.Group>
      </Stack>
    );
  }

  function linkLayout() {
    return (
      <>
        <Row className="mb-1">
          <div className="h1 fw-bold">Edit Social Media Links</div>
        </Row>
        <Row className="mb-3">
          <Form.Text muted>
            Choose a social media platform and enter your handle
          </Form.Text>
        </Row>
        {uiCreator.links &&
          uiCreator.links.map((link, index) => (
            <Row className="mb-3" key={index}>
              {/* brand */}
              <Col xs={4}>
                <Form.Select
                  name={"brand"}
                  aria-label="Link"
                  onChange={(event) => handleUiLinkChanges(event, index)}
                  value={link.brand}
                  disabled={link.url}
                  required
                >
                  <option value="" disabled>
                    Choose a link
                  </option>
                  {linksArray.map((array) => (
                    <option value={array.brand} key={array.brand}>
                      {array.text}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              {/* handle */}
              <Col xs={4}>
                <Form.Control
                  type="text"
                  id={index}
                  name="url"
                  value={link.url ? link.url : ""}
                  onChange={(event) => handleUiLinkChanges(event, index)}
                  required
                />
              </Col>
              {/* delete */}
              <Col xs={2}>
                <Button
                  name="-"
                  value={index + " -"}
                  variant="outline-secondary"
                  onClick={(event) => handleUiLinkChanges(event, index)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          ))}

        <Button
          className="col-4 d-flex mb-5 justify-content-center"
          name="+"
          variant="outline-secondary"
          onClick={handleUiLinkChanges}
        >
          +
        </Button>
      </>
    );
  }

  function serviceLayout() {
    return (
      <>
        <Row className="mb-1">
          <div className="h1 fw-bold">Edit Services</div>
        </Row>
        <Row className="mb-3">
          <Form.Text muted>
            Select a service and enter the price of the service in US Dollars
          </Form.Text>
        </Row>
        {uiCreatorServices &&
          uiCreatorServices.map((service, index) => (
            // service.active && (
            <Row className="mb-3" key={index}>
              {/* typeOfService */}
              <Col xs={4}>
                <Form.Select
                  name={"typeOfService"}
                  aria-label="Service"
                  onChange={(event) => handleUiServiceChanges(event, index)}
                  value={service.typeOfService}
                  disabled={service.firestoreId ? true : false}
                  required
                >
                  <option value="" disabled>
                    Choose a service
                  </option>
                  {servicesArray.map((array) => (
                    <option
                      value={array.typeOfService}
                      key={array.typeOfService}
                    >
                      {array.text}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              {/* tokenPrice */}
              <Col xs={2}>
                <Form.Control
                  type="number"
                  min="1"
                  name="tokenPrice"
                  value={service.tokenPrice ? service.tokenPrice : ""}
                  onChange={(event) => handleUiServiceChanges(event, index)}
                  aria-describedby="tokenPrice"
                  required
                />
              </Col>
              {/* active/inactive */}
              {/* <Col xs={3}>
              <Form.Select
                name={"active"}
                aria-label="Active"
                onChange={(event) => handleUiServiceChanges(event, index)}
                value={service.active}
                disabled={service.firestoreId ? false : true}
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Form.Select>
            </Col> */}
              {/* delete */}
              <Col xs={1}>
                <Button
                  name="-"
                  variant="outline-secondary"
                  onClick={(event) => handleUiServiceChanges(event, index)}
                  // onClick={handleUiServiceChanges}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          ))}

        {/* add */}
        <Button
          className="col-4 d-flex mb-5 justify-content-center"
          name="+"
          variant="outline-secondary"
          onClick={handleUiServiceChanges}
        >
          +
        </Button>
      </>
    );
  }

  // page layout - stack
  function setPageLayout() {
    // if (pending || updatePending) {
    // if (pending) {
    // return loading();
    // } else if (
    if (
      uiCreator === null // ||
      // ((section === "service" || section === "") && uiCreatorServices === null)
    ) {
      // error handling - creator not found
      return <>Creator not found</>;
    } else {
      return (
        <Stack direction="horizontal">
          <Form
            className="col-6"
            onSubmit={handleSubmit}
            onReset={() => navigate(-1)}
          >
            {section === "bio" && bioLayout()}
            {section === "link" && linkLayout()}
            {section === "service" && serviceLayout()}
            {section === "" && (
              <>
                {bioLayout()}
                {linkLayout()}
                {serviceLayout()}
              </>
            )}
            <Button
              className="col-4 d-flex mb-3 justify-content-center"
              variant="r10_orange text-white"
              type="submit"
              // disabled={status}
              disabled={pending}
            >
              Submit
            </Button>
            <Button
              className="col-4 d-flex mb-3 justify-content-center"
              variant="light text-r10_orange"
              type="reset"
              // disabled={status}
              disabled={pending}
            >
              Cancel
            </Button>
            {status &&
              statusAlert(status, statusVariant, undefined, alertClass)}
          </Form>
          <div className="col-6"></div>
        </Stack>
      );
    }
  }

  return setPageLayout();
};

export default CreatorPageEdit;
