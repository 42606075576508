import React, { useEffect, useRef, useState } from "react";
import { useGetDoc } from "../utilities/CustomHooks";
import { useNavigate } from "react-router-dom";
import {
  conversationTopics,
  createDoc,
  getFbFile,
  loading,
  patchData,
  statusAlert,
  getBase64,
  uploadEncodedStringFile,
  photoPopUp,
  getFbDocs,
  delDoc,
  deleteFile,
  changeTextWithParameters,
  dateDifferenceinDays,
} from "../utilities/Functions";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  Row,
  Stack,
} from "react-bootstrap";
import {
  BuildConfig,
  InquiryTexts,
  JobCompletionState,
  MAXIMUM_RESPONSE_TIME,
  MIN_DAY_FOR_ALERT,
  SubmissionAlert,
  SubmissionMark,
} from "../utilities/Constants";
import { v4 as uuidv4 } from "uuid";

import "./ComponentStyles.css";
import { fbTimestamp } from "../utilities/Firebase";
import Compressor from "compressorjs";
import ReportJob from "./ReportJob";
import PaymentCheckout from "./PaymentCheckout";
import Summary from "./Summary";
import ConfirmationPopUp from "./ConfirmationPopUp";

const ReviewDetail = ({
  userId,
  creatorId,
  service,
  convoId,
  loggedBy,
  jobCallBack,
}) => {
  var user = JSON.parse(sessionStorage.getItem("token"));
  // var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  var launchSettings = JSON.parse(sessionStorage.getItem("launchSettings"));
  // launchSettings.isSimpleReviewQuestionEnabled = false; //  test

  const [summaryExtentShow, setSummaryExtentShow] = useState(true);
  const [summaryShow, setSummaryShow] = useState(false);

  const [reportShow, setReportShow] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);

  var continueOrEnd = false;
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [callBackFunction, setCallBackFunction] = useState();
  const [modalElements, setModalElements] = useState();

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();
  const [alertClass, setAlertClass] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const { data: inquiryUser } = useGetDoc(
    "User",
    userId,
    [userId, updatePending],
    setPending,
    setError
  );

  const { data: inquiryCreator } = useGetDoc(
    "Creator",
    creatorId,
    [creatorId, updatePending],
    setPending,
    setError
  );

  const [uiInputs, setUiInputs] = useState({ subject: "", posts: [] });

  const [paymentConvo, setPaymentConvo] = useState();
  const [paymentIntent, setPaymentIntent] = useState();

  const { data: convInfo } = useGetDoc(
    "CompletedJob",
    convoId,
    [convoId, updatePending],
    setPending,
    setError
  );

  const [uiPost, setUiPost] = useState();
  const [selectedPost, setSelectedPost] = useState(null);

  const [photoPop, setPhotoPop] = useState(null);
  const navigate = useNavigate();

  const inputFile = useRef(null);

  // user or creator
  const currentUser = loggedBy === "user" ? userId : creatorId;

  // set UI obejct
  useEffect(() => {
    if (convInfo) {
      setPending(true);
      getPostPhotos(convInfo, user, setUiInputs, setPending);
      checkAlert();
    }
  }, [convInfo]);

  // if any post selected, set message screen ui
  useEffect(() => {
    setUiPostFields(selectedPost);
  }, [selectedPost]);

  // for testing purpose
  useEffect(() => {
    if (status) {
      // show success and leave
      if (statusVariant === "success") {
        setTimeout(() => {
          if (jobCallBack !== undefined) {
            jobCallBack();
          } else {
            navigate(-1);
          }
        }, 3000);
      } else {
        // error message
        setAlertClass("col-6 mx-auto mt-auto text-center");
        setTimeout(() => {
          setStatus(null);
          setAlertClass(null);
        }, 5000);
      }
    }
  }, [status]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function newConvoObject() {
    var newConvo;

    if (!convInfo) {
      // new review

      // remove excess fields
      let postArray = [];
      uiInputs.posts.forEach((post) => {
        let newPost = {
          comments: post.comments,
          photo: post.photo,
          photoURL: post.photoURL,
        };
        postArray.push(newPost);
      });

      newConvo = {
        subject: uiInputs.subject,
        link: uiInputs.link !== undefined ? uiInputs.link : null,
        // posts: uiInputs.posts,
        posts: postArray,
        id: `job_${uuidv4()}`,
        creatorId: inquiryCreator.id,
        creatorName: inquiryCreator.name,
        userId: user.uid,
        userName: inquiryUser.name,
        category: service.typeOfService,
        completionState: JobCompletionState.ONGOING,
        submissionDate: fbTimestamp.fromDate(new Date()),
        lastUpdateDate: fbTimestamp.fromDate(new Date()),
        lastUpdateId: currentUser,
        completionDeadline: service.completionDeadline,
        amount: service.price,
        amountInTokens: service.tokenPrice,
        environment: BuildConfig.BUILD_TYPE,
        markByCreator: SubmissionMark.CREATOR_WILL_SUBMIT_RESPONSE,
        markByEndUser: SubmissionMark.USER_IS_WAITING_RESPONSE,
        showAlertToCreator: SubmissionAlert.NO_ALERT,
        showAlertToEndUser: SubmissionAlert.NO_ALERT,
        maximumDaysPartiesCanBeIdle: MAXIMUM_RESPONSE_TIME,
        minimumDaysRequiredToShowIdleAlert: MIN_DAY_FOR_ALERT,
        platform: "web",
        receivedThumbsUp: false,
      };
      setPaymentConvo({ ...newConvo });
    } else {
      // review updated
      newConvo = {
        subject: uiInputs.subject,
        posts: uiInputs.posts,
        // completionState: JobCompletionState.COMPLETED, !!!
        // submissionDate: fbTimestamp.fromDate(new Date()),
        lastUpdateDate: fbTimestamp.fromDate(new Date()),
        lastUpdateId: currentUser,
        // markByCreator: SubmissionMark.CAN_MARK_COMPLETE,
      };

      if (loggedBy === "creator") {
        let found;
        for (let i = 0; i < uiInputs.posts.length; i++) {
          found = null;
          found = uiInputs.posts[i].comments.find(
            (comment) => comment.userId === currentUser && comment.text
          );
          if (!found) {
            break;
          }

          // if creator replied anything, can mark review complete
          // if (convInfo.markByCreator !== SubmissionMark.CAN_MARK_COMPLETE) {
          newConvo.markByCreator = SubmissionMark.CAN_MARK_COMPLETE;
          newConvo.markByEndUser = SubmissionMark.CAN_MARK_COMPLETE;
          // }
        }

        // simple review - if all is answered and submitted, review is completed
        if (
          launchSettings &&
          launchSettings.isSimpleReviewQuestionEnabled &&
          found
        ) {
          newConvo.completionState = JobCompletionState.COMPLETED;
          newConvo.markByCreator = SubmissionMark.COMPLETED;
          newConvo.markByEndUser = SubmissionMark.COMPLETED;
        }
      }
    }
    return { newConvo, postFiles: uiInputs.posts };
  }

  function setUiPostFields(index) {
    if (index !== null) {
      // a photo is selected
      let ui = JSON.parse(JSON.stringify(uiInputs.posts[index]));

      if (
        !convInfo ||
        (loggedBy === "user" &&
          convInfo.markByEndUser !== SubmissionMark.COMPLETED &&
          convInfo.markByCreator !== SubmissionMark.CREATOR_MARKED_COMPLETE &&
          convInfo.completionState === JobCompletionState.ONGOING) ||
        (loggedBy === "creator" &&
          convInfo.markByCreator !== SubmissionMark.CREATOR_MARKED_COMPLETE &&
          convInfo.completionState === JobCompletionState.ONGOING)
      ) {
        ui.comments.push({
          isSeen: false,
          ownerName:
            loggedBy === "user" ? inquiryUser.name : inquiryCreator.creatorName,
          text: "",
          userId: currentUser,
        });
      }

      setUiPost({
        ...ui,
      });

      document.getElementById("post_stack").scrollTo({
        top: selectedPost * 150,
        // top: 30,
        behavior: "smooth",
      });
    } else {
      // no photo selected
      setUiPost(null);
    }
  }

  function checkAlert() {
    if (
      convInfo.markByCreator !== SubmissionMark.CREATOR_WILL_SUBMIT_RESPONSE ||
      convInfo.markByEndUser !== SubmissionMark.USER_IS_WAITING_RESPONSE
    ) {
      return;
    }

    let today = new Date();
    let difference = dateDifferenceinDays(
      today,
      convInfo.lastUpdateDate.toDate()
    );
    if (
      difference > convInfo.minimumDaysRequiredToShowIdleAlert &&
      difference < convInfo.maximumDaysPartiesCanBeIdle
    ) {
      setCallBackFunction(undefined);
      setModalElements({
        body: InquiryTexts.r10_inquiry_idle_alert,
        alert: true,
      });
      setConfirmationShow(true);
    } else if (difference > convInfo.maximumDaysPartiesCanBeIdle) {
      setCallBackFunction(undefined);
      setModalElements({
        body: InquiryTexts.r10_inquiry_idle_closed,
        alert: true,
      });
      setConfirmationShow(true);
    }
  }

  function onFileSelected(event) {
    setPending(true);

    new Compressor(event.target.files[0], {
      quality: 1 - launchSettings.photoCompressionQuality,
      success: (compressedResult) => {
        getBase64(compressedResult)
          .then((result) => {
            let ui = JSON.parse(JSON.stringify(uiInputs));

            ui.posts.push({
              comments: [],
              photo: null,
              photoURL: uuidv4(),
              photoFile: compressedResult,
              actualPhoto: URL.createObjectURL(compressedResult),
              encoded: result,
            });
            setUiInputs({ ...ui });
            setSelectedPost(ui.posts.length - 1);
            setPending(false);
          })
          .catch((err) => {
            // console.log(err);
          });
      },
    });

    // getBase64(event.target.files[0])
    //   .then((result) => {
    //     let ui = JSON.parse(JSON.stringify(uiInputs));

    //     ui.posts.push({
    //       comments: [],
    //       photo: null,
    //       photoURL: uuidv4(),
    //       photoFile: event.target.files[0],
    //       actualPhoto: URL.createObjectURL(event.target.files[0]),
    //       encoded: result,
    //     });
    //     setUiInputs({ ...ui });
    //     setSelectedPost(ui.posts.length - 1);
    //     setPending(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // let ui = JSON.parse(JSON.stringify(uiInputs));

    // ui.posts.push({
    //   comments: [],
    //   photo: null,
    //   photoURL: uuidv4(),
    //   photoFile: event.target.files[0],
    //   actualPhoto: URL.createObjectURL(event.target.files[0]),
    //   encoded: base64encoded,
    // });
    // setUiInputs({ ...ui });
    // setSelectedPost(ui.posts.length - 1);
    // setPending(false);
  }

  function handleAddPhoto() {
    if (inputFile === null) return;

    inputFile.current.click();
  }

  function handleDeletePhoto(event, index) {
    let ui = JSON.parse(JSON.stringify(uiInputs));

    ui.posts.splice(index, 1);
    // setUiPostFields(null);
    setSelectedPost(null);
    setUiInputs(ui);
  }

  function handleSelectPostFromList(event, index) {
    if (event.target.id.substring(0, 7) !== "delete_") {
      setSelectedPost(index);
    }
  }

  function handleInputChange(event) {
    if (event.target.id === "subject" || event.target.id === "link") {
      setUiInputs({ ...uiInputs, [event.target.id]: event.target.value });
    } else {
      // comment_index - 8'th substring
      let index = parseInt(event.target.id.substring(8));
      let ui = uiPost;
      ui.comments[index].text = event.target.value;
      ui.comments[index].isSeen = false;
      ui.comments[index].userId = currentUser;
      setUiPost(ui);
    }
  }

  function handleSavePosts(event) {
    if (selectedPost !== null) {
      let post = JSON.parse(JSON.stringify(uiPost));
      let ui = JSON.parse(JSON.stringify(uiInputs));

      post.comments = post.comments.filter(
        (comment) => comment.text && comment.text !== ""
      );
      ui.posts[selectedPost] = post;

      setUiInputs(ui);
    }
  }

  function reportCallBack(isReported) {
    setReportShow(false);
  }

  async function paymentCallBack(isPaymentCompleted) {
    setPaymentShow(false);

    //  delete created record
    if (!isPaymentCompleted && paymentConvo) {
      setPending(true);
      if (paymentIntent) {
        await delDoc(
          `CompletedJob/${paymentConvo.id}/PaymentIntent`,
          paymentIntent.fbDocId,
          setPending,
          setStatus,
          setError
        );
      }
      await delDoc(
        "CompletedJob",
        paymentConvo.id,
        setPending,
        setStatus,
        setError
      );
      for (let i = 0; i < uiInputs.posts.length; i++) {
        let fileName =
          "user" +
          paymentConvo.userId +
          "/sub" +
          paymentConvo.id +
          "_" +
          uiInputs.posts[i].photoURL;
        deleteFile(fileName);
      }
      setPending(false);
    }
  }

  function summaryCallBack(updated) {
    setSummaryShow(false);
  }

  function handleContinueOrEnd(event, isConfirmed, isContinue) {
    //  to prevent infinite loop
    if (event === undefined) {
      return;
    }

    if (isConfirmed === undefined) {
      setCallBackFunction(
        (event, isConfirmed) => (event, isConfirmed) =>
          handleContinueOrEnd(event, isConfirmed)
      );
      setModalElements({
        body: changeTextWithParameters(
          InquiryTexts.r10_are_you_sure_selection,
          [{ var: "&SELECTION&", text: isContinue ? "continue" : "end" }]
        ),
      });
      // setContinueOrEnd(isContinue);
      continueOrEnd = isContinue;
      setConfirmationShow(true);

      return;
    } else if (!isConfirmed) {
      return;
    }

    setConfirmationShow(false);
    let newConvo = {};
    switch (loggedBy) {
      case "user":
        if (continueOrEnd) {
          newConvo = {
            markByEndUser: SubmissionMark.USER_ASKED_TO_CONTINUE,
          };
        } else {
          newConvo = {
            completionState: JobCompletionState.COMPLETED,
            markByEndUser: SubmissionMark.COMPLETED,
            markByCreator: SubmissionMark.COMPLETED,
          };
        }
        break;

      case "creator":
        if (continueOrEnd) {
          newConvo = {
            markByCreator: SubmissionMark.CAN_MARK_COMPLETE,
            markByEndUser: SubmissionMark.CAN_MARK_COMPLETE,
          };
        } else {
          newConvo = {
            completionState: JobCompletionState.FLAGGED,
          };
        }
        break;
    }

    newConvo.lastUpdateId = currentUser;
    newConvo.lastUpdateDate = fbTimestamp.fromDate(new Date());

    // make changes in db
    paymentAndDatabaseChange(
      convInfo,
      newConvo,
      uiInputs.posts,
      inquiryUser,
      inquiryCreator,
      setPending,
      setUpdatePending,
      setStatus,
      setStatusVariant,
      setPaymentShow,
      setPaymentIntent
    );
  }

  function handleComplete(event, isConfirmed) {
    //  to prevent infinite loop
    if (event === undefined) {
      return;
    }

    if (isConfirmed === undefined) {
      setCallBackFunction(
        (event, isConfirmed) => (event, isConfirmed) =>
          handleComplete(event, isConfirmed)
      );
      setModalElements({ body: InquiryTexts.r10_complete_confirmation });
      setConfirmationShow(true);
      return;
    } else if (!isConfirmed) {
      return;
    }

    setConfirmationShow(false);
    let newConvo = {};
    if (loggedBy === "user") {
      newConvo.completionState = JobCompletionState.COMPLETED;
      newConvo.markByEndUser = SubmissionMark.COMPLETED;
    } else {
      newConvo.markByCreator = SubmissionMark.CREATOR_MARKED_COMPLETE;
    }
    newConvo.lastUpdateDate = fbTimestamp.fromDate(new Date());
    newConvo.lastUpdateId = currentUser;

    paymentAndDatabaseChange(
      convInfo,
      newConvo,
      uiInputs.posts,
      inquiryUser,
      inquiryCreator,
      setPending,
      setUpdatePending,
      setStatus,
      setStatusVariant,
      setPaymentShow,
      setPaymentIntent
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    // !!! subject required, at least 1 post required
    if (
      (loggedBy === "user" && !convInfo && !uiInputs.posts.length) ||
      uiInputs.posts.length < 1
    ) {
      setStatusVariant("danger");
      setStatus("Add one (or more) post to review");
      setTimeout(() => {
        setStatus(null);
        setStatusVariant(null);
      }, 3000);
      return;
    }
    var { newConvo, postFiles } = newConvoObject();

    setPending(true);

    paymentAndDatabaseChange(
      convInfo,
      newConvo,
      postFiles,
      inquiryUser,
      inquiryCreator,
      setPending,
      setUpdatePending,
      setStatus,
      setStatusVariant,
      setPaymentShow,
      setPaymentIntent
    );
  }

  function handleReset(event) {
    if (jobCallBack !== undefined) {
      jobCallBack();
    } else {
      navigate(-1);
    }
  }

  function fieldBooleans(field, property) {
    switch (field) {
      case "subject":
        if (loggedBy === "user" && !convInfo) {
          return false;
        } else {
          return true;
        }

      case "post-delete":
        if (loggedBy === "user" && !convInfo) {
          return false;
        } else {
          return true;
        }
      default:
        break;
    }
  }

  function formButtons(section) {
    switch (section) {
      case "add-photo":
        if (loggedBy === "user" && !convoId) {
          // photo adding is allowed only in first creation
          return (
            <Button
              className="mt-auto mb-2"
              variant="outline-secondary"
              onClick={handleAddPhoto}
            >
              <input
                hidden
                type="file"
                accept="image/*"
                id="file"
                ref={inputFile}
                onChange={onFileSelected}
              />
              +
            </Button>
          );
        }
        break;
      case "submit-cancel":
        if (
          // user
          loggedBy === "user" &&
          (!convoId ||
            (convInfo &&
              convInfo.completionState === JobCompletionState.ONGOING &&
              convInfo.markByCreator !==
                SubmissionMark.CREATOR_MARKED_COMPLETE))
        ) {
          return (
            <>
              <Button
                className="mb-2"
                variant="r10_orange text-white"
                type="submit"
              >
                Submit
              </Button>
              <Button variant="light text-r10_orange" type="reset">
                Cancel
              </Button>
            </>
          );
        } else if (
          // creator
          loggedBy === "creator" &&
          convInfo &&
          convInfo.completionState === JobCompletionState.ONGOING &&
          convInfo.markByCreator !== SubmissionMark.CREATOR_MARKED_COMPLETE
        ) {
          return (
            <>
              <Button
                className="mb-2"
                variant="r10_orange text-white"
                type="submit"
              >
                Submit
              </Button>
              <Button variant="light text-r10_orange" type="reset">
                Cancel
              </Button>
            </>
          );
        }
        break;
      case "report-complete":
        if (loggedBy === "user") {
          return (
            <>
              <Col
                xs={10}
                sm={9}
                md={7}
                lg={5}
                className="my-2 pe-3 d-flex justify-content-end"
              >
                <Button
                  variant="r10_orange text-white"
                  className="rounded-pill me-2 custom-minw-fit inquiry-r-c-button"
                  onClick={() => setReportShow(true)}
                  disabled={convInfo.reportByEndUser}
                >
                  {convInfo.reportByEndUser ? "Reported" : "Report Inquiry"}
                </Button>
                {/* </Col>
              <Col xs={2} className="my-2"> */}
                <Button
                  variant="secondary"
                  className="rounded-pill custom-minw-fit inquiry-r-c-button"
                  onClick={handleComplete}
                  disabled={
                    convInfo.completionState !== JobCompletionState.ONGOING ||
                    convInfo.markByEndUser !== SubmissionMark.CAN_MARK_COMPLETE
                  }
                >
                  {convInfo.completionState === JobCompletionState.ONGOING
                    ? // ? InquiryTexts.r10_respond
                      "Complete"
                    : convInfo.completionState === JobCompletionState.COMPLETED
                    ? InquiryTexts.r10_complete
                    : InquiryTexts.r10_expired}
                </Button>
              </Col>
            </>
          );
        } else if (loggedBy === "creator") {
          return (
            <>
              <Col
                xs={10}
                sm={9}
                md={7}
                lg={5}
                className="my-2 pe-3 d-flex justify-content-end"
              >
                <Button
                  variant="r10_orange text-white"
                  className="rounded-pill me-2 custom-minw-fit inquiry-r-c-button"
                  onClick={() => setReportShow(true)}
                  disabled={convInfo.reportByCreator}
                >
                  {convInfo.reportByCreator ? "Reported" : "Report Inquiry"}
                </Button>
                {/* </Col>
              <Col xs={2} className="my-2"> */}
                <Button
                  variant="secondary"
                  className="rounded-pill custom-minw-fit inquiry-r-c-button"
                  onClick={handleComplete}
                  disabled={
                    convInfo.completionState !== JobCompletionState.ONGOING ||
                    convInfo.markByCreator !==
                      SubmissionMark.CAN_MARK_COMPLETE ||
                    convInfo.markByCreator ===
                      SubmissionMark.CREATOR_MARKED_COMPLETE
                  }
                >
                  {convInfo.completionState === JobCompletionState.ONGOING
                    ? // ? InquiryTexts.r10_respond
                      "Complete"
                    : convInfo.completionState === JobCompletionState.COMPLETED
                    ? InquiryTexts.r10_complete
                    : InquiryTexts.r10_expired}
                </Button>
              </Col>
            </>
          );
        }
        break;
      case "continue-end":
        if (
          loggedBy === "user" &&
          convInfo.completionState === JobCompletionState.ONGOING &&
          convInfo.markByCreator === SubmissionMark.CREATOR_MARKED_COMPLETE
        ) {
          return (
            <Row className="d-flex flex-column mb-3 mx-1 bg-r10_orange rounded-3">
              <p className="text-center mb-2">
                {changeTextWithParameters(
                  InquiryTexts.r10_creator_marked_complete,
                  [{ var: "&CREATOR&", text: inquiryCreator.creatorName }]
                )}
              </p>
              <Button
                variant="light"
                className="col col-10 col-md-6 mx-auto mb-2 text-r10_text_disabled_dark"
                type="button"
                onClick={(event) => handleContinueOrEnd(event, undefined, true)}
              >
                Continue
              </Button>
              <Button
                variant="light"
                className="col col-10 col-md-6 mx-auto mb-2 text-r10_text_disabled_dark"
                type="button"
                onClick={(event) =>
                  handleContinueOrEnd(event, undefined, false)
                }
              >
                End
              </Button>
            </Row>
          );
        } else if (
          loggedBy === "creator" &&
          convInfo.completionState === JobCompletionState.ONGOING &&
          convInfo.markByEndUser === SubmissionMark.USER_ASKED_TO_CONTINUE
        ) {
          return (
            <Row className="d-flex flex-column mb-3 mx-1 bg-r10_orange rounded-3">
              <p className="text-center mb-2">
                {changeTextWithParameters(
                  InquiryTexts.r10_user_asked_to_continue,
                  [{ var: "&USER&", text: inquiryUser.name }]
                )}
              </p>
              <Button
                variant="light"
                className="col-6 mx-auto mb-2 text-r10_text_disabled_dark"
                type="button"
                onClick={(event) => handleContinueOrEnd(event, undefined, true)}
              >
                Continue
              </Button>
              <Button
                variant="light"
                className="col-6 mx-auto mb-2 text-r10_text_disabled_dark"
                type="button"
                onClick={(event) =>
                  handleContinueOrEnd(event, undefined, false)
                }
              >
                End
              </Button>
            </Row>
          );
        }
        break;
      case "summary":
        {
          // if (loggedBy === "user") {
          if (
            convInfo.completionState !== JobCompletionState.ONGOING &&
            summaryExtentShow
          ) {
            return (
              <Row className="d-flex flex-column mb-3 mx-1 bg-r10_orange rounded-3">
                <p className="text-center my-3">
                  {InquiryTexts.r10_summary_desc}
                </p>
                <Button
                  variant="light"
                  className="col col-10 col-md-6 mx-auto mb-2 text-r10_text_disabled_dark"
                  type="button"
                  onClick={() => setSummaryShow(true)}
                >
                  View Summary
                </Button>
                <Button
                  variant="light"
                  className="col col-10 col-md-6 mx-auto mb-2 text-r10_text_disabled_dark"
                  type="button"
                  onClick={() => setSummaryExtentShow(false)}
                  disabled={convInfo.reportByCreator}
                >
                  Later
                </Button>
              </Row>
            );
          }
          // } else {
          //   //  creator
          // }
        }
        break;
      case "comments":
        if (
          loggedBy === "user" &&
          (!convInfo ||
            (convInfo.completionState === JobCompletionState.ONGOING &&
              convInfo.markByCreator !==
                SubmissionMark.CREATOR_MARKED_COMPLETE))
        ) {
          return (
            <Button
              className="mt-auto mx-auto col-4"
              variant="r10_orange text-white"
              onClick={handleSavePosts}
            >
              Save
            </Button>
          );
        } else if (
          loggedBy === "creator" &&
          convInfo &&
          convInfo.completionState === JobCompletionState.ONGOING &&
          convInfo.markByCreator !== SubmissionMark.CREATOR_MARKED_COMPLETE
        ) {
          return (
            <Button
              className="mt-auto mx-auto col-4"
              variant="r10_orange text-white"
              onClick={handleSavePosts}
            >
              Save
            </Button>
          );
        }
        break;
      default:
        break;
    }
  }

  function photoList() {
    if (uiInputs.posts) {
      return (
        <Stack
          id="post_stack"
          gap={2}
          className="overflow-auto pt-0 pt-md-3 h-60"
        >
          {uiInputs.posts.map((post, index) => (
            // <Button
            <div
              // id={"post_" + post.photoURL}
              key={post.photoURL}
              role="button"
              className={
                selectedPost === index
                  ? "d-flex flex-wrap position-relative mb-2 me-2 border border-2 border-r10_orange "
                  : "d-flex flex-wrap position-relative mb-2 me-2 border-0"
              }
              onClick={(event) => handleSelectPostFromList(event, index)}
              // onClick={() => setSelectedPost(index)}
              // onClick={() => setUiPostFields(index)}
              onDoubleClick={() => setPhotoPop(post.actualPhoto)}
            >
              <div
                id={"delete_" + post.photoURL}
                role="button"
                className="position-absolute top-0 end-0 small-button mt-1 me-1"
                onClick={(event) => handleDeletePhoto(event, index)}
                hidden={fieldBooleans("post-delete", "hidden") ? true : false}
              >
                Delete
              </div>

              <Image
                className="mt-3 review-photo col-12"
                src={post.actualPhoto}
              />
              {post.comments[0] && (
                <div className="px-1 text-r10_orange">
                  <small>{post.comments[0].ownerName}</small>
                </div>
              )}
              {post.comments[0] && (
                <div className="px-1">
                  <small>{post.comments[0].text}</small>
                </div>
              )}
              {/* </Button> */}
            </div>
          ))}
        </Stack>
      );
    }
  }

  function setPageLayout() {
    if (
      pending &&
      // not to show pending when any pop-ups appear
      !summaryShow &&
      !reportShow &&
      !paymentShow &&
      !confirmationShow
      // not to show pending when any pop-ups appear
    ) {
      return loading();
      // } else if (status) {
      //   return statusAlert(status, statusVariant);
      // } else if (photoPop) {
      //   return photoPopUp(photoPop, setPhotoPop);
    } else {
      return (
        <Stack gap={1} direction="horizontal" className="px-3 h-100 w-100">
          {/* photo list column */}
          <Form
            className="d-flex flex-column col-3 photo-list py-3 pe-3"
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            {/* category */}
            <Form.Text id="category-label" muted className="d-none d-md-inline">
              Category
            </Form.Text>
            <Form.Control
              id="category"
              type="text"
              className="mb-1 d-none d-md-inline"
              defaultValue={conversationTopics(service.typeOfService)}
              readOnly
              aria-describedby="category-label"
            />
            {/* subject */}
            <Form.Text id="subject-label" muted className="d-none d-md-inline">
              Subject
            </Form.Text>
            <Form.Control
              id="subject"
              type="text"
              className="mb-1 d-none d-md-inline"
              defaultValue={uiInputs.subject}
              required
              readOnly={fieldBooleans("subject", "disabled") ? true : false}
              aria-describedby="subject-label"
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />

            {/* link */}
            <Form.Text id="link-label" muted className="d-none d-md-inline">
              Link
            </Form.Text>
            <Form.Control
              id="link"
              type="url"
              className="mb-3 d-none d-md-inline"
              placeholder="https://..."
              defaultValue={uiInputs.link}
              // same as subject
              readOnly={fieldBooleans("subject", "disabled") ? true : false}
              aria-describedby="link-label"
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
            {photoList()}
            {formButtons("add-photo")}
            {formButtons("submit-cancel")}
          </Form>

          <div className="vr" />

          {/* post comments column */}
          <Stack className="col-5 h-100 py-3 px-1 position-relative">
            <Row className="sticky-none sticky-md-top align-items-center justify-content-end">
              {convInfo && formButtons("report-complete")}
            </Row>
            {convInfo && formButtons("continue-end")}
            {convInfo && formButtons("summary")}
            {/* mobile category-subject-link */}
            <Form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="d-inline d-md-none px-3"
            >
              <Row>
                {/* category */}
                <Form.Text id="category-label" muted>
                  Category
                </Form.Text>
                <Form.Control
                  id="category"
                  type="text"
                  defaultValue={conversationTopics(service.typeOfService)}
                  readOnly
                  aria-describedby="category-label"
                />
              </Row>
              <Row>
                {/* subject */}
                <Form.Text id="subject-label" muted>
                  Subject
                </Form.Text>
                <Form.Control
                  id="subject"
                  type="text"
                  defaultValue={uiInputs.subject}
                  required
                  readOnly={fieldBooleans("subject", "disabled") ? true : false}
                  aria-describedby="subject-label"
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </Row>
              <Row>
                {/* link */}
                <Form.Text id="link-label" muted>
                  Link
                </Form.Text>
                <Form.Control
                  id="link"
                  type="url"
                  placeholder="https://..."
                  defaultValue={uiInputs.link}
                  // same as subject
                  readOnly={fieldBooleans("subject", "disabled") ? true : false}
                  aria-describedby="link-label"
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />
              </Row>
              {uiPost && <hr />}
            </Form>
            {uiPost && (
              <Form className="overflow-auto py-3 px-1">
                {/* picture */}
                <Image
                  className="mb-3 d-flex mx-auto review-post-photo"
                  src={uiPost.actualPhoto}
                  onDoubleClick={() => setPhotoPop(uiPost.actualPhoto)}
                />

                {/* comments */}
                {uiPost &&
                  uiPost.comments &&
                  uiPost.comments.map((comment, index) => (
                    <FloatingLabel
                      label={comment.ownerName}
                      className="mb-3"
                      key={uiPost.photoURL + "_" + index}
                    >
                      <Form.Control
                        id={"comment_" + index}
                        className="mb-3"
                        as="textarea"
                        style={{ height: "10vh" }}
                        defaultValue={comment.text}
                        readOnly={
                          (convInfo &&
                            (convInfo.completionState !==
                              JobCompletionState.ONGOING ||
                              convInfo.markByCreator ===
                                SubmissionMark.CREATOR_MARKED_COMPLETE)) ||
                          comment.isSeen ||
                          currentUser !== comment.userId
                        }
                        onChange={handleInputChange}
                      />
                    </FloatingLabel>
                  ))}
                {/* <Form.Control
                className="mb-3"
                as="textarea"
                rows={3}
                defaultValue="sdadasdad"
              /> */}
              </Form>
            )}
            {uiPost && formButtons("comments")}
            {status &&
              statusAlert(status, statusVariant, undefined, alertClass)}
          </Stack>

          {photoPopUp(photoPop, setPhotoPop)}

          {reportShow && (
            <ReportJob
              job={convInfo}
              loggedBy={loggedBy}
              reportShow={reportShow}
              reportCallBack={reportCallBack}
            />
          )}
          {paymentShow && (
            <PaymentCheckout
              paymentShow={paymentShow}
              paymentIntent={paymentIntent}
              paymentCallBack={paymentCallBack}
              paymentConvo={paymentConvo}
              service="Review"
            />
          )}
          {summaryShow && (
            <Summary
              job={convInfo}
              loggedBy={loggedBy}
              summaryShow={summaryShow}
              summaryCallBack={summaryCallBack}
              setUpdatePending={setUpdatePending}
            />
          )}
          {confirmationShow && (
            <ConfirmationPopUp
              show={confirmationShow}
              setShow={setConfirmationShow}
              callBackFunction={callBackFunction}
              modalElements={modalElements}
            />
          )}
        </Stack>
      );
    }
  }

  return setPageLayout();
};

export default ReviewDetail;

// get all photos awaiting then set them all together
async function getPostPhotos(convInfo, user, setUiInputs, setPending) {
  setPending(true);

  // let ui = { subject: convInfo.subject, posts: [] };
  let ui = JSON.parse(JSON.stringify(convInfo));

  ui.posts = [];
  // convInfo.posts.map((post, index) => {
  for (let i = 0; i < convInfo.posts.length; i++) {
    let newPost = { ...convInfo.posts[i] };
    let fileName =
      "user" + user.uid + "/sub" + convInfo.id + "_" + newPost.photoURL;

    function setFile() {}
    function setFilePending() {}
    function setFileError() {}

    let photo = await getFbFile(
      fileName,
      setFile,
      setFilePending,
      setFileError
    );
    newPost.actualPhoto = photo;
    ui.posts.push(newPost);
  }
  // });
  setUiInputs(ui);
  setPending(false);
}

// after submit
async function paymentAndDatabaseChange(
  convInfo,
  newConvo,
  postFiles,
  inquiryUser,
  inquiryCreator,
  setPending,
  setUpdatePending,
  setStatus,
  setStatusVariant,
  setPaymentShow,
  setPaymentIntent
) {
  var asyncPending;
  var asyncStatus;
  var asyncError;

  function setAsyncData(data) {}
  function setAsyncPending(bool) {
    asyncPending = bool;
  }
  function setAsyncStatus(status) {
    asyncStatus = status;
  }
  function setAsyncError(error) {
    asyncError = error;
  }

  if (!convInfo) {
    // new review created

    // new data in CompletedJob with UUID and  get payment results
    // ******************************
    // create data
    await createDoc(
      "CompletedJob",
      newConvo.id,
      newConvo,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!! - payment rollback
      setStatusVariant("danger");
      setStatus("Error : " + asyncError.message);
      setPending(false);
      return;
    }

    // upload post files
    var fileIndex = 0;
    function uploadFileLoop() {
      if (fileIndex === postFiles.length) {
        // all files updated - start payment process
        startPaymentProcess();

        // setUpdatePending(true);
        // setStatusVariant("success");
        // setStatus("Success");
        // // after everything is done, setPending(false)
        // setPending(false);
        // return;
      }
      let fileName =
        "user" +
        newConvo.userId +
        "/sub" +
        newConvo.id +
        "_" +
        postFiles[fileIndex].photoURL;

      uploadEncodedStringFile(
        fileName,
        postFiles[fileIndex].encoded,
        handleUploadProgress,
        handleUploadError,
        handleUploadSuccess
      );
      fileIndex++;
    }
    function handleUploadProgress() {}
    function handleUploadError(error) {
      // error handling !!!
      // uploadFileLoop();
    }
    function handleUploadSuccess(file) {
      uploadFileLoop();
    }

    async function startPaymentProcess() {
      // + get new payment intent data created with the record
      var intents = [],
        paymentIntent,
        turnTimeOut = 0;

      while (paymentIntent === undefined && turnTimeOut < 40) {
        intents = await getFbDocs(
          `CompletedJob/${newConvo.id}/PaymentIntent`,
          setAsyncData,
          setAsyncPending,
          setAsyncError
        );
        if (intents && intents.length > 0) {
          paymentIntent = intents[intents.length - 1];
          break;
        }
        setTimeout(() => {
          turnTimeOut++;
        }, 500);
      }

      // + show payment intent with stripe UI
      if (!paymentIntent) {
        delDoc(
          "CompletedJob",
          newConvo.id,
          setAsyncPending,
          setAsyncStatus,
          setAsyncError
        );
        for (let i = 0; i < postFiles.length; i++) {
          let fileName =
            "user" +
            newConvo.userId +
            "/sub" +
            newConvo.id +
            "_" +
            postFiles[i].photoURL;
          deleteFile(fileName);
        }
      } else {
        setPaymentShow(true);
        setPaymentIntent(paymentIntent);
        setPending(false);
      }
      // after payment confirmed, add question id to creator and user
    }

    uploadFileLoop();
  } else {
    // review job updated
    setPending(true);
    setUpdatePending(true);
    await patchData(
      "CompletedJob",
      convInfo.id,
      newConvo,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!! - review job could not updated
      setStatusVariant("danger");
      setStatus("Error : " + asyncError.message);
      setPending(false);
      return;
    }

    setUpdatePending(false);
    setStatusVariant("success");
    setStatus("Success");
    setPending(false);
  }
}
