export const JobCompletionState = {
  ONGOING: "ongoing",
  COMPLETED: "completed",
  EXPIRED: "expired",
  FLAGGED: "flagged",
};
export const SubmissionMark = {
  CREATOR_WILL_SUBMIT_RESPONSE: "creatorWillSubmitResponse",
  USER_IS_WAITING_RESPONSE: "userIsWaitingResponse",
  COMPLETED: "completed",
  CAN_MARK_COMPLETE: "canMarkComplete",
  CREATOR_MARKED_COMPLETE: "creatorMarkedComplete",
  USER_ASKED_TO_CONTINUE: "userAskedToContinue",
  NO_ACTION: "noAction",
};
export const SubmissionAlert = {
  NO_ALERT: "noAlert",
  LATE_ALERT_MAXIMUM_DAYS_PASSED: "lateAlertMaximumDaysPassed",
  LATE_ALERT_MINIMUM_DAYS_PASSED: "lateAlertMinimumDaysPassed",
  USER_DENIED_COMPLETION: "userDeniedCompletion",
};
export const MAXIMUM_RESPONSE_TIME = 5;
export const MIN_DAY_FOR_ALERT = 2;
export const BuildConfig = { BUILD_TYPE: "prod" };
export const Status = { SUCCESS: "success" };

export const eduTexts = {
  // question texts
  r10_one_question_education_title: "Submitting a Single Question",
  r10_one_question_education_vp_first: "Write your question.",
  r10_one_question_education_vp_second:
    "Submit your question after purchasing the required amount of tokens from the Token Shop. Do not worry, your tokens will only be taken from you permanently once the inquiry is completed.",
  r10_one_question_education_vp_third:
    "The inquiry will be considered complete when the creator responds to the question.",
  r10_one_question_education_vp_fourth:
    "In case the user does not respond within a specified number of days, the inquiry will be automatically marked as complete.\n\nIn the event that the creator does not respond, the users token will be immediately refunded.",
  r10_one_question_education_vp_fifth:
    "If a question is deemed inappropriate based on the following rules, the user will be charged without receiving an answer.\n- Completely unrelated to the category,\n- Containing pornographic content,\n- Featuring harassment or offensive content to the creator",

  // review texts
  r10_review_education_title: "Submitting a Review Request",
  r10_review_education_vp_first:
    "Select photos or screenshots for review. Optionally provide comments for each photo selected. If applicable, add your social media link to receive a review",
  r10_review_education_vp_second:
    "Submit your question after purchasing the required amount of tokens from the Token Shop. Do not worry, your tokens will only be taken from you permanently once the inquiry is completed.",
  r10_review_education_vp_third:
    "The inquiry is considered complete when both the creator and user mark it as such.",
  r10_review_education_vp_fourth:
    "In case the user does not respond within a specified number of days, the inquiry will be automatically marked as complete.\n\nIn the event that the creator does not respond, the users token will be immediately refunded.",
  r10_review_education_vp_fifth:
    "If a question is deemed inappropriate based on the following rules, the user will be charged without receiving an answer.\n- Completely unrelated to the category,\n- Containing pornographic content,\n- Featuring harassment or offensive content to the creator",
};

export const InquiryTexts = {
  r10_submission_report: "Report Inquiry",
  r10_submission_waiting_response: "Waiting for response",
  r10_complete: "Completed",
  r10_expired: "Expired",
  r10_respond: "Respond",
  r10_one_question_submission_answer_hint_user:
    "Creator has not responded yet.",
  r10_one_question_submission_answer_hint: "Write your answer",
  r10_prompt_text_creator: "You completed the Inquiry!",
  r10_one_question_submission_answer_title: "Answer the question below:",
  rc_answer_is_irrelevant: "Answer is not relevant",
  rc_question_is_irrelevant: "Question is not relevant",
  rc_no_answer: "No response was received",
  rc_includes_adult_content: "Contains pornographic content",
  rc_other: "Other",
  r10_report_title: "Please state the reason for reporting:",
  r10_report_selector_default: "Select the reason for reporting",
  r10_report_success:
    "Your report has been submitted. Our team will review it and get back to you as soon as possible.",
  r10_summary_title_creator: "The Inquiry has been completed!",
  r10_summary_title_user: "Your question has been answered!",
  r10_summary_contact_support:
    "<u>Are you experiencing any issues? Please contact our support team for assistance.</u>",
  r10_summary_desc_creator:
    "The conversation has ended. Payment for the Inquiry has been transferred to you.\n\nA receipt for the payment will be sent to your email.",
  r10_summary_desc_user:
    "The conversation has ended. Please give the creator a thumbs up if you found the interaction enjoyable.\n\nA receipt for the payment will be sent to your email.",
  r10_summary_desc: "Question has been answered. View summary.",
  r10_complete_confirmation:
    "Are you sure you want to mark this inquiry as complete?",
  r10_creator_marked_complete:
    "&CREATOR& wants to complete, do you want to continue / end?",
  r10_user_asked_to_continue:
    "&USER& refused to mark the inquiry as complete. Keep complete mark?",
  r10_are_you_sure_selection:
    "Are you sure you want to &SELECTION& the conversation?",
  r10_inquiry_idle_alert:
    "You have been idle for a day; if you don't respond by tomorrow. The inquiry will be closed.",
  r10_inquiry_idle_closed:
    "The inquiry has not been responded in time. Inquiry closed!",
};

export const messageTexts = {
  cpe_same_link: "Same social media type not allowed!",
  cpe_same_service: "Same type of service not allowed!",
};
