import React from "react";
import { Button, Modal, ModalFooter } from "react-bootstrap";

const ConfirmationPopUp = ({
  show,
  setShow,
  callBackFunction,
  modalElements,
}) => {
  return (
    <Modal
      size="lg"
      show={show}
      centered
      animation="fade"
      onHide={() => setShow(false)}
    >
      {modalElements.header && (
        <Modal.Header closeButton className="bg-r10_bg_default">
          {modalElements.header}
        </Modal.Header>
      )}
      {modalElements.body && (
        <Modal.Body className="bg-r10_bg_default rounded-3">
          {modalElements.body}
        </Modal.Body>
      )}
      {!modalElements.alert && (
        <ModalFooter className="bg-r10_bg_default">
          <Button
            variant="r10_orange text-white"
            type="button"
            onClick={(event) => callBackFunction(event, true)}
          >
            Accept
          </Button>
          <Button
            variant="light text-r10_orange"
            type="button"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      )}
      {modalElements.alert && (
        <ModalFooter className="bg-r10_bg_default rounded-3">
          <Button
            variant="r10_orange text-white"
            type="button"
            onClick={(event) => setShow(false)}
          >
            Okay
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ConfirmationPopUp;
