import React from 'react';
import {isMobile} from "../utilities/Functions";
import WhyRated10Creators from '../components/WhyRated10Creators.js';
import WhyRated10Users from '../components/WhyRated10Users.js';
import GetStarted from '../components/GetStarted.js';

const WhyRated10 = () => {
    const textStyle = {
      fontSize: isMobile() ? '1.2em' : '2.3em',
      textAlign: 'left',
      marginBottom: '1.7em',
      paddingLeft: isMobile() ? '0px' : '20px',
    };

    const containerStyle = {
      display: 'flex',           // Use flexbox to align items horizontally
      justifyContent: 'space-between',  // Space between the components
      alignItems: 'flex-start',   // Align items to the top
      width: '100%',             // Full width container
      padding: '20px',           // Optional padding
      boxSizing: 'border-box',
    };
  
    const columnStyle = {
      width: '50%',              // Ensure equal width for both components
      padding: '10px',           // Optional: Add some padding between the components
      boxSizing: 'border-box',   // Ensure padding is included in width calculations
    };

    const darkBlueColor = '#3B3F4E'
    const lightBlueColor = '#5D677F'
    const orangeColor = '#E35335'

    class Header extends React.Component {
        render() {
          return (
            <header className="App-header">
              <h2 style={{ ...textStyle, marginBottom: '1.5em', marginTop: '1.5em' }}>
                <span style={{color: darkBlueColor}}>
                  Why
                </span>
                {" "} {/* Add a space between the spans */}
                <span style={{color: orangeColor, fontWeight: 'bold'}}>
                  you should use 
                </span>
                {" "}
                <span style={{color: darkBlueColor}}>
                  Rated10:
                </span>
              </h2>
            </header>
          );
        }
      }

  function setPageLayout() {
    if (isMobile()) {
      return (
        <div className="App">
          <Header />
          <WhyRated10Creators />
          <WhyRated10Users />
          <GetStarted />
        </div>
      );
    } else {
      return (
        <div className="App">
          <Header />
          <div style={containerStyle}>
            <div style={columnStyle}>
              <WhyRated10Creators />
            </div>
            <div style={columnStyle}>
              <WhyRated10Users />
            </div>
          </div>
          <GetStarted />
        </div>
      );
    }
  }
  return setPageLayout();
};

export default WhyRated10;
