import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  createDoc,
  getBase64,
  linkAndBrand,
  uploadEncodedStringFile,
} from "../utilities/Functions";
import { v4 as uuidv4, validate } from "uuid";
import Compressor from "compressorjs";

const ApplyToBeCreator = ({ callBack, setUpdatePending }) => {
  var user = JSON.parse(sessionStorage.getItem("token"));
  var launchSettings = JSON.parse(sessionStorage.getItem("launchSettings"));

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  //   const [updatePending, setUpdatePending] = useState();

  const [appliantInfo, setAppliantInfo] = useState({
    email: null,
    isDocUploadSuccessful: null,
    fullName: null,
    socialMediaHandle: null,
    socialMediaType: null,
    userId: user.uid,

    // extra fields
    docName: `creatorApp_${uuidv4()}`,
    file: null,
    filename: `user${user.uid}/${user.uid}CreatorDoc`,
  });

  const inputFile = useRef(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const linksArray = linkAndBrand();

  // self form validation (for self notifications)
  useEffect(() => {
    var forms = document.querySelectorAll(".needs-validation");
    Array.prototype.slice.call(forms).forEach(function (form) {
      form.addEventListener("submit", (event) => validate(event, form), false);
    });
  }, []);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  // form validation
  function validate(event, form) {
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }

    form.classList.add("was-validated");
  }

  function onFileSelected(event) {
    setPending(true);
    setUploadSuccess(false);

    new Compressor(event.target.files[0], {
      quality: 1 - launchSettings.photoCompressionQuality,
      success: (compressedResult) => {
        getBase64(compressedResult)
          .then((result) => {
            setAppliantInfo({
              ...appliantInfo,
              isDocUploadSuccessful: "true",
              file: result,
            });
            setPending(false);
            setUploadSuccess(true);
          })
          .catch((err) => {
            setUploadSuccess(false);
            // console.log(err);
          });
      },
    });

    // getBase64(event.target.files[0])
    //   .then((result) => {
    //     setAppliantInfo({
    //       ...appliantInfo,
    //       isDocUploadSuccessful: "true",
    //       file: result,
    //     });
    //     setPending(false);
    //     setUploadSuccess(true);
    //   })
    //   .catch((err) => {
    //     setUploadSuccess(false);
    //     // console.log(err);
    //   });
  }

  function handleUploadDoc(event) {
    if (inputFile === null) return;

    inputFile.current.click();
  }

  function handleInputChanges(event) {
    if (event.target.id !== "isDocUploadSuccessful") {
      setAppliantInfo({
        ...appliantInfo,
        [event.target.id]: event.target.value,
      });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // check everything is fine !!!
    let data = {
      email: appliantInfo.email,
      isDocUploadSuccessful: appliantInfo.isDocUploadSuccessful,
      fullName: appliantInfo.fullName,
      socialMediaHandle: appliantInfo.socialMediaHandle,
      socialMediaType: appliantInfo.socialMediaType,
      userId: appliantInfo.userId,
    };

    setPending(true);
    setStatus("Submitting application");
    // setUpdatePending(true);

    async function createApplicationDoc(isDocUploaded) {
      data.isDocUploadSuccessful = isDocUploaded;

      function setVoidPending(bool) {}

      await createDoc(
        "CreatorApplication",
        appliantInfo.docName,
        data,
        setVoidPending,
        setStatus,
        setError
      );
      if (error) {
        // error handling !!!
        setStatus("Try again later, please");
        setTimeout(() => {
          setPending(false);
          return;
        }, 3000);
      }

      sessionStorage.setItem("applyToBeCreator", JSON.stringify(true));
      setStatus(
        isDocUploaded
          ? "Application received"
          : "Application received without document"
      );
      setTimeout(() => {
        setPending(false);
        setUpdatePending(true);
        callBack();
      }, 3000);
    }

    function handleUploadProgress() {}

    function handleUploadError(error) {
      // error handling !!!
      setStatus("Document couldn't be uploaded");
      createApplicationDoc(false);
    }

    function handleUploadSuccess() {
      setStatus("Document uploaded");
      createApplicationDoc(true);
    }

    setPending(true);
    uploadEncodedStringFile(
      appliantInfo.filename,
      appliantInfo.file,
      handleUploadProgress,
      handleUploadError,
      handleUploadSuccess
    );

    // // sessionStorage.setItem("applyToBeCreator", JSON.stringify(true));
    // setStatus("Application received");
    // setTimeout(() => {
    //   setPending(false);
    //   // setUpdatePending(false);
    //   setUpdatePending(true);
    //   callBack();
    // }, 3000);
  }

  return (
    <Modal size="lg" show={true} centered onHide={callBack}>
      <Modal.Header closeButton className="bg-r10_bg_default">
        <Modal.Title className="w-100 text-center fw-bold">
          Apply to be a Creator
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-r10_bg_default rounded-3">
        <Form
          noValidate
          className="d-flex flex-column needs-validation"
          onSubmit={handleSubmit}
          onReset={callBack}
        >
          {/* header text */}
          <Form.Text className="mb-3">
            Fill out the form below and we'll get back to you within 72 hours.
          </Form.Text>

          {/* fullname */}
          <Form.Group controlId="fullName" className="col-6 mb-3">
            <Form.Label className="fw-bold">Step 1: Verify identity</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Full name"
              onChange={handleInputChanges}
            />
            <Form.Control.Feedback type="invalid">
              Please provide your full name
            </Form.Control.Feedback>
          </Form.Group>

          {/* document upload */}
          <Form.Group controlId="document" className="col-6 mb-3">
            <Row>
              <Col>
                <Button
                  variant="outline-r10_orange"
                  className="bg-white"
                  onClick={handleUploadDoc}
                >
                  Upload an ID or Passport for identity proof
                </Button>
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  ref={inputFile}
                  onChange={onFileSelected}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please upload an identity document
                </Form.Control.Feedback>
              </Col>
              <Col className="align-self-center">
                <Form.Text muted hidden={!uploadSuccess}>
                  Upload Successful
                </Form.Text>
              </Col>
            </Row>
          </Form.Group>

          {/* social media */}
          <Row className="col-12 mb-3">
            <Form.Label className="fw-bold">
              Step 2: Provide your most prominent social media link
            </Form.Label>
            <Col xs={6}>
              <Form.Group controlId="socialMediaType">
                <Form.Select
                  className="bg-r10_bg_default text-r10_orange border border-r10_orange"
                  onChange={handleInputChanges}
                  // id="socialMediaType"
                  defaultValue=""
                  required
                >
                  <option value="" disabled>
                    Select Social Media
                  </option>
                  {linksArray.map((array) => (
                    <option value={array.brand} key={array.brand}>
                      {array.text}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a social media type
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="socialMediaHandle">
                <Form.Control
                  type="text"
                  // id="socialMediaHandle"
                  placeholder="Write handle here"
                  onChange={handleInputChanges}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide your social media handle
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Form.Control.Feedback type="invalid">
              Please select a social media and enter your corresponding handle
            </Form.Control.Feedback>
          </Row>

          {/* email */}
          <Form.Group controlId="email" className="col-6 mb-3">
            <Form.Label className="fw-bold">
              Step 3: Enter your Email address
            </Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Email address"
              onChange={handleInputChanges}
            />
            <Form.Control.Feedback type="invalid">
              Please provide your email address
            </Form.Control.Feedback>
          </Form.Group>

          {/* footer text */}
          <Form.Text muted className="mb-3">
            Note: You are not automatically enrolled on Rated10. If you meet the
            eligibility requirements, a talent representative will contact you
            to finish onboarding via your registered email address.
          </Form.Text>

          <Button
            className="col-4 mx-auto mb-3"
            variant="r10_orange text-white"
            type="submit"
            disabled={pending}
          >
            {pending ? status : "Apply"}
          </Button>

          <Button
            className="col-4 mx-auto mb-3"
            variant="light text-r10_orange"
            type="reset"
            hidden={pending}
          >
            Cancel
          </Button>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer className="w-100 justify-content-center bg-r10_bg_default rounded-3">
      </Modal.Footer> */}
    </Modal>
  );
};

export default ApplyToBeCreator;
