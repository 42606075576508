import { Button, Form, Image, Modal } from "react-bootstrap";
import { patchData } from "../utilities/Functions";
import { InquiryTexts } from "../utilities/Constants";
import Icon_thumbs_up from "../assets/Icon_thumbs_up.svg";
import Icon_thumbs_uped from "../assets/Icon_thumbs_uped.svg";
import Icon_verified from "../assets/Icon_verified.svg";
import { useEffect, useState } from "react";
import { useGetDoc } from "../utilities/CustomHooks";

const Summary = ({
  job,
  loggedBy,
  summaryShow,
  summaryCallBack,
  setUpdatePending,
}) => {
  const [pending, setPending] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  const [isThumbsUped, setIsThumbsUped] = useState(false);

  const { data: inquiryCreator } = useGetDoc(
    "Creator",
    job.creatorId,
    [job.creatorId],
    setPending,
    setError
  );

  useEffect(() => {
    if (job.receivedThumbsUp) {
      setIsThumbsUped(true);
    }
  }, [job]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  async function updateData() {
    let data = {},
      err;

    data = { receivedThumbsUp: true };
    err = await patchData(
      job.category.search("Question") > 0
        ? "CompletedOneQuestionJob"
        : "CompletedJob",
      job.id,
      data,
      setPending,
      setStatus,
      setError
    );
    if (err) {
      // error handling
    }

    data = { numberOfThumbsUp: inquiryCreator.numberOfThumbsUp + 1 };
    err = await patchData(
      "Creator",
      job.creatorId,
      data,
      setPending,
      setStatus,
      setError
    );
    if (err) {
      // error handling
    }

    setIsThumbsUped(true);
    setUpdatePending(false);
  }

  function handleThumbsUp() {
    setUpdatePending(true);
    updateData();
  }

  return (
    <Modal
      size="md"
      show={summaryShow}
      centered
      animation="fade"
      onHide={() => summaryCallBack()}
    >
      <Modal.Header
        closeButton
        className="d-flex flex-column fw-bold bg-r10_bg_default"
      >
        <Image src={Icon_verified} className="mb-3 summary-icon" />
        {InquiryTexts.r10_summary_title_user}
      </Modal.Header>
      <Modal.Body className="d-flex flex-column bg-r10_bg_default rounded-3">
        <p className="mb-3 proper-text">{InquiryTexts.r10_summary_desc_user}</p>
        <Image
          src={isThumbsUped ? Icon_thumbs_uped : Icon_thumbs_up}
          className="as-button summary-icon my-3 mx-auto"
          onClick={isThumbsUped ? undefined : handleThumbsUp}
          type="button"
          hidden={loggedBy === "user" ? false : true}
        />
      </Modal.Body>
    </Modal>
  );
};

export default Summary;
