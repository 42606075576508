import React from 'react';

const TermsConditions = () => {
    return (
        <main className="flex min-h-screen flex-col justify-center p-24 space-y-10 lg:w-full">
            <div className="flex flex-col justify-center items-start text-left lg:max-w-5xl space-y-10 mx-auto">
                <div className="font-futura-bold text-center text-4xl" style={{ marginBottom: '20px', fontSize: '2.50rem' }}>
                    Terms & Conditions
                </div>

                <div className="font-futura-medium" style={{ fontSize: '1.25rem' }}>
                    <p>
                        Acceptance of Terms: By using our social media platform, you agree to be bound by these Terms & Conditions. If you do not agree to these terms, please do not use our platform.
                        <br /><br />
                        User Content: You are solely responsible for any content you post, including text, photos, videos, and links. You grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, copy, modify, distribute, publish, and process your content in any way, in accordance with our Privacy Policy.
                        <br /><br />
                        Prohibited Conduct: You may not post any content that:
                        <br /><br />
                        Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party.
                        <br /><br />
                        Is illegal, threatening, defamatory, obscene, vulgar, pornographic, or offensive.
                        <br /><br />
                        Promotes discrimination, hate, or violence against any individual or group.
                        <br /><br />
                        Violates the privacy rights of any individual or group.
                        <br /><br />
                        User Conduct: You may not use our platform for any illegal or unauthorized purpose. You may not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                        <br /><br />
                        Modification of Terms: We reserve the right to modify these terms at any time. Your continued use of the platform after any such changes constitutes your acceptance of the new Terms & Conditions.
                        <br /><br />
                        Termination: We may, at our sole discretion, at any time terminate or suspend all or a portion of our platform, or your use of all or a portion of the platform, with or without notice and with or without cause.
                        <br /><br />
                        Disclaimer of Warranties: Our platform is provided on an "as is" and "as available" basis. We make no warranties of any kind, express or implied, as to the operation of the platform or the information, content, materials, or products included on the platform.
                        <br /><br />
                        Limitation of Liability: In no event shall we be liable for any damages of any kind arising from the use of our platform, including but not limited to direct, indirect, incidental, punitive, and consequential damages.
                        <br /><br />
                        Indemnification: You agree to indemnify and hold us harmless from any claims, damages, expenses, and costs (including attorney's fees) arising from your use of our platform, your violation of these Terms & Conditions, or your violation of any rights of another.
                        <br /><br />
                        Dispute Resolution: Any dispute arising from these Terms & Conditions will be resolved through binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association.
                        <br /><br />
                        Governing Law: These Terms & Conditions will be governed by and construed in accordance with the laws of the state in which our headquarters are located, without giving effect to any principles of conflicts of law.
                        <br /><br />
                        By using our social media platform, you agree to be bound by these Terms & Conditions. If you have any questions, please contact us.
                    </p>
                </div>
            </div>
        </main>
    );
};

export default TermsConditions;