import React, { useEffect, useState } from "react";
import { Button, Carousel, Image, Modal } from "react-bootstrap";

import Edu_pic_1 from "../assets/Edu_pic_1.jpeg";
import Edu_pic_2 from "../assets/Edu_pic_2.jpeg";
import Edu_pic_3 from "../assets/Edu_pic_3.jpeg";
import Edu_pic_4 from "../assets/Edu_pic_4.jpeg";
import Edu_pic_5 from "../assets/Edu_pic_5.jpeg";
import { eduTexts } from "../utilities/Constants";

const Education = ({ eduState, callBack }) => {
  const [isQuestion, setIsQuestion] = useState();
  const [index, setIndex] = useState(0);

  const imageStyle = {
    objectFit: "scale-down",
    height: "40vh",
    width: "100%",
  };

  const carouselItems = [
    {
      img: Edu_pic_1,
      question: eduTexts.r10_one_question_education_vp_first,
      review: eduTexts.r10_review_education_vp_first,
    },
    {
      img: Edu_pic_2,
      question: eduTexts.r10_one_question_education_vp_second,
      review: eduTexts.r10_review_education_vp_second,
    },
    {
      img: Edu_pic_3,
      question: eduTexts.r10_one_question_education_vp_third,
      review: eduTexts.r10_review_education_vp_third,
    },
    {
      img: Edu_pic_4,
      question: eduTexts.r10_one_question_education_vp_fourth,
      review: eduTexts.r10_review_education_vp_fourth,
    },
    {
      img: Edu_pic_5,
      question: eduTexts.r10_one_question_education_vp_fifth,
      review: eduTexts.r10_review_education_vp_fifth,
    },
  ];

  useEffect(() => {
    if (eduState.service.typeOfService.search("Question") > 0) {
      setIsQuestion(true);
    } else {
      setIsQuestion(false);
    }
  }, [eduState.service.typeOfService]);

  function handleSelect(selectedIndex) {
    if (selectedIndex === 0) {
      if (isQuestion) {
        JSON.stringify(sessionStorage.setItem("isQuestionEduSeen", true));
      } else {
        JSON.stringify(sessionStorage.setItem("isRevievEduSeen", true));
      }
      callBack(eduState, true);
    }
    setIndex(selectedIndex);
  }

  return (
    <Modal
      size="md"
      show={true}
      centered
      backdrop="static"
      onHide={() => callBack(eduState, false)}
    >
      <Modal.Header closeButton className="bg-r10_bg_default">
        <Modal.Title className="w-100 text-center fw-bold">
          {isQuestion
            ? eduTexts.r10_one_question_education_title
            : eduTexts.r10_review_education_title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-r10_bg_default rounded-3">
        <Carousel
          interval={null}
          controls={false}
          data-bs-theme="dark"
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <Image src={carouselItems[0].img} style={imageStyle} />
            <div style={{ height: "20vh" }}>
              {isQuestion ? carouselItems[0].question : carouselItems[0].review}
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={carouselItems[1].img} style={imageStyle} />
            <div style={{ height: "20vh" }}>
              {isQuestion ? carouselItems[1].question : carouselItems[1].review}
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={carouselItems[2].img} style={imageStyle} />
            <div style={{ height: "20vh" }}>
              {isQuestion ? carouselItems[2].question : carouselItems[2].review}
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={carouselItems[3].img} style={imageStyle} />
            <div style={{ height: "20vh" }}>
              {isQuestion ? carouselItems[3].question : carouselItems[3].review}
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={carouselItems[4].img} style={imageStyle} />
            <div style={{ height: "20vh" }}>
              {isQuestion ? carouselItems[4].question : carouselItems[4].review}
            </div>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
      <Modal.Footer className="w-100 justify-content-center bg-r10_bg_default rounded-3">
        <Button
          className="col-4"
          variant="r10_orange text-white"
          onClick={() => handleSelect((index + 1) % 5)}
        >
          {index % 5 !== 4 ? "Next" : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Education;
