import React from 'react';
import R10FooterLink from './R10FooterLink';

class R10Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isFocused: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  openApplyCreatorPage = () => {
    window.open("https://www.rated10.app/apply-to-be-creator", "_blank");
  };

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  handleFocus() {
    this.setState({ isFocused: true });
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  render() {
    const { isHovered, isFocused } = this.state;

    const footerContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '40px 20px',
      backgroundColor: '#5D677F',
      color: '#FFFFFF',
      textAlign: 'center',
    };

    const horizontalContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: '1200px', // You can adjust the max-width as needed
      gap: '50px', // Add spacing between the groups
    };

    const linkContainerStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '15px',
      margin: '20px 0',
    };

    const applyLinkContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#5D677F',
      color: '#FFFFFF',
      padding: '20px',
      borderRadius: '8px',
    };

    const applyTextStyle = {
      marginRight: '20px',
      fontSize: '18px',
      fontWeight: '500',
      color: '#FFFFFF',
      pointerEvents: 'none',
      textDecoration: 'none',
    };

    const darkBlueColor = '#3B3F4E'
    const orangeColor = '#E35335'

    const buttonHoveredColor = darkBlueColor

    const joinButtonStyle = {
      padding: '10px 20px',
      backgroundColor: '#5D677F',
      color: isHovered || isFocused ? buttonHoveredColor : '#FFFFFF',
      border: '1px solid #FFFFFF',
      borderColor: isHovered || isFocused ? buttonHoveredColor : '#FFFFFF',
      borderRadius: '5px',
      cursor: 'pointer',
    };

    return (
      <div style={footerContainerStyle}>
        <div style={horizontalContainerStyle}>
          <div style={linkContainerStyle}>
            <R10FooterLink href="https://www.rated10.app">About</R10FooterLink>
            <R10FooterLink href="https://www.rated10.app/faq">FAQ</R10FooterLink>
            <R10FooterLink href="https://www.linkedin.com/company/86437468">Jobs</R10FooterLink>
            <R10FooterLink href="https://www.rated10.app/privacy-policy">Privacy</R10FooterLink>
            <R10FooterLink href="https://www.rated10.app/terms-conditions">Terms</R10FooterLink>
            <R10FooterLink href="https://www.rated10.app/why-rated10">Why Rated10?</R10FooterLink>
            <R10FooterLink href="https://www.rated10.app/contact-us">Press</R10FooterLink>
          </div>
          <div style={applyLinkContainerStyle}>
            <span style={applyTextStyle}>Ready to become a creator on Rated10?</span>
            <button
              onClick={() => this.openApplyCreatorPage()}
              style={joinButtonStyle}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            >
              Join as a creator
            </button>
          </div>
        </div>
        <div style={linkContainerStyle}>
          <R10FooterLink href="https://www.instagram.com/rated10.app"><i className="fab fa-instagram"></i></R10FooterLink>
          <R10FooterLink href="https://www.linkedin.com/company/86437468"><i className="fab fa-linkedin"></i></R10FooterLink>
        </div>
        <p>© 2024 Rated10, LLC. All rights reserved</p>
      </div>
    );
  }
}

export default R10Footer;