import React, { useEffect, useState } from "react";
import { getFbDocsWithQuery, loading } from "../utilities/Functions";
import { Col, Container, Row } from "react-bootstrap";
import CreatorCard from "../components/CreatorCard";

const CreatorsAll = ({ searchParam }) => {
  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [creators, setCreators] = useState();
  const [uiList, setUiList] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    getAll();
  }, []);

  // set uilist with search
  useEffect(getUiList, [creators, searchParam]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  async function getAll() {
    let queryList = [
      { field: "environment", condition: "==", value: "prod" },
      //   { field: "byPassCreatorFilters", condition: "!=", value: true },
    ];

    setPending(true);

    let data = await getFbDocsWithQuery(
      "Creator",
      queryList,
      setCreators,
      //   setPending,
      undefined,
      setError
      //   isAppend
    );

    if (data) {
      data.sort((a, b) => {
        if (a.numberOfThumbsUp < b.numberOfThumbsUp) {
          return 1;
        }
        if (a.creatorName.toLowerCase > b.creatorName.toLowerCase) {
          return 1;
        }
        return -1;
      });
    }

    setCreators(data);
    setPending(false);
  }

  function getUiList() {
    if (!creators) {
      return;
    }
    let ui = [];
    let search = searchParam.toLowerCase();

    setPending(true);
    creators.forEach((creator) => {
      if (!search || creator.creatorName.toLowerCase().search(search) >= 0) {
        if (
          userInfo &&
          userInfo.asCreator &&
          userInfo.asCreator === creator.id
        ) {
          // if user is also creator, dont bring it
          return;
        }
        ui.push(creator);
      }
    });

    setUiList([...ui]);
    setPending(false);
  }

  function setPageLayout() {
    if (pending) {
      // return loading();
    } else if (!uiList) {
      return <>No creators</>;
      //   } else if (status) {
      //     return statusAlert(status, statusVariant);
    } else {
      return (
        // <Container>
        <Row className="my-4">
          {/* <Col>1 of 1</Col> */}
          {uiList.map((creator) => (
            <Col className="my-2">
              <CreatorCard creatorInfo={creator} key={`${creator.id}`} />
            </Col>
          ))}
        </Row>
        // </Container>
      );
    }
  }

  return setPageLayout();
};

export default CreatorsAll;
