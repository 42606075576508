import React, { useState } from 'react';
import { isMobile } from "../utilities/Functions";
import GetStarted from '../components/GetStarted.js';

const FAQPage = () => {
    const darkBlueColor = '#3B3F4E';
    const lightBlueColor = '#5D677F';
    const orangeColor = '#E35335';

    const faqs = [
        {
            header: "①",
            question: "What makes Rated10 different from other paid fan platforms?",
            answer: "Rated10 focuses on genuine interaction and advice sharing. Unlike fan platforms where creators may need to produce exclusive content, Rated10’s content is the direct interaction and responses to followers’ questions. This approach eliminates the need for creators to spend extra time on exclusive content creation, allowing them to focus on answering questions and offering advice, which is both personalized and meaningful. Additionally, Rated10 is commitment-free—there are no subscriptions required. Users only pay for each interaction, making it easy and flexible to connect with creators without long-term commitments."
        },
        {
            header: "②",
            question: "Can I use Rated10 on my computer or phone?",
            answer: "Yes! Rated10 is available as a mobile app for both iOS and Android devices, as well as a web app accessible from any computer."
        },
        {
            header: "③",
            question: "What happens if a fan asks the same question multiple times?",
            answer: "Our system assists creators by using AI to suggest a modified version of a previously given answer. This helps ensure responses remain fresh while saving the creator’s time when dealing with repeat inquiries."
        },
        {
            header: "④",
            question: "What if I receive too many questions on Rated10?",
            answer: "Creators can set an optional limit on unanswered questions. Once this limit is reached, new questions will stop coming in, allowing creators to manage their time more effectively without feeling overwhelmed."
        },
        {
            header: "⑤",
            question: "What are Review Inquiries and Short Questions?",
            answer: "On Rated10, users can choose between Review Inquiries and Short Questions. Review Inquiries are more in-depth and could cover topics like a dating profile review, social media page feedback, or resume evaluation. They are more premium. Short Questions are quick, targeted questions that can be answered concisely, ideal for simple advice or specific questions."
        },
        {
            header: "⑥",
            question: "How does payment processing work on Rated10?",
            answer: "Payments are securely handled via Stripe, a trusted third-party payment processor. When a user asks a question, the payment amount is put on hold until the creator answers. If the creator does not respond within the set time frame, the hold is released, ensuring a seamless and fair transaction."
        },
        {
            header: "⑦",
            question: "What are Rated10 tokens?",
            answer: "On mobile apps, users need to purchase Rated10 tokens to ask questions, due to payment policies by Apple and Google. Each token is equivalent to $1. On the web app, tokens are not required—users can directly enter their payment details."
        },
        {
            header: "⑧",
            question: "How does payment work for questions?",
            answer: "On mobile, users spend Rated10 tokens that they’ve purchased in-app to ask questions. On the web, payment information is entered at the time of asking a question, and a hold is placed on the user’s payment method until the question is answered. If the creator doesn’t respond, the token (on mobile) or payment hold (on web) is refunded."
        },
        {
            header: "⑨",
            question: "What cut does Rated10 take from each question answered?",
            answer: "Rated10 generally takes a 30% commission from all answered questions. However, we frequently run promotions and offer reduced rates, so keep an eye out for these opportunities."
        },
        {
            header: "⑩",
            question: "What if a creator receives harassment or offensive messages?",
            answer: "Rated10 has a reporting feature that protects creators from inappropriate or offensive messages. In cases where a question violates our guidelines, creators can report it. They’ll receive payment without needing to respond, ensuring a safe and respectful experience for everyone."
        },
        {
            header: "⑪",
            question: "What if I receive a meaningless answer to my question?",
            answer: "Users have the option to report answers that don’t meaningfully address their question. After reviewing the report, Rated10 will either refund the token or ensure that no charge is made to the user’s payment method, maintaining the quality and value of responses."
        },
        {
            header: "⑫",
            question: "What if I don’t receive an answer to my question?",
            answer: "Creators have 7 days to respond to a question. If no response is given within this period: On mobile apps, users will be refunded their token. On the web app, the payment hold will be released, so the user isn’t charged."
        }
    ];

    const FAQ = () => {
        const [openIndex, setOpenIndex] = useState(null);

        const toggleFAQ = (index) => {
            setOpenIndex(openIndex === index ? null : index);
        };

        const containerStyle = {
            paddingBottom: isMobile() ? '60px' : '100px',
            paddingTop: '40px',
            paddingLeft: isMobile() ? '0px' : '0px',
            paddingRight: isMobile() ? '10px' : '40px',
            boxSizing: 'border-box',
            overflowX: 'scroll',
        };
    
        const headerStyle = {
            fontSize: isMobile() ? '15px' : '18px',
            fontWeight: 'bold',
            marginBottom: '0px',
            textAlign: 'left',
            color: orangeColor,
        };
    
        const titleStyle = {
            fontSize: isMobile() ? '16px' : '23px',
            fontWeight: 'bold',
            marginBottom: '0px',
            textAlign: 'left',
            color: darkBlueColor,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        };
    
        const textStyle = {
            marginTop: '5px',
            textAlign: 'left',
            fontSize: isMobile() ? '16px' : '23px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word', 
            color: lightBlueColor,
        };
    
        const boxContainerStyle = {
            display: 'flex',
            paddingLeft: isMobile() ? '10px' : '40px',
            flexDirection: 'column',
            gap: isMobile() ? '10px' : '20px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        };
    
        const boxStyle = {
            flex: 1,
            padding: '20px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            height: 'auto',
        };

        return (
            <section id="faq" style={containerStyle}>
                <h2 style={{ fontSize: '2em', marginBottom: '40px', fontStyle: 'italic', color: lightBlueColor }}>Frequently Asked Questions</h2>
                <div style={boxContainerStyle}>
                  {faqs.map((faq, index) => (
                    <div key={index} style={boxStyle}>
                        <span style={headerStyle}>{faq.header}</span>
                        <span 
                          style={titleStyle} 
                          onClick={() => toggleFAQ(index)}
                        >
                          <span>Q: {faq.question}</span>
                          <span style={{ marginLeft: '10px' }}>
                            {openIndex === index ? "▼" : "►"}
                          </span>
                        </span>
                        {openIndex === index && (
                          <span style={textStyle}>A: {faq.answer}</span>
                        )}
                    </div>
                  ))}
                </div>
            </section>
        ); 
    };

    function setPageLayout() {
        return (
          <div className="App">
            <FAQ />
            <GetStarted />
          </div>
        );
    }
  
    return setPageLayout();
};

export default FAQPage;