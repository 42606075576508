import React from 'react';
import {Button} from "react-bootstrap";
import {isMobile} from "../utilities/Functions";
import YouTubeButton from './YoutubeButton';

class AboutUsProductDescriptionForCreators extends React.Component {
  render() {
    const containerStyle = {
      paddingBottom: isMobile() ? '20px' : '100px',
      paddingTop: isMobile() ? '20px' : '40px',
      paddingLeft: isMobile() ? '20px' : '40px',
      paddingRight: isMobile() ? '20px' : '40px',
      borderBottom: isMobile() ? '0px' : '1px solid #ddd',
      width: isMobile() ? '100%' : '66.67%', // 2/3 of the width
      color: 'black',
    };

    const wrapperStyle = {
      width: '100%',
    };

    const buttonContainerStyle = {
      display: 'flex',
      justifyContent: isMobile() ? 'flex-start' : 'left', // Align buttons to the left for both mobile and desktop
      alignItems: 'center',
      flexDirection: isMobile() ? 'column' : 'row', // Stack vertically for mobile, horizontally for desktop
      gap: isMobile() ? '5px' : '40px', // Adjust spacing between buttons
    };

    const textStyle = {
      fontSize: isMobile() ? '1.2em' : '1.7em',
      textAlign: 'left',
      marginBottom: '1.7em',
    };

    const highlightedTextStyle = {
      fontSize: isMobile() ? '1.2em' : '1.7em',
      color: '#E35335',
      fontWeight: 'bold',
    };

    const tagLineStyle = {
      fontSize: isMobile() ? '0.7em' : '1.1em',
      color: '#3B3F4E',
      fontWeight: 'bold',
      fontStyle: 'italic',
    };

    const darkBlueColor = '#3B3F4E'
    const lightBlueColor = '#5D677F'
    const orangeColor = '#E35335'

    return (
      <section
        id="product-description"
        className="d-flex flex-column justify-content-center align-items-center"
        style={containerStyle}
      >
        <div style={wrapperStyle}> {/* Add a wrapper div to apply full width */}
        <h2 style={{ ...textStyle, marginBottom: isMobile() ? '2.6em' : '3.4em' }}>
            <span style={tagLineStyle}>
              Get Paid for What You Know, Not What You Create
            </span>      
          </h2>
          <h2 style={{ ...textStyle, marginBottom: '1.7em' }}>
            <span style={highlightedTextStyle}>
              Rated10
            </span>
            {" "} {/* Add a space between the spans */}
            <span style={{color: darkBlueColor}}>
              is a direct messaging app where 
            </span>
            {" "}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              content creators
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              provide advice and answer their followers’ questions—for a
            </span>
            {" "}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              price
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              they set themselves.
            </span>
            {" "}
          </h2>
          <h2 style={{ ...textStyle, marginBottom: '3.4em' }}>
            <span style={{color: darkBlueColor}}>
              Unlike traditional fan platforms, Rated10 offers 
            </span>
            {" "} {}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              hassle-free setup
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              and
            </span>
            {" "}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              no need 
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              for exclusive content. The answers you give
            </span>
            {" "}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              are
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              your content!
            </span>
          </h2>
          {/* <h2 style={{ ...textStyle, marginBottom: '3.4em' }}>
            <span style={{color: darkBlueColor}}>
              It’s the
            </span>
            {" "} {}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              easiest
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              and
            </span>
            {" "}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              most genuine
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              way to connect with your favorite creators, supporting them
            </span>
            {" "}
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              without the hassle
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              of subscriptions or multiple platforms.
            </span>
          </h2> */}
          <div style={buttonContainerStyle}>
            <GetStartedButton />
            <YouTubeButton />
          </div>
        </div>
      </section>
    );

    return (
      <section
        id="product-description"
        className="d-flex flex-column justify-content-center align-items-center"
        style={containerStyle}
      >
        <div style={wrapperStyle}> {/* Add a wrapper div to apply full width */}
          <h2 style={{ ...textStyle, marginBottom: '1.7em' }}>
            <span style={{color: darkBlueColor}}>
              Got a quick question for your favorite creator but unsure if you’ll get a response?
            </span>
          </h2>
          <h2 style={{ ...textStyle, marginBottom: '1.7em' }}>
            <span style={{color: orangeColor, fontWeight: 'bold'}}>
              Rated10
            </span>
            {" "}
            <span style={{color: darkBlueColor}}>
              guarantees you’ll get an answer while supporting the creator you admire.
            </span>
          </h2>
          <div style={buttonContainerStyle}>
            <GetStartedButton />
            <YouTubeButton />
          </div>
        </div>
      </section>
    );

    return (
      <section
        id="product-description"
        className="d-flex flex-column justify-content-center align-items-center"
        style={containerStyle}
      >
        <div style={wrapperStyle}> {/* Add a wrapper div to apply full width */}
          <h2 style={{ ...textStyle }}>
            Got a quick question for your favorite creator but unsure if you’ll get a response?
          </h2>
          <h2 style={{ ...textStyle, marginBottom: '3.4em' }}>
            <span style={highlightedTextStyle}>
              Rated10
            </span>
            {" "} {/* Add a space between the spans */}
            guarantees you’ll get an answer while supporting the creator you admire.
          </h2>
          <div style={buttonContainerStyle}>
            <GetStartedButton />
            <YouTubeButton />
          </div>
        </div>
      </section>
    );
  }
}

class GetStartedButton extends React.Component {
  scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  render() {
    if (isMobile()) {
      return (
        <Button
          variant="r10_orange text-white"
          className="mb-3 col-12 fw-bold d-flex align-items-center justify-content-center"
          style={{ height: "7vh" }}
          onClick={this.scrollToBottom}
        >
          Get Started
        </Button>
      );
    } else {
      return (
        <Button
          variant="r10_orange text-white"
          className="mb-3 col-4 fw-bold d-flex align-items-center justify-content-center"
          style={{ height: "7vh" }}
          onClick={this.scrollToBottom}
        >
          Get Started
        </Button>
      );
    }
  }
}

export default AboutUsProductDescriptionForCreators;