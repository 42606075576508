import axios from "axios";
import { randomStringGenerator, getFbSingleDoc, patchData } from "./Functions";
import { auth as fbAuth } from "./Firebase";
import { signInWithCustomToken } from "firebase/auth";

export async function AuthenticateWithTwitch(
  setPending,
  setStatus,
  setError,
  signInSuccess
) {
  const codeUri =
    "https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=fuqljcxts5pg57o20ditj9bqe0g1bc&redirect_uri=https://www.rated10.app/twitch-login&scope=user:read:email&force_verify=true";
  try {
    // get authorization code token
    const authWindow = window.open(
      codeUri,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,width=500,height=500"
    );

    const { code, incomingState } = await getCode(authWindow);
    if (!code) {
      // error handling
      setPending(false);
      throw { name: URIError, message: "CSRF attack" };
    }
    // console.log("Token granted");

    function setAsyncData() {}
    function setAsyncPending() {}
    function setAsyncError(error) {
      // console.log(error);
    }
    function setAsyncStatus() {}

    let accessCodes = await getFbSingleDoc(
      `TwitchLogin`,
      "accessCodes",
      setAsyncData,
      setAsyncPending,
      setAsyncError
    );

    let codes = accessCodes.codes;
    codes.push(code);

    await patchData(
      `TwitchLogin`,
      "accessCodes",
      { codes: codes },
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );

    // listen this account and wait for userIdDict
    let userIdDict;
    let turnTimeOut = 0;
    while (turnTimeOut < 20) {
      let newAccessCodes = await getFbSingleDoc(
        `TwitchLogin`,
        "accessCodes",
        setAsyncData,
        setAsyncPending,
        setAsyncError
      );
      if (
        newAccessCodes &&
        newAccessCodes.userIdDict &&
        newAccessCodes.userIdDict[code]
      ) {
        userIdDict = newAccessCodes.userIdDict[code];
        break;
      }
      setTimeout(() => {
        turnTimeOut++;
      }, 500);
    }
    if (turnTimeOut === 20) {
      // error handling - server service not worked
      setPending(false);
      return;
    }

    // signInWithCustomToken(token);
    signInWithCustomToken(fbAuth, userIdDict.customLoginToken).then(
      (userCredential) => {
        // // Signed in
        // const user = userCredential.user;
        // // ...
        if (
          userCredential &&
          userCredential.additionalUserInfo &&
          userCredential.additionalUserInfo.isNewUser
        ) {
          userCredential.additionalUserInfo.isNewUser = false;
        }
        authWindow.close();
        signInSuccess(userCredential);
      }
    );
    authWindow.close();
  } catch (error) {
    setPending(false);
    setStatus(error.message);
    setError(error);
    // console.log(error);
  }
}

const getCode = (authWindow) => {
  return new Promise((resolve, reject) => {
    let url;

    var interval = setInterval(async () => {
      try {
        url = authWindow && authWindow.location && authWindow.location.hash;
      } catch (error) {}
      if (url) {
        // const parsedCode = parse(url.substring(1));
        // const code = parsedCode.code;
        const code = parseUrl(url.substring(1), "access_token");
        const incomingState = parseUrl(url.substring(1), "state");
        // authWindow.close();
        resolve({ code, incomingState });
      }
      if (authWindow.closed) {
        reject(new Error("Not logged in"));
        clearInterval(interval);
        // throw new Error("Not logged in");
      }
    }, 500);
  });
};

function parseUrl(url, prop) {
  let queryString = url;
  let urlParams = new URLSearchParams(queryString);
  return urlParams.get(prop);
}
