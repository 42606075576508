import React from 'react';

const ReturnPolicy = () => {
    return (
        <main className="flex min-h-screen flex-col justify-center p-24 space-y-10 lg:w-full">
            <div className="flex flex-col justify-center items-start text-left lg:max-w-5xl space-y-10 mx-auto">
                <div className="font-futura-bold text-center text-4xl" style={{ marginBottom: '20px', fontSize: '2.50rem' }}>
                Return Policy
                </div>

                <div className="font-futura-medium" style={{ fontSize: '1.25rem' }}>
                At Rated10, we are committed to providing the highest quality products and services to our customers. Due to the nature of our products and services, we do not offer returns or refunds for any purchases made through our platform.
                <br /><br />
                This policy includes, but is not limited to:
                <br /><br />
                Software and application downloads
                <br /><br />
                Subscriptions and services
                <br /><br />
                Online courses and training programs
                <br /><br />
                All sales are final and non-refundable. We strongly advise our customers to carefully review the description, requirements, and demonstration materials provided before making a purchase to ensure the product or service meets their needs.
                <br /><br />
                In the event of technical issues, our support team will work diligently to resolve any problems. If we are unable to resolve the issue, we may offer an alternative solution or credit to be used towards a future purchase.
                <br /><br />
                By making a purchase through our platform, you acknowledge and agree to this no return, no refund policy.
                <br /><br />
                Rated10 reserves the right to modify or update this policy at any time without prior notice.
                </div>
            </div>
        </main>
    );
  };
  
export default ReturnPolicy;


