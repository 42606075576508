import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormText, Row, Stack } from "react-bootstrap";
import { useGetDoc } from "../utilities/CustomHooks";
import {
  createDoc,
  delDoc,
  getFbDocs,
  loading,
  patchData,
  statusAlert,
} from "../utilities/Functions";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  BuildConfig,
  JobCompletionState,
  MAXIMUM_RESPONSE_TIME,
  MIN_DAY_FOR_ALERT,
  SubmissionAlert,
  SubmissionMark,
  InquiryTexts,
} from "../utilities/Constants";
import { fbTimestamp } from "../utilities/Firebase";
import ReportJob from "./ReportJob";
import PaymentCheckout from "./PaymentCheckout";
import Summary from "./Summary";
import "./ComponentStyles.css";

const OneQuestionDetail = ({
  userId,
  creatorId,
  service,
  convoId,
  loggedBy,
  jobCallBack,
}) => {
  var user = JSON.parse(sessionStorage.getItem("token"));
  var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [summaryExtentShow, setSummaryExtentShow] = useState(true);
  const [summaryShow, setSummaryShow] = useState(false);

  const [reportShow, setReportShow] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const { data: inquiryUser } = useGetDoc(
    "User",
    userId,
    [userId, updatePending],
    setPending,
    setError
  );

  const { data: inquiryCreator } = useGetDoc(
    "Creator",
    creatorId,
    [creatorId, updatePending],
    setPending,
    setError
  );

  const [uiInputs, setUiInputs] = useState({
    question: "",
    answer: "",
  });

  const [paymentConvo, setPaymentConvo] = useState();
  const [paymentIntent, setPaymentIntent] = useState();

  const { data: convInfo, setData: setConvInfo } = useGetDoc(
    "CompletedOneQuestionJob",
    convoId,
    [convoId, updatePending],
    setPending,
    setError
  );

  const navigate = useNavigate();

  // user or creator
  const currentUser = loggedBy === "user" ? userId : creatorId;

  useEffect(() => {
    if (convInfo) {
      setUiInputs({
        ...uiInputs,
        question: convInfo.question,
        answer: convInfo.answer ? convInfo.answer : "",
      });
    }
  }, [convInfo, convoId]);

  useEffect(() => {
    setPending(updatePending);
  }, [updatePending]);

  // for testing purpose
  useEffect(() => {
    if (status) {
      // show success and leave
      if (statusVariant === "success") {
        setTimeout(() => {
          if (jobCallBack !== undefined) {
            jobCallBack();
          } else {
            navigate(-1);
          }
        }, 3000);
      } else {
        // error message
        setTimeout(() => {
          setStatus(null);
        }, 5000);
      }
    }
  }, [status]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function newConvoObject() {
    var newConvo;
    if (!convInfo) {
      // new question
      newConvo = {
        id: `job_${uuidv4()}`,
        creatorId: inquiryCreator.id,
        creatorName: inquiryCreator.name,
        userId: user.uid,
        userName: inquiryUser.name,
        category: service.typeOfService,
        completionState: JobCompletionState.ONGOING,
        submissionDate: fbTimestamp.fromDate(new Date()),
        lastUpdateDate: fbTimestamp.fromDate(new Date()),
        lastUpdateId: currentUser,
        completionDeadline: service.completionDeadline,
        amount: service.price,
        amountInTokens: service.tokenPrice,
        environment: BuildConfig.BUILD_TYPE,
        markByCreator: SubmissionMark.CREATOR_WILL_SUBMIT_RESPONSE,
        markByEndUser: SubmissionMark.USER_IS_WAITING_RESPONSE,
        showAlertToCreator: SubmissionAlert.NO_ALERT,
        showAlertToEndUser: SubmissionAlert.NO_ALERT,
        maximumDaysPartiesCanBeIdle: MAXIMUM_RESPONSE_TIME,
        minimumDaysRequiredToShowIdleAlert: MIN_DAY_FOR_ALERT,
        platform: "web",
        receivedThumbsUp: false,
        question: uiInputs.question,
        answer: null,
      };
      setPaymentConvo({ ...newConvo });
    } else {
      // question updated
      newConvo = {
        answer: uiInputs.answer,
        completionState: JobCompletionState.COMPLETED,
        // submissionDate: fbTimestamp.fromDate(new Date()),
        lastUpdateDate: fbTimestamp.fromDate(new Date()),
        lastUpdateId: currentUser,
        markByCreator: SubmissionMark.COMPLETED,
        markByEndUser: SubmissionMark.COMPLETED,
      };
    }
    return newConvo;
  }

  function reportCallBack(isReported) {
    setReportShow(false);
  }

  async function paymentCallBack(isPaymentCompleted) {
    setPaymentShow(false);

    //  delete created record
    if (!isPaymentCompleted && paymentConvo) {
      setPending(true);
      if (paymentIntent) {
        await delDoc(
          `CompletedOneQuestionJob/${paymentConvo.id}/PaymentIntent`,
          paymentIntent.fbDocId,
          setPending,
          setStatus,
          setError
        );
      }
      await delDoc(
        "CompletedOneQuestionJob",
        paymentConvo.id,
        setPending,
        setStatus,
        setError
      );
      setPending(false);
    }
  }

  function summaryCallBack(updated) {
    setSummaryShow(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    // !!! odeme
    var newConvo = newConvoObject();

    setPending(true);
    paymentAndDatabaseChange(
      convInfo,
      newConvo,
      inquiryUser,
      inquiryCreator,
      setPending,
      setUpdatePending,
      setStatus,
      setStatusVariant,
      setPaymentShow,
      setPaymentIntent
    );
  }

  function handleReset(event) {
    event.preventDefault();
    if (jobCallBack !== undefined) {
      jobCallBack();
    } else {
      navigate(-1);
    }
  }

  function handleInputChange(event) {
    setUiInputs({ ...uiInputs, [event.target.id]: event.target.value });
  }

  function formAnswer() {
    if (!convInfo) {
      // without convInfo, this is irrelevant
      return;
    }
    if (loggedBy === "user") {
      return (
        <Form.Control
          id="answer"
          className="mb-3"
          as="textarea"
          defaultValue={uiInputs.answer}
          rows={5}
          readOnly
          placeholder={
            InquiryTexts.r10_one_question_submission_answer_hint_user
          }
          // onChange={handleInputChange}
          resize="none"
        />
      );
    } else if (loggedBy === "creator") {
      return (
        <Form.Control
          id="answer"
          className="mb-3"
          as="textarea"
          defaultValue={uiInputs.answer}
          rows={5}
          required
          readOnly={
            convInfo &&
            ((convInfo &&
              convInfo.completionState !== JobCompletionState.ONGOING) ||
            convInfo.answer
              ? true
              : false)
          }
          placeholder={InquiryTexts.r10_one_question_submission_answer_hint}
          onChange={handleInputChange}
          resize="none"
        />
      );
    }
  }

  function formButtons(section) {
    switch (section) {
      case "submit-cancel":
        if (loggedBy === "user" && !convInfo) {
          return (
            <>
              {/* submit */}
              <Button
                className="mt-auto mx-auto mb-3 col-4"
                variant="r10_orange text-white"
                type="submit"
              >
                Submit
              </Button>

              {/* cancel button */}
              {!convInfo && (
                <Button
                  className="mx-auto mb-3 col-4"
                  variant="light text-r10_orange"
                  type="reset"
                >
                  Cancel
                </Button>
              )}
            </>
          );
        }
        // // creator submit answer from upright corner button
        // else if (
        //   loggedBy === "creator" &&
        //   convInfo &&
        //   convInfo.completionState === JobCompletionState.ONGOING &&
        //   !convInfo.answer
        // ) {
        //   return (
        //     <>
        //       {/* submit - waiting - completed button */}
        //       <Button
        //         className="mt-auto mx-auto mb-3 col-4"
        //         variant="r10_orange text-white"
        //         type="submit"
        //       >
        //         Submit
        //       </Button>

        //       {/* cancel button */}
        //       <Button
        //         className="mx-auto mb-3 col-4"
        //         variant="light text-r10_orange"
        //         type="reset"
        //       >
        //         Cancel
        //       </Button>
        //     </>
        //   );
        // }
        break;
      case "report-complete":
        if (loggedBy === "user") {
          return (
            <>
              <Col
                xs={10}
                sm={8}
                md={6}
                lg={4}
                className="my-2 pe-3 d-flex justify-content-end"
              >
                <Button
                  variant="r10_orange text-white"
                  className="rounded-pill me-2 custom-minw-fit inquiry-r-c-button"
                  type="button"
                  onClick={() => setReportShow(true)}
                  disabled={convInfo.reportByEndUser}
                >
                  {convInfo.reportByEndUser ? "Reported" : "Report Inquiry"}
                </Button>
                {/* </Col> */}
                {/* <Col xs={2} className="my-2 me-3 pe-0 d-flex justify-content-end"> */}
                <Button
                  variant="secondary"
                  className="rounded-pill custom-minw-fit inquiry-r-c-button"
                  type="submit"
                  disabled
                >
                  {convInfo.answer
                    ? InquiryTexts.r10_complete
                    : InquiryTexts.r10_submission_waiting_response}
                </Button>
              </Col>
            </>
          );
        } else if (loggedBy === "creator") {
          return (
            <>
              <Col
                xs={10}
                sm={8}
                md={6}
                lg={4}
                className="my-2 pe-3 d-flex justify-content-end"
              >
                <Button
                  variant="r10_orange text-white"
                  className="rounded-pill me-2 custom-minw-fit inquiry-r-c-button"
                  type="button"
                  onClick={() => setReportShow(true)}
                  disabled={convInfo.reportByCreator}
                >
                  {convInfo.reportByCreator ? "Reported" : "Report Inquiry"}
                </Button>
                {/* </Col>
              <Col xs={2} className="my-2"> */}
                <Button
                  variant="secondary"
                  className="rounded-pill custom-minw-fit inquiry-r-c-button"
                  type="submit"
                  disabled={
                    convInfo.completionState === JobCompletionState.ONGOING &&
                    uiInputs.answer
                      ? false
                      : true
                  }
                >
                  {convInfo.completionState === JobCompletionState.ONGOING
                    ? InquiryTexts.r10_respond
                    : convInfo.completionState === JobCompletionState.COMPLETED
                    ? InquiryTexts.r10_complete
                    : InquiryTexts.r10_expired}
                </Button>
              </Col>
            </>
          );
        }
        break;
      case "summary":
        {
          // if (loggedBy === "user") {
          if (convInfo.answer && summaryExtentShow) {
            return (
              <Row className="d-flex flex-column mb-3 mx-1 bg-r10_orange rounded-3">
                <p className="text-center my-3">
                  {InquiryTexts.r10_summary_desc}
                </p>
                <Button
                  variant="light"
                  className="col-6 mx-auto mb-2 text-r10_text_disabled_dark"
                  type="button"
                  onClick={() => setSummaryShow(true)}
                >
                  View Summary
                </Button>
                <Button
                  variant="light"
                  className="col-6 mx-auto mb-2 text-r10_text_disabled_dark"
                  type="button"
                  onClick={() => setSummaryExtentShow(false)}
                  disabled={convInfo.reportByCreator}
                >
                  Later
                </Button>
              </Row>
            );
          }
          // } else {
          //   //  creator
          // }
        }
        break;
      default:
        break;
    }
  }

  function setPageLayout() {
    if (pending || (convoId && !convInfo)) {
      return loading();
    } else if (status) {
      return statusAlert(status, statusVariant);
      // } else if (reportShow) {
      //   return reportJob(reportShow, setReportShow, setUpdatePending);
    } else {
      return (
        <Form
          className="d-flex flex-column h-100 mx-3 my-3"
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <Row className="align-items-end justify-content-end">
            {/* <Col>
              <h1 className="mb-3 mx-auto text-r10_orange">
                {conversationTopics(service.typeOfService)}
              </h1>
            </Col> */}
            {convInfo && formButtons("report-complete")}
          </Row>
          {convInfo && formButtons("summary")}
          <Form.Control
            id="question"
            className="mb-3"
            as="textarea"
            defaultValue={uiInputs.question}
            rows={5}
            required
            readOnly={convInfo && convInfo.question ? true : false}
            onChange={handleInputChange}
            resize="none"
          />
          {formAnswer()}
          {formButtons("submit-cancel")}
          <ReportJob
            job={convInfo}
            loggedBy={loggedBy}
            reportShow={reportShow}
            reportCallBack={reportCallBack}
          />
          {paymentShow && (
            <PaymentCheckout
              paymentShow={paymentShow}
              paymentIntent={paymentIntent}
              paymentCallBack={paymentCallBack}
              paymentConvo={paymentConvo}
              service="Question"
            />
          )}
          {summaryShow && (
            <Summary
              job={convInfo}
              loggedBy={loggedBy}
              summaryShow={summaryShow}
              summaryCallBack={summaryCallBack}
              setUpdatePending={setUpdatePending}
            />
          )}
        </Form>
      );
    }
  }

  return setPageLayout();
};

export default OneQuestionDetail;

async function paymentAndDatabaseChange(
  convInfo,
  newConvo,
  inquiryUser,
  inquiryCreator,
  setPending,
  setUpdatePending,
  setStatus,
  setStatusVariant,
  setPaymentShow,
  setPaymentIntent
) {
  var asyncPending;
  var asyncStatus;
  var asyncError;

  function setAsyncData(data) {}
  function setAsyncPending(bool) {
    asyncPending = bool;
  }
  function setAsyncStatus(status) {
    asyncStatus = status;
  }
  function setAsyncError(error) {
    asyncError = error;
  }

  if (!convInfo) {
    // new one question created

    // new data in CompletedOneQuestionJob with UUID and  get payment results
    // ******************************
    // create data
    await createDoc(
      "CompletedOneQuestionJob",
      newConvo.id,
      newConvo,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!! - payment rollback
      setStatusVariant("danger");
      setStatus("Error : " + asyncError.message);
      setPending(false);
      return;
    }

    // + get new payment intent data created with the record
    var intents = [],
      paymentIntent,
      turnTimeOut = 0;

    while (paymentIntent === undefined && turnTimeOut < 40) {
      intents = await getFbDocs(
        `CompletedOneQuestionJob/${newConvo.id}/PaymentIntent`,
        setAsyncData,
        setAsyncPending,
        setAsyncError
      );
      if (intents && intents.length > 0) {
        paymentIntent = intents[intents.length - 1];
        break;
      }
      setTimeout(() => {
        turnTimeOut++;
      }, 500);
    }

    // + show payment intent with stripe UI
    if (!paymentIntent) {
      delDoc(
        "CompletedOneQuestionJob",
        newConvo.id,
        setAsyncPending,
        setAsyncStatus,
        setAsyncError
      );
    } else {
      setPaymentShow(true);
      setPaymentIntent(paymentIntent);
    }
    // after payment confirmed, add question id to creator and user

    // setPending(false);
    // return;
  } else {
    // one question updated
    await patchData(
      "CompletedOneQuestionJob",
      convInfo.id,
      newConvo,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );
    if (asyncError) {
      // error handling !!! - question could not updated
      setStatusVariant("danger");
      setStatus("Error : " + asyncError.message);
      setPending(false);
      return;
    }

    setStatusVariant("success");
    setStatus("Success");
  }

  // after everything is done, setPending(false)
  setPending(false);
}
