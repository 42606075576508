import { useEffect, useState } from "react";
import "./CreatorCard.css";
import Card from "react-bootstrap/Card";

import { getIcon, loading } from "../utilities/Functions";
import { useGetDoc, useGetFile } from "../utilities/CustomHooks";
import { useNavigate } from "react-router-dom";

import Avatar from "../assets/Avatar.png";
import Icon_show_all from "../assets/Icon_show_all.svg";
// const Icon_show_all = Avatar;

const CreatorCard = ({ creatorInfo }) => {
  const [pending, setPending] = useState();
  const [error, setError] = useState();
  const [fileName, setFileName] = useState();
  // const { data: creatorInfo } = useGetDoc(
  //   "Creator",
  //   id,
  //   [id],
  //   setPending,
  //   setError
  // );

  const [dependant, setDependant] = useState([null]);
  const { file: photo, setFile: setPhoto } = useGetFile(
    fileName,
    dependant,
    setPending,
    setError
  );

  const navigate = useNavigate();

  //  get photo with creatorInfo.userId and set photo dependencies
  useEffect(() => {
    if (creatorInfo) {
      if (creatorInfo.userId) {
        // creators
        setFileName(
          "user" +
            creatorInfo.userId +
            "/" +
            creatorInfo.userId +
            "ProfilePicture"
        );
        setDependant([creatorInfo.userId]);
      } else {
        // show all
        setPhoto(Icon_show_all);
      }
    }
  }, [creatorInfo]);

  // set dummy photo if photo is null
  useEffect(() => {
    if (!photo) {
      if (creatorInfo && creatorInfo.userId) {
        setPhoto(Avatar);
      } else {
        setPhoto(Icon_show_all);
      }
    }
  }, [photo]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function handleCardClicked(event) {
    if (event.target.className === "card-img custom-links") {
      return;
    }

    if (creatorInfo.id === "creatorsTab") {
      navigate("/creators");
    } else if (creatorInfo.id !== "-") {
      // to creator page
      navigate("/creator", { state: { id: creatorInfo.id } });
    } else {
      // show all
      navigate("/all-creators");
    }
  }

  let dragTarget;
  function handleMouseDown(event) {
    dragTarget = false;
  }

  function handleMouseMove(event) {
    dragTarget = true;
  }

  function handleMouseUp(event) {
    if (dragTarget) {
      return;
    }

    if (event.target.className === "card-img custom-links") {
      return;
    }
    if (creatorInfo.id === "creatorsTab") {
      navigate("/creators");
    } else if (creatorInfo.id !== "-") {
      // to creator page
      navigate("/creator", { state: { id: creatorInfo.id } });
    } else {
      // show all
      navigate("/all-creators");
    }
  }

  function setPageLayout() {
    if (pending) {
      return loading();
    } else if (creatorInfo === null || creatorInfo === undefined) {
      // error handling - creator not found
    } else {
      return (
        // <div className="card-div" onClick={handleCardClicked}>
        // {creatorInfo && (
        <Card
          className="custom-card card-div"
          bg="light"
          // onClick={handleCardClicked}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <Card.Img
            className="custom-card-img"
            variant="top"
            src={photo}
            // onClick={handleCardClicked}
            draggable={false}
          />
          <Card.Body>
            <Card.Text
              className={
                creatorInfo.id !== "-"
                  ? "fw-bold custom-card-text text-break"
                  : "fw-bold custom-card-text text-center"
              }
              // onClick={handleCardClicked}
            >
              {creatorInfo.creatorName}
              {/* </div> */}
            </Card.Text>
            {creatorInfo.links &&
              creatorInfo.links.map((link) => (
                <Card.Link href={link.url} target="_blank" key={link.brand}>
                  <Card.Img
                    className="custom-links"
                    src={getIcon(link)}
                    alt={link.brand}
                  />
                </Card.Link>
              ))}
          </Card.Body>
        </Card>
        //   )}
        // </div>
      );
    }
  }

  return setPageLayout();
};

export default CreatorCard;
