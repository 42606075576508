import { useEffect, useState } from "react";
import { getFbDocs, getFbFile, getFbSingleDoc } from "./Functions";

export function useGetDoc(
  collectionName,
  collectionId,
  dependant,
  setPending,
  setError
) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (collectionId) {
      setPending(true);
      getFbSingleDoc(
        collectionName,
        collectionId,
        setData,
        setPending,
        setError
      );
    }
  }, dependant);

  return { data, setData };
}

export function useGetDocs(collectionName, dependant, setPending, setError) {
  const [fbDocs, setfbDocs] = useState();

  useEffect(() => {
    setPending(true);
    getFbDocs(collectionName, setfbDocs, setPending, setError);
  }, dependant);

  return { fbDocs, setfbDocs };
}

export function useGetFile(fileName, dependant, setPending, setError) {
  const [file, setFile] = useState();

  useEffect(() => {
    if (fileName) {
      setPending(true);
      getFbFile(fileName, setFile, setPending, setError);
    }
  }, dependant);

  return { file, setFile };
}
