import { Button, Form, Image, Modal } from "react-bootstrap";
import { patchData } from "../utilities/Functions";
import Report_exclamation from "../assets/Report_exclamation.png";
import { InquiryTexts } from "../utilities/Constants";
import { useState } from "react";

const ReportJob = ({ job, loggedBy, reportShow, reportCallBack }) => {
  const [pending, setPending] = useState();

  const categoryArray = [
    {
      value: "answerIsIrrelevant",
      text: InquiryTexts.rc_answer_is_irrelevant,
    },
    {
      value: "questionIsIrrelevant",
      text: InquiryTexts.rc_question_is_irrelevant,
    },
    { value: "noAnswer", text: InquiryTexts.rc_no_answer },
    {
      value: "includesAdultContent",
      text: InquiryTexts.rc_includes_adult_content,
    },
    { value: "other", text: InquiryTexts.rc_other },
  ];

  var report = { category: "", comment: "" };

  function handleInputChanges(event) {
    report[event.target.id] = event.target.value;
  }

  async function handleReport(event) {
    event.preventDefault();

    let data = {},
      stts,
      err;

    if (loggedBy === "user") {
      data.reportByEndUser = report;
    } else {
      data.reportByCreator = report;
    }
    // reportCallBack(true);
    // return;
    setPending(true);

    function setStatus(status) {
      stts = status;
    }
    function setError(error) {
      err = error;
    }

    let error = await patchData(
      job.category.search("Question") > 0
        ? "CompletedOneQuestionJob"
        : "CompletedJob",
      job.id,
      data,
      setPending,
      setStatus,
      setError
    );
    reportCallBack(true);
  }

  return (
    <Modal
      size="md"
      show={reportShow}
      centered
      animation="fade"
      onHide={() => reportCallBack()}
    >
      <Modal.Header
        closeButton
        className="d-flex flex-column fw-bold bg-r10_bg_default"
      >
        <Image
          src={Report_exclamation}
          className="mb-3"
          style={{ width: "3vw" }}
        />
        {InquiryTexts.r10_report_title}
      </Modal.Header>
      <Modal.Body className="bg-r10_bg_default rounded-3">
        <Form className="d-flex flex-column">
          <Form.Select
            className="bg-r10_bg_default text-r10_orange border border-r10_orange"
            onChange={handleInputChanges}
            id="category"
            defaultValue=""
            required
          >
            <option value="" disabled>
              {InquiryTexts.r10_report_selector_default}
            </option>
            {categoryArray.map((array) => (
              <option value={array.value} key={array.value}>
                {array.text}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            required
            as="textarea"
            id="comment"
            className="my-3"
            rows={3}
            onChange={handleInputChanges}
          />

          <Button
            className="col-4 mx-auto"
            variant="r10_orange text-white"
            type="button"
            onClick={handleReport}
            disabled={pending}
          >
            {pending ? "Reporting" : "Submit"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ReportJob;
