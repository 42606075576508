import { db } from "../utilities/Firebase";
import { doc, getDoc } from 'firebase/firestore';

/**
 * Helper function to get the API key from Firestore
 * @returns {Promise<string>} The API key
 */
const getMailgunApiKey = async () => {
  try {
    // Reference the document where the API key is stored
    const docRef = doc(db, "AppSettings", "keys");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Return the API key from the document
      return docSnap.data().mailgun;
    } else {
      console.error("No API key found in Firestore!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching API key from Firestore:", error);
    return null;
  }
};

export default getMailgunApiKey;