import React from "react";
import {isMobile} from "../utilities/Functions";

class WhyRated10Creators extends React.Component {
    render() {
        const isMobileDevice = isMobile();

        const darkBlueColor = '#3B3F4E'
        const lightBlueColor = '#5D677F'
        const orangeColor = '#E35335'
          
        const containerStyle = {
            paddingBottom: isMobileDevice ? '60px' : '100px',
            paddingTop: '40px',
            paddingLeft: isMobileDevice ? '0px' : '0px',
            paddingRight: isMobileDevice ? '10px' : '40px',
            boxSizing: 'border-box',
            overflowX: 'scroll',
        };

        const headerStyle = {
            fontSize: isMobileDevice ? '12px' : '15px',
            fontWeight: 'bold',
            marginBottom: '0px',
            textAlign: 'left',
            color: orangeColor,
        }
    
        const titleStyle = {
            fontSize: isMobileDevice ? '18px' : '25px',
            fontWeight: 'bold',
            marginBottom: '0px',
            textAlign: 'left',
            color: darkBlueColor,
        };

        const textStyle = {
            marginTop: '5px',
            textAlign: 'left',
            fontSize: isMobileDevice ? '9px' : '12px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word', // Ensure long words break and wrap
            color: lightBlueColor,
        };
    
        const boxContainerStyle = {
            display: 'flex',
            paddingLeft: isMobileDevice ? '10px' : '40px',
            flexDirection: isMobileDevice ? 'column' : 'column',
            flexWrap: 'wrap',
            gap: isMobileDevice ? '10px' : '20px',
            justifyContent: isMobileDevice ? 'center' : 'space-between',
            alignItems: isMobileDevice ? 'center' : 'flex-start',
          };

        // const mobileRowStyle = {
        //     display: 'flex',
        //     gap: '10px',
        //     flexDirection: 'row',
        //     justifyContent: 'left',
        //     width: isMobileDevice ? '100%' : 'auto',
        // };

        // const rowStyle = {
        //     display: 'flex',
        //     gap: '30px',
        //     flexDirection: 'row',
        //     justifyContent: 'left',
        //     width: isMobileDevice ? '100%' : '80%',
        // };
    
        const mobileBoxStyle = {
            flex: '1 1 45%',
            padding: '10px',
            // border: '1px solid #ddd',
            // borderRadius: '10px',
            textAlign: 'center',
            // backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            height: '180px',
            // boxSizing: 'border-box',
            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        };
    
        const boxStyle = {
            flex: 1,
            padding: '20px',
            // border: '1px',
            // borderRadius: '10px',
            textAlign: 'center',
            // backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            height: '180px',
            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        };

        const separatorStyle = {
            width: '100%',
            height: '1px',
            backgroundColor: '#ddd',
            marginBottom: '40px',
        };

        return (
            <section id="why-rated10-creators" style={containerStyle}>
                <h2 style={{ fontSize: '2em', marginBottom: '40px', fontStyle: 'italic', color: lightBlueColor }}>Benefits for Creators</h2>
                {isMobileDevice ? (
                <div style={boxContainerStyle}>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>① Earn</span>
                    <span style={titleStyle}>Get paid by simply answering your followers' questions! 💰</span>
                    <span style={textStyle}>Creators earn money by charging for each answer. Every question they answer has a price tag, and they get the money when they answer.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>② No extra content</span>
                    <span style={titleStyle}>Answers are the content 📝</span>
                    <span style={textStyle}>Creators do not need to spend time to create exclusive content like in fan platforms. The answer and advice they give IS the content.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>③ Time management</span>
                    <span style={titleStyle}>Time management 🕰️</span>
                    <span style={textStyle}> Optionally, creators can limit the number of unanswered questions they can have. So when they hit a limit, they will not receive new questions, enabling them to manage their time better.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>④ Community building</span>
                    <span style={titleStyle}>Genuine interaction 🤝</span>
                    <span style={textStyle}>The advice and opinions the content creators provide on Rated10 are genuine. There is no exclusive content, just genuine opinion and advice sharing, which results in less effort compared to other platforms.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>⑤ Clean inbox</span>
                    <span style={titleStyle}>Clean inbox 📬</span>
                    <span style={textStyle}>Messaging requires payment information submission, no spam or bot messages.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>⑥ Reporting</span>
                    <span style={titleStyle}>Intelligent reporting 🚨</span>
                    <span style={textStyle}>The questions the creator receives must comply with the content topic and cannot have offensive or inappropriate content. Otherwise, the creator gets the money without answering the message.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>⑦ Easy to use</span>
                    <span style={titleStyle}>Hustle-free, almost instant sign-up ✅</span>
                    <span style={textStyle}>Hustle-free, almost instant sign-up with template account generation for creators. Creators are expected to edit the account and add more topics of conversation and prices.</span>
                </div>
                </div>
                ) : (
                <div style={boxContainerStyle}>
                <div style={boxStyle}>
                    <span style={headerStyle}>① Earn</span>
                    <span style={titleStyle}>Get paid for answering! 💰</span>
                    <span style={textStyle}>Creators earn money by charging for each answer. Every question they answer has a price tag, and they get the money when they answer.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>② No extra content</span>
                    <span style={titleStyle}>Answers are the content 📝</span>
                    <span style={textStyle}>Creators do not need to spend time to create exclusive content like in fan platforms. The answer and advice they give IS the content.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>③ Time management</span>
                    <span style={titleStyle}>Easily manage your time on the platform 🕰️</span>
                    <span style={textStyle}> Optionally, creators can limit the number of unanswered questions they can have. So when they hit a limit, they will not receive new questions, enabling them to manage their time better.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>④ Community building</span>
                    <span style={titleStyle}>Genuine interaction with users 🤝</span>
                    <span style={textStyle}>The advice and opinions the content creators provide on Rated10 are genuine. There is no exclusive content, just genuine opinion and advice sharing, which results in less effort compared to other platforms.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>⑤ Clean inbox</span>
                    <span style={titleStyle}>Clean inbox with no spam or bot messages 📬</span>
                    <span style={textStyle}>Messaging requires payment information submission, no spam or bot messages.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>⑥ Reporting</span>
                    <span style={titleStyle}>Feel safe with intelligent reporting 🚨</span>
                    <span style={textStyle}>The questions the creator receives must comply with the content topic and cannot have offensive or inappropriate content. Otherwise, the creator gets the money without answering the message.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>⑦ Easy to use</span>
                    <span style={titleStyle}>Hustle-free, almost instant sign-up ✅</span>
                    <span style={textStyle}>Hustle-free, almost instant sign-up with template account generation for creators. Creators are expected to edit the account and add more topics of conversation and prices.</span>
                </div>
            </div>
            )}
            </section>
         ); 
        }
    }

    export default WhyRated10Creators;