import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CreatorCard from "./CreatorCard";
import { useEffect, useState } from "react";
import { getFbDocsWithQuery, loading } from "../utilities/Functions";
import { Button, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const CreatorTabList = ({ tab, searchParam }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 1024 },
      items: 5,
      slidesToSlide: 3, // optional, default to 1.
      partialVisibilityGutter: 25, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 25, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 15, // this is needed to tell the amount of px that should be visible.
    },
  };

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [creators, setCreators] = useState([]);
  const [uiList, setUiList] = useState([]);

  // get creators' detail
  useEffect(() => {
    getCreators();
  }, [tab]);

  // set uilist with search
  useEffect(() => {
    getUiList();
  }, [creators, searchParam]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function getCreators() {
    if (tab.creatorIds.length < 1) {
      return;
    }
    // for space character in db
    let creatorIds = [];
    tab.creatorIds.forEach((id) => {
      creatorIds.push(id.replace(/ /g, ""));
    });
    // for space character in db
    let queries = [
      {
        field: "id",
        condition: "in",
        // value: tab.creatorIds,
        value: creatorIds,
      },
    ];
    setPending(true);
    getFbDocsWithQuery("Creator", queries, setCreators, setPending, setError);
  }

  function getUiList() {
    if (!creators) {
      return;
    }
    let ui = [];
    let search = searchParam.toLowerCase();

    setPending(true);
    creators.forEach((creator) => {
      if (!search || creator.creatorName.toLowerCase().search(search) >= 0) {
        ui.push(creator);
      }
    });

    // show all card
    if (ui && ui.length > 0) {
      ui.push({ id: "-", creatorName: "Show All" });
    }
    // show all card

    setUiList([...ui]);
    setPending(false);
  }

  function setPageLayout() {
    // if (pending) {
    //   return loading();
    // } else if (status) {
    //   return statusAlert(status, statusVariant);
    // } else if (creators) {
    if (!uiList || uiList.length < 1) {
      return;
    } else {
      return (
        <div className="creator-list">
          <h1 className="mb-4 fs-2">
            <strong>{tab.name}</strong>
          </h1>
          {pending ? (
            loading()
          ) : (
            <Row>
              {/* <Col> */}
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                //   ssr={true} // means to render carousel on server-side.
                // infinite={true}
                //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
                // autoPlaySpeed={1000}
                // keyBoardControl={true}
                // customTransition="all .5"
                // transitionDuration={500}
                // containerClass="carousel-container"
                removeArrowOnDeviceType={["desktop"]}
                //   deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                // itemClass="carousel-item-padding-40-px"
                partialVisible={false} //  true spoils when odd # of slides
              >
                {uiList.map((creator) => (
                  <CreatorCard
                    creatorInfo={creator}
                    key={`${tab.name}_${creator.id}`}
                  />
                ))}
              </Carousel>
            </Row>
          )}
        </div>
      );
    }
  }

  return setPageLayout();
};

export default CreatorTabList;
