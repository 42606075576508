import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Compressor from "compressorjs";
import { createDoc, getBase64, uploadEncodedStringFile } from "../utilities/Functions";

const ApplyToBeCreatorSeparated = ({ callBack = () => {} }) => {
  // Now callBack is always a function, even if it isn't passed
  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  // Form input state
  const [appliantInfo, setAppliantInfo] = useState({
    email: "",
    isDocUploadSuccessful: null,
    fullName: "",
    socialMediaHandle: "",
    socialMediaType: "",
    userId: uuidv4(),  // Generating a unique ID locally
    docName: `creatorApp_${uuidv4()}`,
    file: null,
    filename: `creatorApp/${uuidv4()}CreatorDoc`,
  });

  const inputFile = useRef(null);

  // Form validation (self-validation on submit)
  useEffect(() => {
    const forms = document.querySelectorAll(".needs-validation");
    Array.prototype.slice.call(forms).forEach((form) => {
      form.addEventListener("submit", (event) => validate(event, form), false);
    });
  }, []);

  // Form validation function
  const validate = (event, form) => {
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add("was-validated");
  };

  // Handle file selection and compression
  const onFileSelected = (event) => {
    setPending(true);
    setUploadSuccess(false);

    new Compressor(event.target.files[0], {
      quality: 0.8, // Adjusted quality for compression
      success: (compressedResult) => {
        getBase64(compressedResult)
          .then((result) => {
            setAppliantInfo({
              ...appliantInfo,
              isDocUploadSuccessful: "true",
              file: result,
            });
            setPending(false);
            setUploadSuccess(true);
          })
          .catch(() => setUploadSuccess(false));
      },
    });
  };

  // Trigger file input
  const handleUploadDoc = () => {
    if (inputFile.current) inputFile.current.click();
  };

  // Handle form input changes
  const handleInputChanges = (event) => {
    setAppliantInfo({
      ...appliantInfo,
      [event.target.id]: event.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setPending(true);
    setStatus("Submitting application");

    const data = {
      email: appliantInfo.email,
      fullName: appliantInfo.fullName,
      socialMediaHandle: appliantInfo.socialMediaHandle,
      socialMediaType: appliantInfo.socialMediaType,
      userId: appliantInfo.userId,
    };

    // Upload file function
    const handleUploadSuccess = () => {
      setStatus("Document uploaded successfully");
      completeApplication(true);
    };

    const handleUploadError = () => {
      setStatus("Document upload failed, submitting application without document");
      completeApplication(false);
    };

    const completeApplication = async (isDocUploaded) => {
      try {
        await createDoc("CreatorApplication", appliantInfo.docName, data);
        setStatus(isDocUploaded ? "Application received" : "Application received without document");
      } catch (err) {
        setStatus("Application failed. Try again later.");
      } finally {
        // setPending(false);
        // callBack();
        setStatus("Application successful!");
      }
    };

    // Upload file if present
    if (appliantInfo.file) {
      uploadEncodedStringFile(appliantInfo.filename, appliantInfo.file, null, handleUploadError, handleUploadSuccess);
    } else {
      completeApplication(false);
    }
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '10vh',
    padding: '10px',
    color: '#E35335',
    textAlign: 'left',
    padding: '10px 0',
    boxSizing: 'border-box',
    borderRadius: '10px',
    // border: '1px solid #E35335', // Specify border width, style, and color
    // backgroundColor: 'white',
  };

  const stepStyle = {
    marginBottom: '30px', // Increase spacing between steps
  };

  return (
    <div style={containerStyle}>
      <Form noValidate className="d-flex flex-column needs-validation" onSubmit={handleSubmit}>
        <Form.Text className="mb-4">
          Fill out the form below and we'll get back to you within 72 hours.
        </Form.Text>
  
        <Form.Group controlId="fullName" className="col-6 mb-3" style={stepStyle}>
          <Form.Label className="fw-bold">Step 1: Verify identity</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Full name"
            onChange={handleInputChanges}
          />
          <Form.Control.Feedback type="invalid">
            Please provide your full name
          </Form.Control.Feedback>
        </Form.Group>
  
        <Form.Group controlId="document" className="col-6 mb-4" style={stepStyle}>
          <Row>
            <Col>
              <Button variant="outline-r10_orange" className="bg-white" onClick={handleUploadDoc}>
                Upload a document
              </Button>
              <input hidden type="file" accept="image/*" ref={inputFile} onChange={onFileSelected} required />
              <Form.Control.Feedback type="invalid">
                Please upload a document
              </Form.Control.Feedback>
            </Col>
            <Col className="align-self-center">
              <Form.Text muted hidden={!uploadSuccess}>
                Upload Successful
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>
  
        <Row className="col-12 mb-4">
          <Form.Label className="fw-bold">
            Step 2: Provide your most prominent social media link
          </Form.Label>
          <Col xs={6}>
            <Form.Group controlId="socialMediaType">
              <Form.Select
                className="text-r10_orange border border-r10_orange"
                onChange={handleInputChanges}
                required
              >
                <option value="" disabled>
                  Select Social Media
                </option>
                <option value="Twitter">Twitter</option>
                <option value="Instagram">Instagram</option>
                <option value="YouTube">YouTube</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select a social media type
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="socialMediaHandle">
              <Form.Control
                type="text"
                placeholder="Write handle here"
                onChange={handleInputChanges}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide your social media handle
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
  
        <Form.Group controlId="email" className="col-6 mb-4" style={stepStyle}>
          <Form.Label className="fw-bold">
            Step 3: Enter your Email address
          </Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Email address"
            onChange={handleInputChanges}
          />
          <Form.Control.Feedback type="invalid">
            Please provide your email address
          </Form.Control.Feedback>
        </Form.Group>
  
        <Button className="col-4 mx-auto mb-3" variant="r10_orange text-white" type="submit" disabled={pending}>
          {pending ? status : "Apply"}
        </Button>
  
        <Button className="col-4 mx-auto mb-3" variant="light text-r10_orange" type="reset" hidden={pending}>
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default ApplyToBeCreatorSeparated;