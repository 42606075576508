import Icon_instagram from "../assets/Icon_instagram.svg";
import Icon_link from "../assets/Icon_link.png";
import Icon_twitch from "../assets/Icon_twitch.svg";
import Icon_twitter from "../assets/Icon_twitter.svg";
import Icon_youtube from "../assets/Icon_youtube.svg";
import Icon_linkedin from "../assets/Icon_linkedin.svg";
import Icon_tiktok from "../assets/Icon_tiktok.svg";

import { auth, db, storage } from "../utilities/Firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { Alert, Image, Modal, Spinner } from "react-bootstrap";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { deleteUser, signOut } from "firebase/auth";

export function getIcon(link) {
  let icon = "Icon_link";
  switch (link.brand) {
    case "instagram":
      icon = Icon_instagram;
      break;
    case "twitch":
      icon = Icon_twitch;
      break;
    case "x":
      icon = Icon_twitter;
      break;
    case "youtube":
      icon = Icon_youtube;
      break;
    case "linkedin":
      icon = Icon_linkedin;
      break;
    case "tiktok":
      icon = Icon_tiktok;
      break;

    default:
      icon = Icon_link;
      break;
  }
  return icon;
}

export function linkAndBrand() {
  return [
    {
      brand: "instagram",
      text: "Instagram",
      url: "https://www.instagram.com/",
    },
    { brand: "twitch", text: "Twitch", url: "https://www.twitch.tv/" },
    { brand: "x", text: "X", url: "https://www.x.com/" },
    { brand: "youtube", text: "Youtube", url: "https://www.youtube.com/@" },
    {
      brand: "linkedin",
      text: "Linkedin",
      url: "https://www.linkedin.com/in/",
    },
    { brand: "tiktok", text: "Tiktok", url: "https://www.tiktok.com/@" },
  ];
}

export function loading() {
  return (
    <Spinner animation="border" role="status" className="d-flex m-auto">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export function statusAlert(message, variant, header, alertClass) {
  return (
    <Alert
      variant={variant}
      className={alertClass ? alertClass : "col-6 m-auto text-center"}
    >
      {header && <Alert.Heading>{header}</Alert.Heading>}
      <p>{message}</p>
    </Alert>
  );
}

export function photoPopUp(src, setSrc) {
  return (
    <Modal
      size="lg"
      show={src ? true : false}
      centered
      animation="fade"
      onHide={() => setSrc(null)}
    >
      <Modal.Header
        closeButton
        className="d-flex d-md-none bg-r10_bg_default"
      />
      <Modal.Body className="bg-r10_bg_default rounded-3">
        <Image src={src} className="mw-100" />
      </Modal.Body>
    </Modal>
  );
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function conversationTopics(topicKey) {
  let topic = topicKey;
  switch (topicKey) {
    case "astrologyQuestion":
      topic = "Astrology Question";
      break;
    case "bussinessQuestion":
      topic = " Question";
      break;
    case "careerQuestion":
      topic = "Career Question";
      break;
    case "cryptoQuestion":
      topic = "Crypto Question";
      break;
    case "datingQuestion":
      topic = "Dating Question";
      break;
    case "datingAppReview":
      topic = "Dating App Review";
      break;
    case "familyAndChildrenQuestion":
      topic = "Family And Children Question";
      break;
    case "fashionAndBeautyQuestion":
      topic = "Fashion And Beauty Question";
      break;
    case "fashionAndBeautyReview":
      topic = "Fashion And Beauty Review";
      break;
    case "financeQuestion":
      topic = "Finance Question";
      break;
    case "fitnessQuestion":
      topic = "Fitness Question";
      break;
    case "gamingQuestion":
      topic = "Gaming Question";
      break;
    case "gamingReview":
      topic = "Gaming Review";
      break;
    case "lifestyleQuestion":
      topic = "LifeStyle Question";
      break;
    case "lawQuestion":
      topic = "Law Question";
      break;
    case "multiplePhotos":
      topic = "Multiple Photos Review";
      break;
    case "musicQuestion":
      topic = "Music Question";
      break;
    case "personalGrowthQuestion":
      topic = "Personal Growth Question";
      break;
    case "personalQuestion":
      topic = "Personal Question About Creator";
      break;
    case "portfolioReview":
      topic = "Portfolio Review";
      break;
    case "resumeReview":
      topic = "Resume Review";
      break;
    case "sportsQuestion":
      topic = "Sports Question";
      break;
    case "socialMediaReview":
      topic = "Social Media Review";
      break;
    case "streamingQuestion":
      topic = "Streaming Question";
      break;
    case "travelQuestion":
      topic = "Travel Question";
      break;
    case "technologyQuestion":
      topic = "Technology Question";
      break;
  }
  return topic;
}

export function serviceAndText() {
  var launchSettings = JSON.parse(sessionStorage.getItem("launchSettings"));
  let serviceWithText = [];
  launchSettings.supportedJobCategories.forEach((service) => {
    serviceWithText.push({
      typeOfService: service,
      text: conversationTopics(service),
    });
  });

  return serviceWithText;
  // return [
  //   { typeOfService: "travelQuestion", text: "Travel Question" },
  //   { typeOfService: "sportsQuestion", text: "Sports Question" },
  //   { typeOfService: "lifestyleQuestion", text: "LifeStyle Question" },
  //   { typeOfService: "socialMediaReview", text: "Social Media Review" },
  //   { typeOfService: "astrologyQuestion", text: "Astrology Question" },
  //   { typeOfService: "datingQuestion", text: "Dating Question" },
  //   { typeOfService: "personalQuestion", text: "Personal Question" },
  //   { typeOfService: "gamingQuestion", text: "Gaming Question" },
  //   { typeOfService: "resumeReview", text: "Resume Review" },
  //   { typeOfService: "gamingReview", text: "Gaming Review" },
  //   { typeOfService: "technologyQuestion", text: "Technology Question" },
  // ];
}

export function uploadFile(
  fileName,
  file,
  handleUploadProgress,
  handleUploadError,
  handleUploadSuccess
) {
  if (fileName === null || file === null) {
    return;
  }

  let fileUri = encodeURI(fileName);
  // const metadata = {
  //   contentType: "image/jpeg",
  // };

  const uploadTask = uploadBytesResumable(
    ref(storage, fileUri),
    file
    // metadata
  );
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      if (handleUploadProgress !== undefined) {
        handleUploadProgress(progress);
      }
    },
    (error) => {
      if (handleUploadError !== undefined) {
        handleUploadError(error);
      }
      // return { status: error.message, error };
    },
    () => {
      if (handleUploadSuccess !== undefined) {
        handleUploadSuccess(file);
      }
      // return { status: "Success", error: null };
    }
  );
}

export function uploadEncodedStringFile(
  fileName,
  fileString,
  handleUploadProgress,
  handleUploadError,
  handleUploadSuccess
) {
  if (fileName === null || fileString === null) {
    return;
  }

  let fileUri = encodeURI(fileName);
  uploadString(ref(storage, fileUri), fileString, "data_url")
    .then((snapshot) => {
      handleUploadSuccess();
    })
    .catch((error) => {
      handleUploadError(error);
    });
}

export function deleteFile(fileName, handleError, handleSuccess) {
  if (fileName === null) {
    return;
  }

  deleteObject(ref(storage, encodeURI(fileName)))
    .then(() => {
      // File deleted successfully
      handleSuccess();
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      if (handleError !== undefined) {
        handleError(error);
      }
    });
}

export async function navbarUserCreatorCache(user) {
  var userCredentials = JSON.parse(sessionStorage.getItem("token"));
  const setData = (param) => {};
  const setPending = (param) => {};
  const setError = (param) => {};

  if (userCredentials === null) {
    sessionStorage.setItem("token", JSON.stringify(user));
  }

  var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  if (userInfo === null) {
    userInfo = await getFbSingleDoc(
      "User",
      user.uid,
      setData,
      setPending,
      setError
    );
    if (userInfo) {
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      window.dispatchEvent(new Event("userInfo"));
    }
  }

  // creator
  var creatorInfo = JSON.parse(sessionStorage.getItem("creatorInfo"));
  if (creatorInfo === null && userInfo && userInfo.asCreator) {
    creatorInfo = await getFbSingleDoc(
      "Creator",
      userInfo.asCreator,
      setData,
      setPending,
      setError
    );
    if (creatorInfo) {
      sessionStorage.setItem("creatorInfo", JSON.stringify(creatorInfo));
    }
  }

  // photo
  let fileName = "user" + user.uid + "/" + user.uid + "ProfilePicture";
  var userPhoto = JSON.parse(sessionStorage.getItem("userPhoto"));
  if (userPhoto === null) {
    userPhoto = await getFbFile(fileName, setData, setPending, setError);
    if (userPhoto) {
      sessionStorage.setItem("userPhoto", JSON.stringify(userPhoto));
    }
  }
}

export async function cacheUtility() {
  const setData = (param) => {};
  const setPending = (param) => {};
  const setError = (param) => {};

  // page launch settings
  var launchSettings = JSON.parse(sessionStorage.getItem("launchSettings"));
  if (launchSettings === null) {
    launchSettings = await getFbSingleDoc(
      "AppSettings",
      "LaunchSettings",
      setData,
      setPending,
      setError
    );
    if (launchSettings) {
      sessionStorage.setItem("launchSettings", JSON.stringify(launchSettings));
    }
  }
}

// fetch document from firebase database
export async function getFbSingleDoc(
  collectionName,
  collectionId,
  setData,
  setPending,
  setError
) {
  try {
    let docRef = doc(db, collectionName, collectionId);

    let docSnap = await getDoc(docRef);
    let data = docSnap.data();

    if (setData !== undefined) {
      setData(data);
    }
    if (setPending !== undefined) {
      setPending(false);
    }
    if (setError !== undefined) {
      // setError(null);
    }

    return data;
  } catch (error) {
    if (setPending !== undefined) {
      setPending(false);
    }
    if (setError !== undefined) {
      setError(error);
    }
  }
}

export async function getFbDocs(collectionName, setData, setPending, setError) {
  try {
    const ref = collection(db, collectionName);
    let data = await getDocs(ref);

    let filteredData = data.docs.map((doc) => ({
      fbDocId: doc.id,
      ...doc.data(),
    }));
    setData(filteredData);
    setPending(false);
    // setError(null);
    return filteredData;
  } catch (error) {
    setPending(false);
    setError(error);
  }
}

export async function getFbDocsWithQuery(
  collectionName,
  queryList,
  setData,
  setPending,
  setError,
  isAppend
) {
  try {
    if (setPending !== undefined) {
      setPending(true);
    }
    const ref = collection(db, collectionName);
    var conditions = [];
    queryList.forEach((condition) => {
      conditions.push(
        where(condition.field, condition.condition, condition.value)
      );
    });
    var dbQuery = query(ref, ...conditions);
    // var dbQuery = query(
    //   ref,
    //   where(queryList[0].field, queryList[0].condition, queryList[0].value)
    // );

    let data = await getDocs(dbQuery);

    let filteredData = data.docs.map((doc) => ({
      fbDocId: doc.id,
      ...doc.data(),
    }));
    if (filteredData.length > 0) {
      if (setData !== undefined) {
        if (isAppend) {
          setData((oldArray) =>
            oldArray ? [...oldArray, ...filteredData] : filteredData
          );
        } else {
          setData(filteredData);
        }
      }
    }
    if (setPending !== undefined) {
      setPending(false);
    }
    // setError(null);
    return filteredData;
  } catch (error) {
    if (setPending !== undefined) {
      setPending(false);
    }
    if (setError !== undefined) {
      setError(error);
    }
  }
}

// fetch file from firebase storage
export async function getFbFile(fileName, setFile, setPending, setError) {
  try {
    let fileUri = encodeURI(fileName);
    let url = await getDownloadURL(ref(storage, fileUri));

    setFile(url);
    setPending(false);
    // setError(null);
    return url;
  } catch (error) {
    setPending(false);
    setError(error);
  }
}

export async function patchData(
  collectionName,
  docId,
  data,
  setPending,
  setStatus,
  setError
) {
  let initialError;

  if (!collectionName) {
    // no collection
    initialError = new Error("Collection name missing");
    setError(initialError);
    return initialError;
  }
  if (!docId) {
    // no document id
    initialError = new Error("Document id missing");
    setError(initialError);
    return initialError;
  }
  if (!data) {
    // no data to patch
    initialError = new Error("Data missing");
    setError(initialError);
    return initialError;
  }

  try {
    // setPending(true);
    const docRef = doc(db, collectionName, docId);

    await updateDoc(docRef, data);

    setPending(false);
    setStatus("Success");
  } catch (error) {
    setPending(false);
    setStatus(error.message);
    setError(error);
    return error;
  }
}

export function userSignOut() {
  // sessionStorage.removeItem("token");
  signOut(auth)
    .then(() => {
      // session cleared
      cacheClear();
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });
}

export async function deleteUserAccount() {
  //  db'de user tablosundan user'i sil
  //  authentication'dan useri sil
  try {
    await deleteUser(auth.currentUser);
    cacheClear();
  } catch (error) {
    // An error ocurred
    // ...
    return error;
  }
}

export function cacheClear() {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("userInfo");
  sessionStorage.removeItem("userPhoto");
  sessionStorage.removeItem("creatorInfo");
  sessionStorage.removeItem("isQuestionEduSeen");
  sessionStorage.removeItem("isRevievEduSeen");
  sessionStorage.removeItem("applyToBeCreator");
}

export async function createDoc(
  collectionName,
  docId,
  data,
  setPending,
  setStatus,
  setError
) {
  try {
    const docRef = doc(db, collectionName, docId);

    await setDoc(docRef, data);

    setPending(false);
    // setStatus("Success");
  } catch (error) {
    setPending(false);
    setStatus(error.message);
    setError(error);
    return error;
  }
}

export async function delDoc(
  collectionName,
  docId,
  setPending,
  setStatus,
  setError
) {
  try {
    const docRef = doc(db, collectionName, docId);

    await deleteDoc(docRef);

    setPending(false);
    // setStatus("Success");
  } catch (error) {
    setPending(false);
    setStatus(error.message);
    setError(error);
  }
}

export function randomStringGenerator(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function getBase64(file) {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      // console.log("Called", reader);
      baseURL = reader.result;
      // console.log(baseURL);
      resolve(baseURL);
    };
    // console.log(fileInfo);
  });
}

export function changeTextWithParameters(text, params) {
  let result = text;

  for (let i = 0; i < params.length; i++) {
    result = result.replace(params[i].var, params[i].text);
  }

  return result;
}

export function fbDateConvert(date) {
  let today = date.toDate();
  let yyyy = date.toDate().getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  // if (dd < 10) dd = "0" + dd;
  // if (mm < 10) mm = "0" + mm;

  let formattedToday = dd + "/" + mm + "/" + yyyy;
  return formattedToday;
}

export function dateDifferenceinDays(date1, date2) {
  let difference = date1.getTime() - date2.getTime();
  difference = difference / (1000 * 60 * 60 * 24);
  return difference;
}

export function isMobile() {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  if (regex.test(navigator.userAgent)) {
    return true;
  } else {
    // screen width less then bootstrap md breakpoint
    // return window.screen.width < 768;
    return window.innerWidth < 768;
  }
}

export function uniqByKeepLast(data, key) {
  return [...new Map(data.map((x) => [key(x), x])).values()];
}
