import React from 'react';
import {isMobile} from "../utilities/Functions";
import {Image, Button} from "react-bootstrap";
import AppStoreButtonLogo from "../assets/Download_on_the_App_Store_Badge_blk.svg";
import GooglePlayStoreButtonLogo from "../assets/GetItOnGooglePlay_Badge_Web_color_English.svg";

class GetStarted extends React.Component {
    render() {
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: isMobile() ? '60px' : '100px',
        paddingTop: '40px',
        paddingLeft: '0px',
        paddingRight: '0px',
        alignItems: 'flex-start',
        textAlign: 'left',
      };
  
      const buttonContainerStyle = {
        display: 'flex',
        gap: '20px',
        flexDirection: isMobile() ? 'column' : 'row',
      };
  
      const buttonStyle = {
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '63px',
        // backgroundColor: '#FFFFFF',
      };
  
      const webButtonStyle = {
        ...buttonStyle,
        padding: '10px 20px',
        border: '1px solid #000',
        borderRadius: '5px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        fontWeight: 'bold',
      };
  
      const appStoreButtonStyle = {
        ...buttonStyle,
        color: '#000000',
      };
  
      const googlePlayButtonStyle = {
        ...buttonStyle,
        color: '#FFFFFF',
      };

      const imageStyle = {
        height: '63px',
        marginRight: '10px',
      };

      const separatorStyle = {
        width: '100%',
        height: '1px',
        backgroundColor: '#ddd',
        marginBottom: '40px',
      };
  
      return (
        <div style={containerStyle}>
          <div style={separatorStyle}></div>
          <h2 style={{ fontSize: '2em', marginBottom: '40px' }}>Get Started</h2>
          <div style={buttonContainerStyle}>
            <a href="https://www.rated10.app/creators" style={webButtonStyle}>
              Get Started on Web
            </a>
            <a href="https://apps.apple.com/us/app/rated10/id6448967860" style={appStoreButtonStyle}>
              <Image src={AppStoreButtonLogo} alt="Apple Logo" style={imageStyle} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rated10.app&hl=en_US" style={googlePlayButtonStyle}>
              <Image src={GooglePlayStoreButtonLogo} alt="Google Play Logo" style={imageStyle} />
            </a>
          </div>
        </div>
      );
    }
  }

  export default GetStarted;