import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { db } from "../utilities/Firebase";
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { isMobile } from '../utilities/Functions';


const NewsletterSignup = () => {
  const [email, setEmail] = useState('');

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (email !== '' && isValidEmail(email)) { // Check for valid email
        try {
            const newsletterRef = doc(db, 'Newsletter', 'emails');
        
            await updateDoc(newsletterRef, {
                emailAddresses: arrayUnion(email),
            });
        
            // setStatus("Success");
            alert('Thank you for signing up!');
            setEmail(''); // Clear the input field
        } catch (error) {
            console.error('Error adding email: ', error);
            alert('An error occurred. Please try again.');
            return error;
        }
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile() ? '20px' : '60px',
        textAlign: 'center',
        borderTop: '1px solid #ddd', // Add a top border as a separator
      }}
    >
      <h4 style={{ marginBottom: '20px' }}>
        Intrigued? Join our inner circle for updates and promotions!
      </h4>
      <div style={{ display: 'flex', width: '100%', maxWidth: isMobile ? '500px' : '500px' }}>
        <Form.Control
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ flex: 1, marginRight: '10px' }}
        />
        <Button
          variant="r10_orange"
          style={{ backgroundColor: '#E35335', color: 'white' }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </section>
  );
};

export default NewsletterSignup;