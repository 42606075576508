import React, { useEffect, useState } from "react";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Button, Modal } from "react-bootstrap";

const stripePromise = loadStripe(
  // "pk_test_51PAu5tRoYBUiaBHjz1WkkK2yJv9y5h8WpyzcixvY0HyarXgyr4Q1Ufl0ToXQdpHXwu4x0bLLOFrz3Xdff6ICGHGF008GrzS6zS"
  "pk_live_51Lry62EnuIXnmkCeocb8IH1fzDcI6JMu3aG0mwfLC2FHYHjCnsBj2H1mSbDosJB2LkRmpd696ivkJviZU5fnSG1q00A2sGrY5a"
);

const PaymentCheckout = ({
  paymentShow,
  paymentIntent,
  paymentCallBack,
  paymentConvo,
  service,
}) => {
  if (!paymentIntent) {
    return;
  }
  // // test
  // var options = { mode: "payment", amount: 1099, currency: "usd" };
  // // test
  // live
  var options = { clientSecret: paymentIntent.client_secret };
  // live

  return (
    <Modal
      size="md"
      show={paymentShow}
      centered
      animation="fade"
      onHide={() => paymentCallBack(false)}
    >
      <Modal.Header
        closeButton
        className="d-flex flex-column fw-bold bg-r10_bg_default"
      >
        Complete Payment
      </Modal.Header>
      <Modal.Body className="bg-r10_bg_default rounded-3">
        <Elements stripe={stripePromise} options={options}>
          <CheckOut paymentConvo={paymentConvo} service={service} />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentCheckout;

const CheckOut = ({ paymentConvo, service }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [pending, setPending] = useState();

  const handlePayment = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    setPending(true);

    // live code  !!!
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success?id=${paymentConvo.id}&service=${service}`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    // live code  !!!

    // // test code  !!!
    // // Trigger form validation and wallet collection
    // const { error: submitError } = await elements.submit();
    // if (submitError) {
    //   console.log(error);
    //   return;
    // }

    // const str = require("stripe")(
    //   "sk_test_51PAu5tRoYBUiaBHjRAlE3U1HJa6QyAOUWTExG8YRVw68rrGCj7rRW4t6F3OYXxMQ7OGAiGXSoaGmZpNDrzRzPsYk00UuBw3Zkz"
    // );
    // const intent = await str.paymentIntents.create({
    //   amount: 1099,
    //   currency: "usd",
    //   // In the latest version of the API, specifying the `automatic_payment_methods` parameter is optional because Stripe enables its functionality by default.
    //   automatic_payment_methods: { enabled: true },
    // });
    // const clientSecret = intent.client_secret;

    // // Confirm the PaymentIntent using the details collected by the Payment Element
    // const { error } = await stripe.confirmPayment({
    //   elements,
    //   clientSecret,
    //   confirmParams: {
    //     return_url: `${window.location.origin}/payment-success?id=${paymentConvo.id}&service=${service}`,
    //   },
    // });

    // if (error) {
    //   // This point is only reached if there's an immediate error when
    //   // confirming the payment. Show the error to your customer (for example, payment details incomplete)
    //   // handleError(error);
    //   console.log(error);
    // } else {
    //   // Your customer is redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer is redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }
    // // test code  !!!
  };

  return (
    <form>
      <PaymentElement />

      <Button
        className="d-flex mx-auto mt-4"
        variant="r10_orange text-white"
        type="button"
        onClick={handlePayment}
        disabled={!stripe || pending}
      >
        Submit
      </Button>
    </form>
  );
};
