import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {isMobile} from "../utilities/Functions";

class YouTubeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        <Button
          variant="r10_orange text-white"
          className="mb-3 fw-bold d-flex align-items-center justify-content-center"
          style={{
            height: "7vh",
            width: isMobile() ? "100%" : "33%", // Full width for mobile, 1/3 for desktop
          }}
          onClick={this.handleOpenModal} // Open the modal on button click
        >
          Watch Product Video ↗
        </Button>

        {/* Modal for the YouTube video */}
        <Modal
          show={this.state.showModal}
          onHide={this.handleCloseModal}
          size={isMobile() ? 'sm' : 'lg'} // Small size on mobile, large size on desktop
          centered
          backdrop="static" // Darkens the background
        >
          <Modal.Body style={{ padding: '0', overflow: 'hidden' }}>
            <div
              style={{
                position: 'relative',
                paddingBottom: isMobile() ? '56.25%' : '56.25%',
                height: 0,
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                src="https://www.youtube.com/embed/KW1OIW71lZU"
                title="Product Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: 'white' }}> {/* Change footer background to white */}
            <Button 
              variant="r10_orange" 
              style={{ backgroundColor: '#E35335', color: 'white' }}  // Orange close button
              onClick={this.handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default YouTubeButton;