import { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import OneQuestionDetail from "../components/OneQuestionDetail";
import ReviewDetail from "../components/ReviewDetail";
import { useGetDoc } from "../utilities/CustomHooks";
import {
  capitalizeFirstLetter,
  conversationTopics,
  fbDateConvert,
  getFbDocsWithQuery,
  isMobile,
  loading,
  statusAlert,
  uniqByKeepLast,
} from "../utilities/Functions";
import { NavLink, useNavigate } from "react-router-dom";
import { JobCompletionState } from "../utilities/Constants";
import "./PageStyles.css";

// "loggedBy" dictates whether the page is inquiries or creator hub
const InquiryAndHubList = ({ loggedBy, pageKey }) => {
  var user = JSON.parse(sessionStorage.getItem("token"));
  var cachedUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();

  const [pending, setPending] = useState(true);
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();
  const { data: userInfo, setData: setUserInfo } = useGetDoc(
    "User",
    user.uid,
    [user.uid, updatePending, loggedBy],
    setPending,
    setError
  );

  const { data: creatorInfo, setData: setCreatorInfo } = useGetDoc(
    "Creator",
    cachedUserInfo.asCreator ? cachedUserInfo.asCreator : null,
    [
      cachedUserInfo.asCreator ? cachedUserInfo.asCreator : null,
      updatePending,
      ,
      loggedBy,
    ],
    setPending,
    setError
  );

  const [jobList, setJobList] = useState(null);
  const [detailList, setDetailList] = useState(null);
  const [uiList, setUiList] = useState(null);

  const [selectedJob, setSelectedJob] = useState(null);
  const [detailProps, setDetailProps] = useState(null);
  const [showNoInquiry, setShowNoInquiry] = useState(false);

  const navigate = useNavigate();

  // get job list
  useEffect(() => {
    setSelectedJob(null);
    if (loggedBy === "user" && userInfo) {
      // for inquiry page, get user inquiries
      getJobList("user");
    } else if (loggedBy === "creator" && creatorInfo) {
      // for hub page, get creator received submissions
      getJobList("creator");
    }

    return () => {
      setJobList(null);
      setDetailList(null);
      setUiList(null);
    };
  }, [userInfo, creatorInfo, loggedBy]);

  // get list details from job list
  useEffect(() => {
    if (jobList) {
      setPending(true);
      getJobListDetails();
    }

    return () => {
      setDetailList(null);
    };
  }, [jobList]);

  // get ui list from detail list
  useEffect(() => {
    if (detailList) {
      getUiList();
    } else {
      setPending(false);
    }

    return () => {
      setUiList(null);
    };
  }, [detailList]);

  // a job is selected from the list
  useEffect(() => {
    prepareDetailSection();
  }, [selectedJob]);

  // show no inquiry delayed
  useEffect(() => {
    setTimeout(() => {
      if (!uiList && !jobList && !detailList && !pending) {
        setShowNoInquiry(true);
      } else {
        setShowNoInquiry(false);
      }
    }, 3000);
    // return () => {
    //   setShowNoInquiry(false);
    // };
  }, [uiList, pending, jobList, detailList]);

  // for testing purpose
  useEffect(() => {
    if (status) {
      // show success and leave
      if (statusVariant === "success") {
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      } else {
        // error message
        setTimeout(() => {
          setStatus(null);
        }, 5000);
      }
    }
  }, [status]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function getJobList(page) {
    if (page === "user") {
      // inquiry page list
      let list = [];
      if (userInfo.oneQuestionSubmissions) {
        userInfo.oneQuestionSubmissions.forEach((job) => {
          list.push({ type: "question", id: job });
        });
      }
      if (userInfo.reviewSubmissions) {
        userInfo.reviewSubmissions.forEach((job) => {
          list.push({ type: "review", id: job });
        });
      }
      if (list.length > 0) {
        setJobList(list);
      } else {
        setJobList(null);
      }
    } else if (page === "creator") {
      // creator hub list
      let list = [];
      if (creatorInfo.receivedOneQuestionSubmissionIds) {
        creatorInfo.receivedOneQuestionSubmissionIds.forEach((job) => {
          list.push({ type: "question", id: job });
        });
      }
      if (creatorInfo.receivedSubmissionIds) {
        creatorInfo.receivedSubmissionIds.forEach((job) => {
          list.push({ type: "review", id: job });
        });
      }
      if (list.length > 0) {
        setJobList(list);
      } else {
        setJobList(null);
      }
    }
    setDetailList(null);
  }

  function getJobListDetails() {
    let questionList = [];
    let reviewList = [];
    let queries;

    jobList.forEach((job) => {
      if (job.type === "question") {
        questionList.push(job.id);
      } else {
        reviewList.push(job.id);
      }
    });
    // get question list details
    if (questionList.length > 0) {
      queries = [
        {
          field: "id",
          condition: "in",
          value: questionList,
        },
      ];
      getFbDocsWithQuery(
        "CompletedOneQuestionJob",
        queries,
        setDetailList,
        // setPending,
        undefined,
        setError
        // true //isAppend
      );
    }

    // get review list details
    if (reviewList.length > 0) {
      queries = [
        {
          field: "id",
          condition: "in",
          value: reviewList,
        },
      ];
      getFbDocsWithQuery(
        "CompletedJob",
        queries,
        setDetailList,
        // setPending,
        undefined,
        setError,
        true //isAppend
      );
    }

    // if review not set pending false, delayed pending false
    // setTimeout(() => {
    // setPending(false);
    // }, 3000);
    setUiList(null);
  }

  function getUiList() {
    // let list = JSON.parse(JSON.stringify(detailList));
    // let list = detailList;
    let list = uniqByKeepLast(detailList, (detail) => detail.id);
    let ui = [];

    if (loggedBy === "user") {
      // inquiry list
      list.sort((a, b) => {
        if (a.creatorName.toLowerCase() < b.creatorName.toLowerCase()) {
          return 1;
        }
        if (a.completionState.toLowerCase() < b.completionState.toLowerCase()) {
          return 1;
        }
        if (fbDateConvert(a.submissionDate) > fbDateConvert(b.submissionDate)) {
          return 1;
        }
        return -1;
      });
      let group = Object.groupBy(list, ({ creatorId }) => creatorId);

      for (let key in group) {
        ui.push({ subHead: group[key][0].creatorName, jobs: group[key] });
      }
      ui.sort((a, b) => a.subHead > b.subHead);
    } else if (loggedBy === "creator") {
      // creator hub list
      list.sort((a, b) => {
        if (a.completionState.toLowerCase > b.completionState.toLowerCase) {
          return 1;
        }
        if (a.creatorId.toLowerCase < b.creatorId.toLowerCase) {
          return 1;
        }
        if (a.submissionDate > b.submissionDate) {
          return 1;
        }
        return -1;
      });
      let group = Object.groupBy(
        list,
        ({ completionState }) => completionState
      );

      let completed = { subHead: "Completed", jobs: [] };
      for (let key in group) {
        if (key === JobCompletionState.ONGOING) {
          ui.push({
            subHead: "Pending",
            jobs: group[key],
          });
        } else {
          completed.jobs = completed.jobs.concat(group[key]);
        }
      }
      if (completed.jobs.length > 0) {
        ui.push(completed);
      }
      ui.sort((a, b) => a.subHead < b.subHead);
    }

    if (ui.length > 0) {
      setUiList(JSON.parse(JSON.stringify(ui)));
    } else {
      setUiList(null);
    }
    setPending(false);
  }

  function jobCallBack() {
    setSelectedJob(null);
    // setDetailProps(null);
  }

  function prepareDetailSection() {
    if (selectedJob) {
      let detail = {
        userId: selectedJob.userId,
        creatorId: selectedJob.creatorId,
        service: {
          typeOfService: selectedJob.category,
          completionDeadline: selectedJob.completionDeadline,
          price: selectedJob.amount,
          tokenPrice: selectedJob.amountInTokens,
        },
        convoId: selectedJob.id,
        loggedBy: loggedBy,
        // jobCallBack: jobCallBack,
      };
      if (isMobile()) {
        navigate("/inquiry-detail-page", {
          state: detail,
        });
      }
      setDetailProps(detail);
    } else {
      setDetailProps(null);
    }
  }

  function handleSelectJobFromList(event, job) {
    setDetailProps(null);
    setSelectedJob(job);
  }

  // questionCard - reviewCard - jobListLayout are copied in Payment Hub
  function questionCard(job, name) {
    return (
      <div className="container p-2">
        <div className="row row-cols-2">
          <div className="col custom-fs-xs fw-bold text-truncate">
            {conversationTopics(job.category)}
          </div>
          <div className="col text-end custom-fs-xs">
            {job.completionState === JobCompletionState.ONGOING && (
              <span className="rounded-circle bg-r10_orange px-2 me-2"></span>
            )}
            {"Status: " + capitalizeFirstLetter(job.completionState)}
          </div>
          <div className="col custom-fs-xs text-truncate">
            {/* {loggedBy === "user" ? job.creatorName : job.userName} */}
            {loggedBy === "user" ? name : job.userName}
          </div>
          <div className="col text-end custom-fs-xs">
            {"Price: " + job.amountInTokens}
          </div>

          <div className="col row-cols-2 ps-3 custom-fs-xs text-truncate">
            <span className="text-r10_orange">Q: </span>
            {job.question ? job.question : ""}
          </div>
          <div className="col"></div>

          <div className="col row-cols-2 ps-3 custom-fs-xs text-truncate">
            <span className="text-r10_orange">A: </span>
            {job.answer ? job.answer : ""}
          </div>
          <div className="col"></div>
        </div>
      </div>
    );
  }

  function reviewCard(job, name) {
    return (
      <div className="container p-2">
        <div className="row row-cols-2">
          <div className="col fw-bold custom-fs-xs text-truncate">
            {job.subject}
          </div>
          <div className="col text-end custom-fs-xs">
            {job.completionState === JobCompletionState.ONGOING && (
              <span className="rounded-circle bg-r10_orange px-2 me-2"></span>
            )}
            {"Status: " + capitalizeFirstLetter(job.completionState)}
          </div>
          <div className="col custom-fs-xs text-truncate">
            {/* {loggedBy === "user" ? job.creatorName : job.userName} */}
            {loggedBy === "user" ? name : job.userName}
          </div>
          <div className="col text-end custom-fs-xs">
            {"Price: " + job.amountInTokens}
          </div>
        </div>
      </div>
    );
  }

  function jobListLayout() {
    return (
      <div className="col col-12 col-md-3 pe-3 d-flex flex-column h-100">
        {loggedBy === "creator" && (
          // <Button
          <Button
            // role="button"
            variant="r10_orange text-white"
            className="mb-3 col-8 fw-bold d-flex align-items-center justify-content-center"
            style={{ height: "7vh" }}
            as={NavLink}
            to="/payment-hub"
          >
            {/* <div className="d-flex fw-bold mx-auto">Payment Hub</div> */}
            Payment Hub
          </Button>
        )}
        <Stack id="sub_stack" gap={3} className="overflow-auto pe-3">
          {uiList.map((sub, index) => (
            <Stack
              key={`${index}_${sub}_${pageKey}`}
              gap={2}
              className="position-relative"
            >
              <h3
                role={loggedBy === "user" ? "button" : ""}
                onClick={
                  loggedBy === "user"
                    ? () =>
                        navigate("/creator", {
                          state: { id: sub.jobs[0].creatorId },
                        })
                    : null
                }
                className="sticky-top bg-r10_bg_default fw-bold"
              >
                {sub.subHead}
              </h3>
              {sub.jobs.map((job) => (
                <div
                  key={`${job.id}_${pageKey}`}
                  role="button"
                  className={
                    loggedBy === "user" ||
                    job.completionState === JobCompletionState.ONGOING
                      ? selectedJob && selectedJob.id === job.id
                        ? "bg-r10_white rounded-3 border border-r10_orange"
                        : "bg-r10_white rounded-3"
                      : selectedJob && selectedJob.id === job.id
                      ? "bg-r10_white text-r10_text_disabled rounded-3 border border-r10_orange"
                      : "bg-r10_white text-r10_text_disabled rounded-3"
                  }
                  onClick={(event) => handleSelectJobFromList(event, job)}
                >
                  {job.category.search("Question") > 0
                    ? questionCard(job, sub.subHead)
                    : reviewCard(job, sub.subHead)}
                </div>
              ))}
            </Stack>
          ))}
        </Stack>
      </div>
    );
  }
  // questionCard - reviewCard - jobListLayout are copied in Payment Hub

  function setPageLayout() {
    if (pending) {
      // return loading();
    } else if (status) {
      return statusAlert(status, statusVariant);
    } else if (!uiList) {
      // no inquiry or received submission
      return (
        <>
          {showNoInquiry && (
            <Stack gap={3} className="one-screen">
              {loggedBy === "creator" && (
                <Button
                  variant="r10_orange text-white"
                  // className="me-3 col-2"
                  className="me-3 mb-3 col-2 fw-bold d-flex align-items-center justify-content-center"
                  style={{ height: "7vh" }}
                  as={NavLink}
                  to="/payment-hub"
                >
                  <div className="d-flex fw-bold text-left">Payment Hub</div>
                </Button>
              )}
              <h1 className="fw-bold">
                {loggedBy === "user" ? "Inquiries" : "Pending Jobs"}
              </h1>
              <div className="col-4 bg-r10_white">
                {loggedBy === "user"
                  ? "You have not submitted an inquiry yet"
                  : "You do not have an inquiry yet"}
              </div>
            </Stack>
          )}
        </>
      );
    } else {
      return (
        <Stack direction="horizontal" className="one-screen">
          {/* list section */}
          {jobListLayout()}

          {/* detail section */}
          {!detailProps && <div className="vr" />}
          {detailProps && (
            <div className="col-9 d-none d-md-flex flex-column h-100 border border-r10_text_disabled rounded-3">
              {detailProps.service.typeOfService.search("Question") >= 0 ? (
                <OneQuestionDetail
                  userId={detailProps.userId}
                  creatorId={detailProps.creatorId}
                  service={detailProps.service}
                  convoId={detailProps.convoId}
                  loggedBy={loggedBy}
                  jobCallBack={jobCallBack}
                />
              ) : (
                <ReviewDetail
                  userId={detailProps.userId}
                  creatorId={detailProps.creatorId}
                  service={detailProps.service}
                  convoId={detailProps.convoId}
                  loggedBy={loggedBy}
                  jobCallBack={jobCallBack}
                />
              )}
            </div>
          )}
        </Stack>
      );
    }
  }

  return setPageLayout();
};

export default InquiryAndHubList;
