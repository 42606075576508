import { Button, Image, Stack } from "react-bootstrap";
import Avatar from "../assets/Avatar.png";
import { useEffect, useRef, useState } from "react";
import {
  deleteUserAccount,
  loading,
  patchData,
  statusAlert,
  uploadFile,
  userSignOut,
} from "../utilities/Functions";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetDoc, useGetFile } from "../utilities/CustomHooks";

import "./PageStyles.css";
import ApplyToBeCreator from "../components/ApplyToBeCreator";
import Compressor from "compressorjs";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const inputFile = useRef(null);

  const user = JSON.parse(sessionStorage.getItem("token"));
  var launchSettings = JSON.parse(sessionStorage.getItem("launchSettings"));
  const [applyToBeCreator, setApplyToBeCreator] = useState();

  const { data: userInfo } = useGetDoc(
    "User",
    user.uid,
    [user.uid, updatePending],
    setPending,
    setError
  );

  const [creatorDependant, setCreatorDependant] = useState([
    null,
    updatePending,
  ]);
  const [creatorId, setCreatorId] = useState();
  const { data: creatorInfo } = useGetDoc(
    "Creator",
    creatorId,
    creatorDependant,
    setPending,
    setError
  );

  const [photoDependant, setPhotoDependant] = useState([null]);
  let fileName = "user" + user.uid + "/" + user.uid + "ProfilePicture";
  const { file: photo, setFile: setPhoto } = useGetFile(
    fileName,
    photoDependant,
    setPending,
    setError
  );

  const [showApply, setShowApply] = useState(false);

  // set creator dependencies
  useEffect(() => {
    if (userInfo && userInfo.asCreator) {
      setCreatorDependant([userInfo.asCreator, updatePending]);
      setCreatorId(userInfo.asCreator);
    }
  }, [userInfo, updatePending]);

  // set photo dependencies
  useEffect(() => {
    if (user.uid && userInfo) {
      setPhotoDependant([updatePending]);
    }
  }, [updatePending]);

  // check applyToBeCreator is in session storage
  useEffect(() => {
    if (userInfo && !userInfo.asCreator) {
      setApplyToBeCreator(
        JSON.parse(sessionStorage.getItem("applyToBeCreator"))
      );
    }
  }, [userInfo, updatePending]);

  // set dummy photo if photo is null
  useEffect(() => {
    if (!photo) {
      setPhoto(Avatar);
    }
  }, [photo]);

  // for testing purpose
  useEffect(() => {
    if (status && statusVariant) {
      // error message
      setTimeout(() => {
        setStatus(null);
      }, 5000);
    }
  }, [status]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function handleVacation(event) {
    let vacationData = { isOnVacation: !creatorInfo.isOnVacation };
    setUpdatePending(true);
    patchData(
      "Creator",
      creatorInfo.id,
      vacationData,
      setUpdatePending,
      setStatus,
      setError
    );
  }

  //  create / change photo
  function handleEditPhoto(event) {
    if (inputFile === null) return;

    inputFile.current.click();
  }

  function handleUploadProgress(progress) {
    setStatus(`Uploading : ${progress}%`);
  }

  function handleUploadError(error) {
    setStatus(`Unsuccessful, try again later`);
    // setTimeout(() => {
    // setPending(false);
    setUpdatePending(false);
    // }, 1000);
  }

  function handleUploadSuccess(file) {
    setStatus(`Successful`);
    // setTimeout(() => {
    // setPending(false);
    setUpdatePending(false);
    // }, 1000);
    // setPhoto(file);
  }

  function onFileSelected(event) {
    // setPending(true);
    setUpdatePending(true);
    setStatus("File uploading!");

    let fileName = "user" + user.uid + "/" + user.uid + "ProfilePicture";

    new Compressor(event.target.files[0], {
      quality: 1 - launchSettings.photoCompressionQuality,
      success: (compressedResult) => {
        uploadFile(
          fileName,
          compressedResult,
          handleUploadProgress,
          handleUploadError,
          handleUploadSuccess
        );
      },
    });

    // uploadFile(
    //   fileName,
    //   event.target.files[0],
    //   handleUploadProgress,
    //   handleUploadError,
    //   handleUploadSuccess
    // );
    // setPhoto(event.target.files[0]);
  }

  //  delete user account
  async function deleteAccount() {
    let error = await deleteUserAccount();
    if (error) {
      setStatus("Please sign out and sign in again to continue");
      setStatusVariant("danger");
    } else {
      navigate("/");
    }
  }

  //  signout
  function signOut() {
    userSignOut();
    navigate("/");
  }

  function applyCallBack() {
    setShowApply(false);
    setUpdatePending(false);
  }

  function setPageLayout() {
    if (pending || updatePending) {
      return loading();
    } else if (userInfo === null) {
      // show error or do something else
    } else if (status && statusVariant) {
      return statusAlert(status, statusVariant);
    } else if (showApply) {
      return (
        <ApplyToBeCreator
          callBack={applyCallBack}
          setUpdatePending={setUpdatePending}
        />
      );
    } else {
      return (
        // <Stack gap={3} className="col-12">
        <Stack
          gap={3}
          className="col-xs-12 col-sm-8 mx-auto p-2 d-flex flex-column"
        >
          {/* profile picture and name */}

          <Stack gap={2} className="mx-auto">
            <Stack gap={1} direction="horizontal">
              <Image src={photo} roundedCircle className="profile-photo" />
              <Button variant="outline-secondary" onClick={handleEditPhoto}>
                <div className="d-flex fw-bold text-left">Edit</div>
              </Button>
              <input
                hidden
                type="file"
                accept="image/*"
                id="file"
                ref={inputFile}
                onChange={onFileSelected}
              />
            </Stack>
            <div className="h1 fw-bold text-center">{userInfo.name}</div>
          </Stack>

          {/* links - buttons */}
          <Stack gap={1} className="mx-auto col-xs-12 col-sm-6">
            <Button variant="light" as={NavLink} to="/user-profile">
              <div className="d-flex fw-bold text-left">Edit Profile</div>
            </Button>

            {creatorInfo && (
              <Button
                variant="light"
                as={NavLink}
                to="/creator"
                state={{ id: userInfo.asCreator }}
              >
                <div className="d-flex fw-bold text-left">Creator Profile</div>
              </Button>
            )}

            {userInfo && !userInfo.asCreator && (
              // <Button variant="light" onClick={handleApplyToBeCreator}>
              <Button
                variant="light"
                onClick={() => setShowApply(true)}
                // disabled={applyToBeCreator}
              >
                <div className="d-flex fw-bold text-left">
                  {/* {applyToBeCreator
                    ? "Waiting for application to be creator"
                    : "Apply to be creator"} */}
                  Apply to be creator
                </div>
              </Button>
            )}

            {creatorInfo && (
              <Button variant="light" onClick={handleVacation}>
                <div className="d-flex fw-bold text-start">
                  {creatorInfo.isOnVacation
                    ? "Become available for questions"
                    : "Become unavailable for questions"}
                </div>
              </Button>
            )}

            {/* {creatorInfo && (
              <Button
                variant="light"
                // onClick={handleCreatorPaymentAccount}
              >
                <div className="d-flex fw-bold text-left">
                  Edit creator payment account
                </div>
              </Button>
            )} */}

            {userInfo &&
              userInfo.eligibleToBeCreator &&
              !userInfo.asCreator && (
                <Button
                  variant="light"
                  as={NavLink}
                  to="/creator-page-edit"
                  state={{ id: userInfo.asCreator, section: "" }}
                >
                  <div className="d-flex fw-bold text-left">
                    Setup Creator Account
                  </div>
                </Button>
              )}

            {/* <Button variant="light">
              <div className="d-flex fw-bold text-left">Contact Support</div>
            </Button> */}
            <Button variant="light" onClick={signOut}>
              <div className="d-flex fw-bold text-left">Log out</div>
            </Button>
            <Button variant="light text-r10_orange" onClick={deleteAccount}>
              <div className="d-flex fw-bold text-left">Delete account</div>
            </Button>
          </Stack>
        </Stack>
      );
    }
  }

  return setPageLayout();
};

export default Settings;
