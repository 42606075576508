import { useEffect, useState } from "react";

import CreatorCard from "../components/CreatorCard";
import CreatorTabList from "../components/CreatorTabList";
import { loading } from "../utilities/Functions";
import { useGetDocs } from "../utilities/CustomHooks";

const Creators = ({ searchParam }) => {
  const [showcaseTabs, setShowcaseTabs] = useState([]);
  const [pending, setPending] = useState(true);
  const [error, setError] = useState();

  const { fbDocs } = useGetDocs(
    "CreatorShowcaseTabs",
    [],
    setPending,
    setError
  );

  // get showcasetabs
  useEffect(() => {
    if (fbDocs) {
      getShowcaseTabs();
    }
  }, [fbDocs]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function getShowcaseTabs() {
    let tabs = [];

    // get tab names, sorted first, then others from services
    for (let i = 0; i < fbDocs.length; i++) {
      if (fbDocs[i].fbDocId === "Tabs") {
        fbDocs[i].sortedOrder.forEach((tab) => {
          tabs.push({ name: tab, creatorIds: [] });
        });
        fbDocs[i].services.forEach((service) => {
          tabs.push({ name: service.title, creatorIds: [] });
        });
        break;
      }
    }

    // set creatorIds to corresponding tabs
    for (let i = 0; i < fbDocs.length; i++) {
      if (fbDocs[i].fbDocId !== "Tabs") {
        tabs[
          tabs.findIndex((tab) => tab.name === fbDocs[i].fbDocId)
        ].creatorIds = fbDocs[i].creatorIds;
      }
    }
    setShowcaseTabs(tabs);
  }

  function setPageLayout() {
    if (pending) {
      // return loading();
    } else if (!showcaseTabs) {
      // error handling - no tab data found
    } else {
      return (
        <div>
          {showcaseTabs.map((tab) => (
            <CreatorTabList
              tab={tab}
              key={tab.name}
              searchParam={searchParam}              
            />
          ))}
        </div>
      );
    }
  }

  return setPageLayout();
};

export default Creators;
