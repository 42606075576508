import React from "react";
import { Alert, Modal } from "react-bootstrap";

const ModalAlert = ({ showAlert, setShowAlert, message, variant, header }) => {
  return (
    <Modal
      //   size="md"
      show={showAlert}
      centered
      animation="fade"
      onHide={() => setShowAlert(false)}
    >
      <Modal.Body className="bg-r10_bg_default rounded-3">
        <Alert
          variant={variant ? variant : "secondary"}
          //   className="col-6 m-auto text-center"
        >
          {header && <Alert.Heading>{header}</Alert.Heading>}
          {message && <p>{message}</p>}
        </Alert>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAlert;
