import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import OneQuestionDetail from "../components/OneQuestionDetail";
import ReviewDetail from "../components/ReviewDetail";
import { useLocation, useNavigate } from "react-router-dom";
import "./PageStyles.css";

const InquiryDetailPage = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  var user = JSON.parse(sessionStorage.getItem("token"));

  const [isQuestion, setIsQuestion] = useState();

  if (props && props.service !== undefined) {
    var userId = props.userId ? props.userId : user.uid;
    var creatorId = props.creatorId;
    var service = props.service;
    var convoId = props.convoId;
    var loggedBy = props.loggedBy;
  } else {
    var userId = location.state.userId ? location.state.userId : user.uid;
    var creatorId = location.state.creatorId;
    var service = location.state.service;
    var convoId = location.state.convoId;
    var loggedBy = location.state.loggedBy;

    // const userId = "gSJ6lIn5nQevRlQI4ktYXvHDkhd2";
    // const creatorId = "753DDF1F-D045-4131-A137-8DC887D1DDF5";
    // const service = { typeOfService: "datingAppReview" };
    // // const convoId = "job_5E57F379-2262-40D1-A46F-401F5DC219C4";
    // // const convoId = "job_587c53d7-06da-488d-96ee-c09329447ef8";
    // const convoId = "job_186c2a0f-99de-4daf-966d-5f507e3f4c05";
    // const loggedBy = "creator";
    // // loggedBy = "creator";
    // // convoId = "job_64044d6f-d50c-4c2c-bed8-41144691b23e";
  }

  // not logged in
  useEffect(() => {
    if (!userId) {
      navigate("/login", { state: { ...location.state, fromInside: true } });
      return;
    }
  }, []);

  // set if question or review
  useEffect(() => {
    let index = service.typeOfService.search("Question");
    if (index > 0) {
      setIsQuestion(true);
    } else {
      setIsQuestion(false);
    }
  }, [service.typeOfService]);

  return (
    <Stack gap={3} direction="horizontal" className="one-screen">
      {/* <div className="col-8 mx-auto d-flex flex-column h-100 border border-secondary"> */}
      <div className="col col-12 col-md-8 mx-auto d-flex flex-column h-100 border border-r10_text_disabled rounded-3">
        {isQuestion ? (
          <OneQuestionDetail
            userId={userId}
            creatorId={creatorId}
            service={service}
            convoId={convoId}
            loggedBy={loggedBy}
          />
        ) : (
          <ReviewDetail
            userId={userId}
            creatorId={creatorId}
            service={service}
            convoId={convoId}
            loggedBy={loggedBy}
          />
        )}
      </div>
    </Stack>
  );
};

export default InquiryDetailPage;
