import React from "react";
import {isMobile} from "../utilities/Functions";

class WhyRated10Users extends React.Component {
    render() {
        const isMobileDevice = isMobile();

        const darkBlueColor = '#3B3F4E'
        const lightBlueColor = '#5D677F'
        const orangeColor = '#E35335'
          
        const containerStyle = {
            paddingBottom: isMobileDevice ? '60px' : '100px',
            paddingTop: '40px',
            paddingLeft: isMobileDevice ? '0px' : '0px',
            paddingRight: isMobileDevice ? '10px' : '40px',
            boxSizing: 'border-box',
            overflowX: 'scroll',
        };

        const headerStyle = {
            fontSize: isMobileDevice ? '12px' : '15px',
            fontWeight: 'bold',
            marginBottom: '0px',
            textAlign: 'left',
            color: orangeColor,
        }
    
        const titleStyle = {
            fontSize: isMobileDevice ? '18px' : '25px',
            fontWeight: 'bold',
            marginBottom: '0px',
            textAlign: 'left',
            color: darkBlueColor,
        };

        const textStyle = {
            marginTop: '5px',
            textAlign: 'left',
            fontSize: isMobileDevice ? '9px' : '11px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word', // Ensure long words break and wrap
            color: lightBlueColor,
        };
    
        const boxContainerStyle = {
            display: 'flex',
            paddingLeft: isMobileDevice ? '10px' : '40px',
            flexDirection: isMobileDevice ? 'column' : 'column',
            flexWrap: 'wrap',
            gap: isMobileDevice ? '10px' : '20px',
            justifyContent: isMobileDevice ? 'center' : 'space-between',
            alignItems: isMobileDevice ? 'center' : 'flex-start',
          };

        // const mobileRowStyle = {
        //     display: 'flex',
        //     gap: '10px',
        //     flexDirection: 'row',
        //     justifyContent: 'left',
        //     width: isMobileDevice ? '100%' : 'auto',
        // };

        // const rowStyle = {
        //     display: 'flex',
        //     gap: '30px',
        //     flexDirection: 'row',
        //     justifyContent: 'left',
        //     width: isMobileDevice ? '100%' : '80%',
        // };

        const mobileBoxStyle = {
            flex: '1 1 45%',
            padding: '10px',
            // border: '1px solid #ddd',
            // borderRadius: '10px',
            textAlign: 'center',
            // backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            height: '180px',
            // boxSizing: 'border-box',
            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        };
    
        const boxStyle = {
            flex: 1,
            padding: '20px',
            // border: '1px',
            // borderRadius: '10px',
            textAlign: 'center',
            // backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            height: '180px',
            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        };

        const separatorStyle = {
            width: '100%',
            height: '1px',
            backgroundColor: '#ddd',
            marginBottom: '40px',
        };

        return (
            <section id="why-rated10-users" style={containerStyle}>
                <h2 style={{ fontSize: '2em', marginBottom: '40px', fontStyle: 'italic', color: lightBlueColor }}>Benefits for Users</h2>
                {isMobileDevice ? (
                <div style={boxContainerStyle}>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>① Get answers</span>
                    <span style={titleStyle}>Response guarantee! ✅</span>
                    <span style={textStyle}> Unlike other platforms, on Rated10, creators earn only when they answer the money. So users do not get charged until they receive a response.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>② No bot messages</span>
                    <span style={titleStyle}>Genuine interaction with the creator 🤝</span>
                    <span style={textStyle}>Each message is answered by the creator tailored explicitly to the user. No mass messages only 1-1. In other platforms, messages are just means to distribute content. On ours, messages and answers are the content.</span>
                </div>
                <div style={mobileBoxStyle}>
                    <span style={headerStyle}>③ No subscriptions</span>
                    <span style={titleStyle}>Easy, commitment free usage 🎯</span>
                    <span style={textStyle}> One button sign up, only pay for the message without any commitments, subscriptions.</span>
                </div>
                </div>
                ) : (
                <div style={boxContainerStyle}>
                <div style={boxStyle}>
                    <span style={headerStyle}>① Get answers</span>
                    <span style={titleStyle}>Response guarantee! ✅</span>
                    <span style={textStyle}> Unlike other platforms, on Rated10, creators earn only when they answer the money. So users do not get charged until they receive a response.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>② No bot messages</span>
                    <span style={titleStyle}>Genuine interaction 🤝</span>
                    <span style={textStyle}>Each message is answered by the creator tailored explicitly to the user. No mass messages only 1-1. In other platforms, messages are just means to distribute content. On ours, messages and answers are the content.</span>
                </div>
                <div style={boxStyle}>
                    <span style={headerStyle}>③ No subscriptions</span>
                    <span style={titleStyle}>Easy, commitment free usage 🎯</span>
                    <span style={textStyle}> One button sign up, only pay for the message without any commitments, subscriptions.</span>
                </div>
            </div>
            )}
            </section>
         ); 
        }
    }

    export default WhyRated10Users;